import React from 'react';
import {Link,useNavigate, useParams } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../actions/server';
import CollectList from '../../../admin/collectList';


export default connect(
  (state) => {
    return {
      //STATE TO PROPS
      ///////////////////////////////////////////////////////////////////
      
    }
  },
  (dispatch) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions
  }, dispatch)
)(function (props) {
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const { getFilteredCollects, } = props;
  const params = useParams();
  const [collects,setCollects] = React.useState([]);
  const [loaded,setLoaded] = React.useState(false);
  React.useEffect(()=>{
    getFilteredCollects({currentCollectorId:parseInt(params.id)}).then(({data})=>{
      setCollects(data);
      setLoaded(true);
    }).catch(e=>{
      setLoaded(true);
    });
  },[getFilteredCollects,params.id]);

  return <CollectList loaded={loaded} collects={collects} updateCollects={()=>{
    getFilteredCollects({currentCollectorId:parseInt(params.id)}).then(({data})=>{
      setCollects(data);
    }).catch(e=>{
      setCollects([]);
      setLoaded(true);
    });
  }}/>
  });

