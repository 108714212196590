import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Box, Popper} from '@mui/material';
import actions from '../../actions/server';
/**
 * Error with children or formik one with name
 */
export default connect(
	(state, ownProps) =>{return {
		//STATE TO PROPS
		///////////////////////////////////////////////////////////////////
	}},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		...actions
	}, dispatch)
	)(function(props){
	//COMPONENT
	///////////////////////////////////////////////////////////////////
	const {sx={},timeout=0,children,reset=()=>{},notif=false} = props;
	var Component = notif ? Popper : Box;

	const style = {
		...sx,
		bgcolor: 'background.paper',
		borderWidth:2,
		borderStyle:"solid",
		borderColor:"success.main",
		color:"success.main",
		borderRadius: 2,
		padding: 2,
		minWidth:notif ? "40vw":0,
		textAlign:notif? "center":undefined,
		zIndex:notif ? 1000000000:undefined
	}
	const extraProps = notif ? {
		open:children,
		anchorEl:{
			getBoundingClientRect:()=>({
				bottom: 0,height:0,left: window.innerWidth/2,right: window.innerWidth/2,top: 10,width: 0,x: 0,y:0
			})
		}
	}:{}
	React.useEffect(()=>{
		if(timeout) {
			var timeoutDone = setTimeout(()=>{reset()},timeout);
			return ()=>clearTimeout(timeoutDone);
		}
	  },[children,timeout]);

	if(!children || children==="null") return null;
	return (
		<Component {...extraProps} {...props} sx={style}>{children}</Component>
	)
});
	