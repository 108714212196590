import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../../actions/server';
import UIActions from "../../../../../../actions/ui";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Popover, MenuItem, Button, Box, Grid, ButtonBase } from '@mui/material';
import { useFormikContext } from 'formik';
import Fields from '../../../../../globals/Fields';
import selector from '../../../../../../selectors';
import { DragHandle } from '@mui/icons-material';
import fieldsConf from './fieldsConf';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import produce from 'immer';
import SplitButton from '../../../../../globals/SplitButton';
import { getNewFieldId, getFiieldsFromForm } from './index';

const SectionContent = connect(
  (state, ownProps) => {
    return {
      //STATE TO PROPS
      ///////////////////////////////////////////////////////////////////
      users: selector.users(state),
      companies: state.server.companies,
      roles: state.server.roles,
      locales: state.server.locales,
      currentLocale: state.server.labels.currentLocale,
      clipboard: state.ui.clipboard,
      defaultOptions: selector.defaultOptions(state)
    }
  },
  (dispatch, props) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions,
    ...UIActions
  }, dispatch)
)(function (props) {
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const {
    locales, sx = {}, currentLocale, clipboard, defaultOptions, copyField, copySession,
    t
  } = props;

  const formikBag = useFormikContext();
  const [openedFieldMenu, setOpenedFieldMenu] = React.useState({
    index: -1,
    element: null
  });

  let section = formikBag.values.form[formikBag.values.currentSection];
  //the current section name
  let sectionName = "form[" + formikBag.values.currentSection + "]"

  //current selected locale in the form
  let formLocale = formikBag.values.currentLocale;
  const getEmptyField = React.useCallback(() => {
    return {
      "id": getNewFieldId(),
      "component": "",
      "visible": true,
      "name": "field-" + section.fields.length,
      "label": ["default",...locales].reduce((acc, curr) => {
        acc[curr] = "";//t("form.chooseName.label", curr);
        return acc;
      }, {}),
      "export": true,
      "condition": "",
      "deleteable": true,
      "required": true
    };
  }, [locales, section.fields.length]);

  //Flat list of fields, this will be used when needing previous fields in a condition value
  var fields = formikBag.values.form.map((section, sectionIndex) => section.fields.map(field => (field.isGroup ?
    (field.fields || []).map((fieldi) => ({
      id: fieldi.id,
      name: fieldi.name,
      component: fieldi.component,
      sectionIndex,
      sectionLabel: section.label,
      label: fieldi.label,
      options: fieldi.options || defaultOptions[fieldi.component]
    }))
    :
    {
      id: field.id,
      name: field.name,
      component: field.component,
      sectionIndex,
      sectionLabel: section.label,
      label: field.label,
      options: field.options || defaultOptions[field.component]
    }))).flat().flat().filter(field => field.component !== "RichText") //we want only inputs


  return <Grid item xs sx={{
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    ...sx
  }}>
    {/*--------------------- SECTION HEAD -------------------------*/}
    <Grid item sx={{
      background: "background.main",
      padding: 1
    }}>
      <Grid container spacing={1}>
        <Grid item>
          <Fields.Text name={sectionName + ".label[" + formLocale + "]"} label={t("form.section.label.label")} />
        </Grid>
        <Grid item xs>
          {[""].map((gni, i) => {
            //Fields available for section condition
            var firstSectionField = fields.findIndex((field) => field.sectionIndex == formikBag.values.currentSection);
            if (firstSectionField < 0) {
              var availableFields = fields;
            } else
              var availableFields = fields.slice(0, firstSectionField);
            return <Fields.Condition key={i} name={sectionName + ".condition"} availableFields={availableFields.reduce((acc, cur) => {
              acc[cur.name] = cur;
              return acc;
            }, {})} fullWidth label={section.checkpoint ? t("form.checkpoint.condition.label") : t("form.section.condition.label")} />
          })}
        </Grid>
      </Grid>

    </Grid>
    {!section.checkpoint && (
      <Grid item xs sx={{
        position: "relative"
      }}>
        <Box sx={{
          position: "absolute",
          top: 0, left: 0, right: 0, bottom: 0, overflow: "auto", padding: 1
        }}>
          <DragDropContext onDragEnd={(result) => {
            if (!result.destination || result.destination.index === result.source.index) {
              return;
            }

            //REORDER
            const fields = Array.from(formikBag.values.form[formikBag.values.currentSection].fields);
            const [removed] = fields.splice(result.source.index, 1);
            fields.splice(result.destination.index, 0, removed);

            //console.log(result, sectionName + ".fields", fields);

            formikBag.setFieldValue(sectionName + ".fields", fields);
          }}>
            <Droppable droppableId="list">
              {provided => {
                //console.log("coin",formikBag.values.form[formikBag.values.currentSection]);
                return (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {formikBag.values.form[formikBag.values.currentSection].fields.map((field, index) => {
                      var fieldName = sectionName + ".fields[" + index + "]";
                      return (
                        <Draggable key={field.id} draggableId={field.id} index={index}>
                          {provided => {
                            return (
                              <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                sx={{
                                  backgroundColor: "background.default",
                                  marginBottom: "3px"
                                }}
                              >
                                <Grid container alignItems={"center"} style={{
                                  position: "relative"
                                }}>
                                  <Grid item {...provided.dragHandleProps}>
                                    <DragHandle />
                                  </Grid>
                                  <Grid item xs sx={{
                                    padding: 2
                                  }}>
                                    <Grid container spacing={1} alignItems={"center"}>
                                      <Grid item>
                                        <Fields.SelectComponent disabled={field.locked && field.locked.component ? true : false} name={fieldName + ".component"} isGroupName={fieldName + ".isGroup"} label={t("form.field.component.label")} />
                                      </Grid>
                                      {field.component=="Select" && <Grid item>
                                        <Fields.Switch disabled={field.locked && field.locked.visible ? true : false} name={fieldName + ".multiple"} label={<small>{t("form.field.multiple.label")}</small>} labelPlacement={"top"} />
                                      </Grid>}
                                      <Grid item>
                                        <Fields.Switch disabled={field.locked && field.locked.visible ? true : false} name={fieldName + ".visible"} label={<small>{t("form.field.visible.label")}</small>} labelPlacement={"top"} />
                                      </Grid>
                                      {!field.isGroup && field.component != "RichText" && <Grid item>
                                        <Fields.Text name={fieldName + ".name"} disabled={field.locked && field.locked.name ? true : false} label={t("form.field.name.label")} />
                                      </Grid>}
                                      <Grid item xs>
                                        {field.component != "RichText" && field.visible && <Fields.Text fullWidth name={fieldName + ".label[" + formikBag.values.currentLocale + "]"} label={t("form.field.label." + formikBag.values.currentLocale + ".label")} />
                                        }
                                      </Grid>
                                      {field.component != "RichText" && <Grid item>
                                        <Fields.Switch name={fieldName + ".required"} disabled={field.locked && field.locked.required ? true : false} label={<small>{t("form.field.required.label")}</small>} labelPlacement={"top"} />
                                      </Grid>}
                                      {field.component != "RichText" && <Grid item>
                                        <Fields.Switch name={fieldName + ".export"} disabled={field.locked && field.locked.export ? true : false} label={<small>{t("form.field.export.label")}</small>} labelPlacement={"top"} />
                                      </Grid>}
                                      <Grid item>
                                        <ButtonBase onClick={(e) => {
                                          setOpenedFieldMenu({ index, element: e.target });
                                        }}>
                                          <MoreVertIcon />
                                        </ButtonBase>
                                      </Grid>
                                      <Grid item xs={12}>
                                        {[""].map((gni, i) => {
                                          //Fields available for section condition
                                          var myIndex = fields.findIndex((fieldi) => fieldi.name == field.name);
                                          if (myIndex < 0) {
                                            var availableFields = fields;
                                          } else
                                            var availableFields = fields.slice(0, myIndex);
                                          //console.log(fieldName, availableFields);
                                          return <Fields.Condition disabled={field.locked && field.locked.condition ? true : false} key={field} name={fieldName + ".condition"} availableFields={availableFields.reduce((acc, cur) => {
                                            acc[cur.name] = cur;
                                            return acc;
                                          }, {})} fullWidth label={t("form.field.condition.label")} />
                                        })}
                                      </Grid>
                                      {[""].map((gni, i) => {
                                        var FieldConf = fieldsConf[field.component];
                                        //console.log(fieldName, FieldConf);
                                        if (FieldConf)
                                          return <Grid key={i+sectionName} item xs={12}><FieldConf 
                                            name={fieldName} 
                                            /* the field "date" is fixed and this has to be retro compatible with existing forms */
                                            disabled={field.name=="date" ? true:false}
                                          /></Grid>
                                        else
                                          return null;
                                      })}
                                    </Grid>

                                  </Grid>
                                  <Popover
                                    open={openedFieldMenu.index == index}
                                    elevation={1}
                                    anchorEl={openedFieldMenu.element}
                                    onClose={() => { setOpenedFieldMenu({ index: -1, element: null }) }}
                                    anchorOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                                    }}
                                  >
                                    <>
                                      {(field.locked && field.locked["deleteable"]) || <MenuItem onClick={() => {
                                        var newFields = produce(section.fields, (draft) => {
                                          draft.splice(index, 1);
                                        });
                                        formikBag.setFieldValue(sectionName + ".fields", newFields);
                                        setOpenedFieldMenu({ index: -1, component: null });
                                      }}>{t("form.field.delete.cta")}</MenuItem>}
                                      {(field.locked && field.locked["copy"]) || <MenuItem onClick={() => {
                                        copyField(field);
                                        setOpenedFieldMenu({ index: -1, component: null });
                                      }}>{t("form.field.copy.cta")}</MenuItem>}
                                    </>
                                  </Popover>
                                </Grid>

                              </Box>
                            )
                          }}
                        </Draggable>)
                    }
                    )}
                    {provided.placeholder}
                  </div>
                )
              }}
            </Droppable>
            <Box sx={{
              padding: theme => theme.spacing(5),
              display: "flex",
              alignItems: "center", justifyContent: "center"
            }}>
              <SplitButton options={[
                {
                  label: t("form.addField.cta"),
                  action: () => {
                    formikBag.setFieldValue(sectionName + ".fields", [...section.fields, getEmptyField()])
                  },
                  active: true,
                },
                {
                  label: t("form.pasteField"),
                  action: () => {
                    var newFields = [...section.fields, {
                      ...clipboard.field,
                      locked: {},
                      id: getNewFieldId(),
                      //name:clipboard.field.name+" "+t("field.nameCopy")
                    }];
                    formikBag.setFieldValue(sectionName + ".fields", newFields)
                  },
                  active: clipboard.field ? true : false,
                }
              ]} />
            </Box>
          </DragDropContext>

          {/*--------------------- SECTION CONTENT -------------------------*/}
          {false && JSON.stringify(formikBag.values)}
          {false && Array(10000).fill("section content").join(" ")}
        </Box>
      </Grid>)}
  </Grid>
})

export default SectionContent;