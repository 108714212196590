import axios from "axios";

const instance = axios.create({});

//Just add entry here to have mock data
const mockData = {
	//"/auth/possession":{"token":"eyJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJqb3JnZS5jYWZydW5lQHlvcG1haWwuY29tIiwiY3JlYXRlZCI6MTY2OTM3MTg5Nzc0MCwiaXNzIjoia2VlcGVyIiwiZXhwIjoxNjY5OTc2Njk3LCJwcm92IjoiU1VOIiwiaWF0IjoxNjY5MzcxODk3LCJyb2wiOiJST0xFX0NQWV9DT0xMRUNUT1IiLCJqdGkiOiIxZDdjMTJjZC1lMjNhLTQyYTEtOWEzNy1iMmJhMmQ4NmNiOWMifQ.PmRSiGmYdFQZj8Va3vxhDvV2cYTvC3YGdF2fwO7QTdbbqGybpjd45siQx74t8VzP9QTyKrtSg-NVNiXRVgfjZRHJ4SgRO420sj0EsFF82AtFJAigyiO9H02Ohpst-2QGfZKSQGI8sHX-OfzR1hMs8QX29impY7LWcM2QSMYxJd_-kX2tX7m_pWC9XXy0Or1eVPqmT9fSXuoM978lMVuzWnpBdUMo7SxQlgYjUdLb4ZeAcT9JPn6NeK7pJEhBs0_zfIQ2ZuGHkWJSMm4Esa4saZ6BtFQrw4ze7IgztXNXoAJClRjH_W8LX5v7mgwYwMErW6sZvHrQYqpxvavAWvn9Mg"}
	/*"/users/me":{data:{
		"id":111,
		"email":"coucou@email.com",
		"role":"ADMIN"
	},status:200}*/
}

//The main idea is to automatically send PROCESSING / SUCCESS and FAIL events
//and to manage mock data
const toExport = (dispatch,getState)=>{
	const GLOBALS = getState().server.GLOBALS;

	//SET BASE URL
	instance.defaults.baseURL = GLOBALS.baseURL;

	//SET AUTHORIZATION
	if(GLOBALS.token) {
		instance.defaults.headers.common['Authorization'] = GLOBALS.token;
	}

	var api = {};
	//for each method
	["get","post","delete","put"].forEach(method=>{
		//create method
		api[method] = (...args)=>{
			const [url,data,config] = args;
			//for get and delete only = 2 params and post = 3
			const event = (config && config.event) || (data &&data.event);
			
			//PRE call
			if(event) {
				dispatch({
					type:event.PROCESSING,
					payload:data
				});
			}
			//THERE IS A MOCK
			if(mockData[url]){
				console.log("mock ",args,event);
				if(event) 
					dispatch({
						type:event.SUCCESS,
						payload:mockData[url],
						request:data
					});
				return Promise.resolve(mockData[url]);
			}	
			//OTHERWISE axios
			else return instance[method](...args)
				.then(response=>{
					//SUCCESS
					if(event) 
						dispatch({
							type:event.SUCCESS,
							payload:response.data,
							request:data
						});
					//Return regular resolve
					return Promise.resolve(response);
				})
				.catch((error)=>{
					//FAIL
					if(event) 
						dispatch({
							type:event.FAIL,
							payload:error,
							request:data
						});
					//Return regular reject
					return Promise.reject(error);
				})
		}
	});
	return api;
};
export default toExport;