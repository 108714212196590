import React from 'react';
import { Link, Outlet, useNavigate, useParams, useResolvedPath, useMatch } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../actions/server';
import UIActions from '../../../../../actions/ui';
import { Button, Container, Grid, TextField, LinearProgress, Typography, Tabs, Tab, useMediaQuery } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import LinkTabs from '../../../../globals/LinkTabs';
import Collect from '../../../../globals/Collect';


export default connect(
  (state, ownProps) => {
    return {
      //STATE TO PROPS
      ///////////////////////////////////////////////////////////////////
      collectStatus: state.server.collectStatus,

    }
  },
  (dispatch, props) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions,
    ...UIActions
  }, dispatch)
)(function (props) {
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const { collect, dialogForm, collectStatus, deleteCollect, sendCollect, t, restartUpload, cancelUpload, deleteFile, getFilesOfCollect } = props;
  let params = useParams();
  let navigate = useNavigate();
  let id = parseInt(params.id);

  const bigScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));
  return <>
    <Collect
      back={() => { navigate("/") }}
      id={id}
      actions={bigScreen ? [
        {
          active: (values) => true,//,//values.canSend && values.status == collectStatus.STARTED,
          action: (values,setSuccess) => () => {
            if(values.status == collectStatus.STARTED) {
              if(values.canSend && values.status == collectStatus.STARTED) 
                return sendCollect(values.id).then(ok=>setSuccess(<>{t("collect.actions.send.ok")}</>));
              else
                return dialogForm({
                  label: t("collect.cantSend.title"),
                  text: t("collect.cantSend.text"),
                })
            } else 
              return Promise.resolve();
          },
          label: (values) => values.status==collectStatus.SENT ? t("collect.actions.sent.cta"):t("collect.actions.send.cta")
        },
        {
          active: (values) => values.status == collectStatus.STARTED,
          action: (values,setSuccess) => () => {
            //if (values.status == collectStatus.STARTED)
            return deleteCollect(values.id).then(ok => navigate("/"));
            /*else if(values.status == collectStatus.REFUSED)
              backToSendCollect(values.id);
            */
          },
          label: (values) => t("collect.actions.delete.cta")
        }
      ] : []}

      photosActions={[
        {
          active: (rows) => {
            //console.log(rows);
            return rows.filter(row => row.status !== "UPLOAD_OK_STORAGE").length ? true : false;
          },
          action: (rows, setUploads) => () => { restartUpload({ id, files: rows.filter(row => row.status !== "UPLOAD_OK_STORAGE") }) },
          label: (rows) => t("collects.actions.restart.cta")
        },
        {
          active: (rows) => rows.length ? true : false,
          action: (rows, setUploads) => () => {
            dialogForm({
              label: t("users.actions.deletePhoto.title"),
              text: t("users.actions.deletePhoto.text"),
            }).then(ok => {
              console.log("cancel rows", rows);
              var uploading = [];
              var uploads = [];
              for (var i = 0; i < rows.length; i++) {
                if (rows[i].status === "UPLOAD_OK_STORAGE")
                  uploads.push(rows[i]);
                else
                  uploading.push(rows[i]);
              }
              if (uploading.length > 0)
                cancelUpload({ id, files: uploading });

              Promise.allSettled(uploads.map(upload => deleteFile(upload.id))).then(ok => {
                getFilesOfCollect(id).then(({ data }) => {
                  setUploads(data);
                });
              });
            })
          },
          label: (rows) => t("collects.actions.cancel.cta")
        }
      ]}
    />
  </>
});
