import createCachedSelector, { LruObjectCache } from 're-reselect';
import keySelectorCombiner from './keySelectorCombiner';
import actions from '../actions/server';
import fieldsConf,{isGroup} from "../components/routes/admin/companies/one/form/fieldsConf";
const selector = {};

selector.users = createCachedSelector(
	(state) => state.server.users.data,
	(state) => state.server.users.v,
	(users, v) => {
		return users
	}
)({
	//on cache par les valeurs d update 
	keySelector: (state) => {
		//console.log(locales,labels,v);
		return "" + state.server.users.v
	},
	cacheObject: new LruObjectCache({ cacheSize: 1 }),
});

//-------------------------------------------------------------------------
//All locales options
//-------------------------------------------------------------------------
selector.localesOptions = createCachedSelector(
	(state) => state.server.locales,
	(state) => state.server.labels,
	(state) => state.server.labels.v,
	(locales, labels, v) => {
		return ["default",...locales].map(locale => ({
			value: locale,
			label: actions.t("locales." + locale + ".label")(() => { }, () => ({ server: { labels } }))
		}));
	}
)({
	//on cache par les valeurs d update 
	keySelector: (state) => {
		//console.log(locales,labels,v);
		return "" + state.server.labels.v
	},
	cacheObject: new LruObjectCache({ cacheSize: 1 }),
});
selector.collectsStatusOptions = createCachedSelector(
	(state) => Object.keys(state.server.collectStatus),
	(state) => state.server.labels,
	(state) => state.server.labels.v,
	(state)=>state.server.me,
	(collectStatus, labels, v,me) => {
		var toReturn = ["",...collectStatus].map(value => ({
				value,
				label: actions.t("collects.status." + value)(() => { }, () => ({ server: { labels } }))
			}));
		if(me.role !== "COLLECTOR")
			return toReturn;
		else
			return toReturn.filter(option=>option.value !== "DUMPED")
	}
)({
	//on cache par les valeurs d update 
	keySelector: (state) => {
		//console.log(locales,labels,v);
		return "" + state.server.labels.v+(state.server.me ? state.server.me.role:"")
	},
	cacheObject: new LruObjectCache({ cacheSize: 1 }),
});

//-------------------------------------------------------------------------
//All collectors options
//-------------------------------------------------------------------------
selector.collectorsOptions = createCachedSelector(
	(state,id) => state.server.users.data,
	(state,id) => id,
	(users,idDefault) => {
		if(!users) return [];
		var toReturn = [];
		for(var i=0;i<users.length;i++) {
			//for each user, accept it or not and put it first if this is the wanted default (will be first on the list)
			var user = users[i];
			var toAdd = {
				value: user.name,
				label: user.name
			};
			if(user.role=="COLLECTOR") {
				if(user.id==idDefault) {
					//wanted
					toReturn.unshift(toAdd);
				} else 
					toReturn.push(toAdd)
			}
		}
		//console.log("attention, selector of collectors",idDefault,toReturn);
		return toReturn;
	}
)({
	//on cache par les valeurs d update 
	keySelector: (state,id) => {
		//console.log(locales,labels,v);
		return "" + state.server.users.v+""+id
	},
	cacheObject: new LruObjectCache({ cacheSize: 10 }),
});

//-------------------------------------------------------------------------
//All components options
//-------------------------------------------------------------------------
selector.componentsOptions = createCachedSelector(
	(state) => state.server.labels,
	(state) => state.server.labels.v,
	(labels, v) => Object.keys(fieldsConf).map(component => ({
			value: component,
			isGroup:isGroup[component] ? true:false,
			label: actions.t("components." + component + ".label")(() => { }, () => ({ server: { labels } }))
		}))
)({
	//on cache par les valeurs d update 
	keySelector: (state) => {
		//console.log(locales,labels,v);
		return "" + state.server.labels.v
	},
	cacheObject: new LruObjectCache({ cacheSize: 1 }),
});

//-------------------------------------------------------------------------
//All components options
//-------------------------------------------------------------------------
selector.canEditCollect = createCachedSelector(
	(state,id) => state.server.me,
	(state,id) => state.server.collectDetail[id],
	(state,id) => state.server.collectStatus,
	(me, collect,collectStatus) => {
		
		var canEdit = me && (
			(me.role=="COLLECTOR" && (!collect || collect.status==collectStatus.STARTED))
			||
			(me.role=="ADMIN" && (!collect || collect.status==collectStatus.STARTED ||collect.status==collectStatus.SENT))
			||
			(me.role=="VALIDATOR" && (!collect || collect.status==collectStatus.SENT))
		);
		return canEdit;
	}
)({
	//on cache par les valeurs d update 
	keySelector: (state,id) => {
		return "" + JSON.stringify(state.server.me)+(state.server.collectDetail[id] ? state.server.collectDetail[id].status:id)
	},
	cacheObject: new LruObjectCache({ cacheSize: 1 }),
});

selector.defaultOptions = createCachedSelector(
	selector.localesOptions,
	selector.users,
	(localesOptions,users) => {
		return {
			SelectCollector:users.filter(({role})=>role=="COLLECTOR").map(user=>({
				value:""+user.id,
				label:user.name
			})),
			SelectLocales:localesOptions
		}
	}
)({
	//on cache par les valeurs d update 
	keySelectorCreator: keySelectorCombiner,
	cacheObject: new LruObjectCache({ cacheSize: 1 }),
});
export default selector;