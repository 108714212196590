import { TextField } from '@mui/material';
import React from 'react'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Field } from 'formik';
import Select from './Select';
import actions from '../../../actions/server';
import ErrorMessage from '../ErrorMessage';
import PropTypes from 'prop-types';
import selector from "../../../selectors";
const SelectLocale =  connect(
	
	 (state, ownProps) =>({
	   //STATE TO PROPS
	   ///////////////////////////////////////////////////////////////////
		options:selector.localesOptions(state)
	 }),
	 (dispatch, props) => bindActionCreators({
	   //ACTIONS
	   ///////////////////////////////////////////////////////////////////
	 }, dispatch)
   )(function({options,...props}){
	 //COMPONENT
	 ///////////////////////////////////////////////////////////////////
	 //Import global styles
	 
	 return <>
		<Field as={Select} options={options} {...props}/>
		{props.name && <ErrorMessage sx={{marginTop:1}} name={props.name} />}
	</>
   });


   SelectLocale.defaultProps = {
	   options:PropTypes.arrayOf(PropTypes.shape({
		value:PropTypes.any,
		label:PropTypes.string
	}))
   }
   export default SelectLocale;