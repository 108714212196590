import React from 'react'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Select,FormControl,InputLabel, NativeSelect ,MenuItem, useMediaQuery} from '@mui/material';
import { useTheme } from '@emotion/react';
import { Field, getIn, useFormikContext } from 'formik';
import ErrorMessage from '../ErrorMessage';
export default connect(
	/* ownProps : {
		visible:boolean,
		name,
		required,
		options:[
			{
				value,
				label
			}
		],
		defaultValue:value,
		value,
		name
	}
	 }*/
	 (state, ownProps) =>{return {
	   //STATE TO PROPS
	   ///////////////////////////////////////////////////////////////////
   
	 }},
	 (dispatch, props) => bindActionCreators({
	   //ACTIONS
	   ///////////////////////////////////////////////////////////////////
	 
	 }, dispatch)
   )(function(props){
	//COMPONENT
	///////////////////////////////////////////////////////////////////
	const {visible=true,value, required,label,variant="outlined", options=[],sx={},...inputProps} = props; 
	const bigScreen = useMediaQuery((theme) => theme.breakpoints.up('xs'));

	const [labelId,setLabelId] = React.useState("label-"+Math.floor(Math.random()*10000));
	
	//MUI not capable of managing correctly select multiple with undefined value
	//------------------------------
	var formikBag = useFormikContext();
	var render = true;
	var valueToTransfer={}
	if(typeof value=="undefined") {
		//CASE SEND BY NAME
		var valueOfField = getIn(formikBag.values,inputProps.name);
		if(inputProps.multiple && !Array.isArray(valueOfField)) {
			if(valueOfField)
				formikBag.setFieldValue(inputProps.name,[valueOfField]);
			else
				formikBag.setFieldValue(inputProps.name,[]);

			render=false;
		}
	} else{
		//CASE SENT by value
		if(inputProps.multiple && !Array.isArray(value)) {
			if(value) 
				valueToTransfer={value:[value]};
			else
				valueToTransfer={value:[]};
		} else 
			valueToTransfer={value:value}
   	}	
	if(!render) return null;
	///MUI not capable of managing correctly select multiple with undefined value
	//------------------------------

	return <>
		{bigScreen ? 
		<FormControl fullWidth sx={sx}>
			<InputLabel id={labelId} required={required}>{label}</InputLabel>
			<Field as={Select}
				variant={variant}
				labelId={labelId}
				label={label}
				{...inputProps}
   				{...valueToTransfer}
			>
				{(Array.isArray(options) ? options:options(formikBag)).map((optioni,i)=><MenuItem key={i+" "+optioni.value}value={optioni.value}>{optioni.label}</MenuItem>)}
			</Field>
		</FormControl>
		:
		<FormControl sx={sx}>
			<InputLabel id={labelId} required={required}>{label}</InputLabel>
			<Field  fullWidth sx={sx} as={NativeSelect}
				variant={variant}
				{...inputProps}
				{...valueToTransfer}
			>
				{options.map((optioni,i)=><option key={i+" "+optioni.value} value={optioni.value}>{optioni.label}</option>)}
			</Field>
		</FormControl>
		}
		{props.name && <ErrorMessage sx={{marginTop:1}} name={props.name} />}
	</>
});