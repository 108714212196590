import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import React from 'react'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Field, useFormikContext} from 'formik';
import ErrorMessage from '../ErrorMessage';
export default connect(
	 (state, ownProps) =>{return {
	   //STATE TO PROPS
	   ///////////////////////////////////////////////////////////////////
   
	 }},
	 (dispatch, props) => bindActionCreators({
	   //ACTIONS
	   ///////////////////////////////////////////////////////////////////
	 
	 }, dispatch)
   )(function({options=[],...props}){
	 //COMPONENT
	 ///////////////////////////////////////////////////////////////////
	 //Import global styles
	 //console.log(options,props);
	 const formik = useFormikContext();

	 return <>
	 	<Field 
		 	as={Autocomplete}
			options={options}
			onChange={(e, value) => formik.setFieldValue(props.name, value)}
			renderInput={(params) => <TextField fullWidth {...params} label={props.label}  name={props.name}/>}
			{...props}
		/>
		 {props.name && <ErrorMessage sx={{marginTop:1}} name={props.name} />}
	</>
   });