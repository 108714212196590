import { TextField } from '@mui/material';
import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field } from 'formik';
import Select from './Select';
import actions from '../../../actions/server';
import ErrorMessage from '../ErrorMessage';
import PropTypes from 'prop-types';
import selector from "../../../selectors";
import { useParams,useLocation, matchPath} from 'react-router-dom';
const SelectCollector = connect(

	(state, ownProps) => ({
		//STATE TO PROPS
		///////////////////////////////////////////////////////////////////
		options: selector.collectorsOptions(state,null),
		me: state.server.me,
	}),
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		getCollect:actions.getCollect
	}, dispatch)
)(function ({ options, getCollect,me, ...props }) {
	//COMPONENT
	///////////////////////////////////////////////////////////////////
	//Import global styles
	
	return <>
		<Field as={Select} options={options}  {...props} />
		{props.name && <ErrorMessage name={props.name} />}
	</>
});

SelectCollector.defaultProps = {
	options: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.any,
		label: PropTypes.string
	}))
}
export default SelectCollector;