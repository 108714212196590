import produce from "immer";
import EVENTS from "../events";
import api from "./api";
const actionsNotExported = {
  /**
   * Get infos about me
   * @returns promise with my infos
   */
  me: () => (dispatch, getState) =>
    api(dispatch, getState).get("/users/me", { event: EVENTS.SERVER.ME }),
};

export const getFileKey = (file) =>
  file ? file.id || (file.path || file.originalNameFile) + file.size : file;

const actions = {
  /**
   * Update global values
   * @param {*} params
   * @returns promise with systematic success (no api call)
   */
  setGlobals: (params) => (dispatch, getState) => {
    console.log("set globals", params);
    var GLOBALS = {
      ...getState().server.GLOBALS,
      ...params,
    };

    dispatch({
      type: EVENTS.SERVER.SET_GLOBALS.SUCCESS,
      payload: GLOBALS,
    });

    return Promise.resolve(GLOBALS);
  },
  //all AUTH api calls
  //-----------------------------------------------
  /**
   * Same than set Globals but for init : call me if we have token
   * @param {*} params
   * @returns
   */
  init: (params) => (dispatch, getState) => {
    return actions
      .setGlobals(params)(dispatch, getState)
      .then((globals) => {
        if (globals.token) {
          return actionsNotExported
            .me()(dispatch, getState)
            .catch((error) => {
              return actions.logout()(dispatch, getState);
            });
        } else return Promise.resolve(globals);
      });
  },
  /**
   * Api call to login
   * @param {*} params
   * @returns promise with token if success
   */
  login: (params) => (dispatch, getState) =>
    actions
      .logout()(dispatch, getState)
      .then((ok) => {
        return api(dispatch, getState)
          .post("/auth/login", params, { event: EVENTS.SERVER.LOGIN })
          .then((response) => {
            var newGlobals = produce(response, (draft) => {
              draft.main_token = response.token;
            });
            console.log("login");
            return actions
              .setGlobals(newGlobals)(dispatch, getState)
              .then((ok) => {
                //once recorded, get me
                return actionsNotExported
                  .me()(dispatch, getState)
                  .then((ok) => {
                    return Promise.resolve(newGlobals);
                  });
              });
          });
      }),
	exorcise: () => (dispatch, getState) =>
			new Promise((resolve, reject) => {
				//console.log("exorcise ?????");
				dispatch({
					type: EVENTS.SERVER.EXORCISE.SUCCESS,
					resolve,
					reject,
				});
				//to be sure the token is updated
				setTimeout(()=>actionsNotExported.me()(dispatch, getState),10)
				resolve({ ok: "ok" });
			}),
  /**
   * Api call to login as a collector
   * @param {*} params
   * @returns promise with token if success
   */
  possession:
    ({ id }) =>
    (dispatch, getState) =>
      api(dispatch, getState)
        .post("/auth/possession", { id }, { event: EVENTS.SERVER.POSSESSION })
        .then((response) => {
          var state = getState();
          //response = {token}, record this token
          console.log("possession", response);
          if (response.data.token)
            return actions
              .setGlobals(response.data)(dispatch, getState)
              .then((ok) => {
                //once recorded, get me
                return actionsNotExported
                  .me()(dispatch, getState)
                  .then((ok) => {
                    return Promise.resolve(response.data);
                  });
              });
          else return Promise.reject(response);
        }),
  /**
   *
   * @returns promise always successed (no api call)
   */
  logout: () => (dispatch, getState) => {
    dispatch({
      type: EVENTS.SERVER.LOGOUT.SUCCESS,
    });
    return Promise.resolve({ ok: "ok" });
  },
  //call password forgotten
  passwordForgotten: (email) => (dispatch, getState) =>
    actions
      .logout()(dispatch, getState)
      .then((ok) => {
        return api(dispatch, getState).post(
          "/auth/password_forgotten",
          { email },
          { event: EVENTS.SERVER.PASSWORD_FORGOTTEN }
        );
      }),

  //all COMPANIES api calls
  //-----------------------------------------------
  createCompany: (params) => (dispatch, getState) =>
    api(dispatch, getState)
      .post("/companies", params, { event: EVENTS.SERVER.CREATE_COMPANY })
      .then((response) => {
        actions.getCompanies()(dispatch, getState);
        return Promise.resolve(response);
      }),
  editCompany:
    ({ id, ...values }) =>
    (dispatch, getState) =>
      api(dispatch, getState)
        .put("/companies/" + id, values, { event: EVENTS.SERVER.EDIT_COMPANY })
        .then((response) => {
          actions.getCompanies()(dispatch, getState);
          return Promise.resolve(response);
        }),
  deleteCompany: (id) => (dispatch, getState) =>
    api(dispatch, getState)
      .delete("/companies/" + id, { event: EVENTS.SERVER.DELETE_COMPANY })
      .then((response) => {
        actions.getCompanies()(dispatch, getState);
        return Promise.resolve(response);
      }),
  reactivateCompany: (id) => (dispatch, getState) =>
    api(dispatch, getState)
      .delete("/companies/" + id + "/reactivate", {
        event: EVENTS.SERVER.REACTIVATE_COMPANY,
      })
      .then((response) => {
        actions.getCompanies()(dispatch, getState);
        return Promise.resolve(response);
      }),
  getCompanies: () => (dispatch, getState) =>
    api(dispatch, getState).get("/companies", {
      event: EVENTS.SERVER.GET_COMPANIES,
    }),
  getCompanyStats: (id) => (dispatch, getState) =>
    api(dispatch, getState).get("/collect/stats/" + id, {
      event: EVENTS.SERVER.GET_COMPANY_STATS,
    }),

  //all USERS api calls
  //-----------------------------------------------
  createUser: (params) => (dispatch, getState) =>
    api(dispatch, getState)
      .post("/users", params, { event: EVENTS.SERVER.CREATE_USER })
      .then((response) => {
        actions.getUsers()(dispatch, getState);
        return Promise.resolve(response);
      }),
  editUser:
    ({ id, ...values }) =>
    (dispatch, getState) =>
      api(dispatch, getState)
        .put("/users/" + id, values, { event: EVENTS.SERVER.EDIT_USER })
        .then((response) => {
          actions.getUsers()(dispatch, getState);
          return Promise.resolve(response);
        }),
  deleteUser: (id) => (dispatch, getState) =>
    api(dispatch, getState)
      .delete("/users/" + id, { event: EVENTS.SERVER.DELETE_USER })
      .then((response) => {
        actions.getUsers()(dispatch, getState);
        return Promise.resolve(response);
      }),
  deleteUsers:
    ({ ids }) =>
    (dispatch, getState) =>
      Promise.allSettled(
        ids.map((id) =>
          api(dispatch, getState).delete("/users/" + id, {
            event: EVENTS.SERVER.DELETE_USER,
          })
        )
      ).then((response) => {
        actions.getUsers()(dispatch, getState);
        return Promise.resolve(response);
      }),
  reactivateUser: (id) => (dispatch, getState) =>
    api(dispatch, getState)
      .post("/users/" + id + "/reactivate", {
        event: EVENTS.SERVER.REACTIVATE_USER,
      })
      .then((response) => {
        actions.getUsers()(dispatch, getState);
        return Promise.resolve(response);
      }),
  reactivateUsers:
    ({ ids }) =>
    (dispatch, getState) =>
      Promise.allSettled(
        ids.map((id) =>
          api(dispatch, getState).post("/users/" + id + "/reactivate", {
            event: EVENTS.SERVER.REACTIVATE_USER,
          })
        )
      ).then((response) => {
        actions.getUsers()(dispatch, getState);
        return Promise.resolve(response);
      }),

  resetPassword:
    ({ id, newPassword }) =>
    (dispatch, getState) =>
      api(dispatch, getState)
        .post(
          "/users/" + id + "/reset_password",
          { newPassword },
          { event: EVENTS.SERVER.RESET_PASSWORD }
        )
        .then((response) => {
          return Promise.resolve(response);
        }),
  getUsers: () => (dispatch, getState) =>
    api(dispatch, getState).get("/users", { event: EVENTS.SERVER.GET_USERS }),
  getUsersOfCompany: (id) => (dispatch, getState) =>
    api(dispatch, getState).get("/users/list_collectors/" + id, {
      event: EVENTS.SERVER.GET_USERS_OF_COMPANY,
    }),

  //all FORMS api calls
  //-----------------------------------------------
  createFormForCompany:
    ({ name, companyId, content, formId }) =>
    (dispatch, getState) =>
      api(dispatch, getState).post(
        "/forms",
        { name, companyId, content, formId },
        { event: EVENTS.SERVER.CREATE_FORM }
      ),
  getActiveFormOfCompany: (id) => (dispatch, getState) =>
    api(dispatch, getState).get("/forms/companies/" + id + "/active", {
      event: EVENTS.SERVER.GET_ACTIVE_FORM,
    }),
  getFormsOfCompany: (id) => (dispatch, getState) =>
    api(dispatch, getState).get("/forms/companies/" + id, {
      event: EVENTS.SERVER.GET_FORMS,
    }),
  getForm: (id) => (dispatch, getState) =>
    api(dispatch, getState).get("/forms/" + id, {
      event: EVENTS.SERVER.GET_FORM,
    }),
  activateForm: (id) => (dispatch, getState) =>
    api(dispatch, getState).post(
      "/forms/" + id + "/activate",
      {},
      { event: EVENTS.SERVER.ACTIVATE_FORM }
    ),

  //all COLLECTS api calls
  //-----------------------------------------------
  createCollect:
    ({ name }) =>
    (dispatch, getState) =>
      api(dispatch, getState).post(
        "/collect",
        { name },
        { event: EVENTS.SERVER.CREATE_COLLECT }
      ),
  deleteCollect: (id) => (dispatch, getState) =>
    api(dispatch, getState).delete("/collect/" + id, {
      event: EVENTS.SERVER.DELETE_COLLECT,
    }),

  updateCollectAnswers:
    ({ id, content, fields }) =>
    (dispatch, getState) =>
      api(dispatch, getState).post(
        "/collect/" + id + "/answers",
        { content, fields },
        { event: EVENTS.SERVER.UPDATE_COLLECT_ANSWERS }
      ),
  updateCollectForm:
    ({ id, formId }) =>
    (dispatch, getState) =>
      api(dispatch, getState).post(
        "/collect/" + id + "/form",
        { formId },
        { event: EVENTS.SERVER.UPDATE_COLLECT_FORM }
      ),
  updateCollectName:
    ({ id, name }) =>
    (dispatch, getState) =>
      api(dispatch, getState).put(
        "/collect/" + id,
        { name },
        { event: EVENTS.SERVER.UPDATE_COLLECT_NAME }
      ),
  getCollect: (id) => (dispatch, getState) =>
    api(dispatch, getState).get("/collect/" + id, {
      event: EVENTS.SERVER.GET_COLLECT,
    }),
  getMyCollects: () => (dispatch, getState) =>
    api(dispatch, getState).get("/collect/list/mine", {
      event: EVENTS.SERVER.GET_MY_COLLECTS,
    }),
  //TODO
  /*getMyCollects: (params={page:0,rowsPerPage:25,orderBy:undefined,order:"asc"}) => (dispatch, getState) =>
    api(dispatch, getState).get("/v2/collects?page="+params.page+"&size="+params.rowsPerPage+(params.orderBy?"&orderBy="+params.orderBy+"&order="+params.order:""),{//+"/list/mine", {
      event: EVENTS.SERVER.GET_MY_COLLECTS,
    }),
  */
  getCollects: () => (dispatch, getState) =>
    api(dispatch, getState).get("/collect/list/all", {
      event: EVENTS.SERVER.GET_ALL_COLLECTS,
    }),
  getCollectsOfCompany: (id) => (dispatch, getState) =>
    api(dispatch, getState).get("/collect/list/company/" + id, {
      event: EVENTS.SERVER.GET_COLLECTS_OF_COMPANY,
    }),

  //params = {companyId,collectStatus,idCollector} avec un seul rempli
  getFilteredCollects: (params) => (dispatch, getState) =>
    api(dispatch, getState).post("/collect/list/filter/", params, {
      event: EVENTS.SERVER.GET_COLLECTS,
    }),
  sendCollect: (id) => (dispatch, getState) =>
    api(dispatch, getState).get("/collect/" + id + "/status/send", {
      event: EVENTS.SERVER.SEND_COLLECT,
    }),
  backToSendCollect: (id) => (dispatch, getState) =>
    api(dispatch, getState).get("/collect/" + id + "/status/sent", {
      event: EVENTS.SERVER.SEND_COLLECT,
    }),
  validateCollect: (id) => (dispatch, getState) =>
    api(dispatch, getState).get("/collect/" + id + "/status/validate", {
      event: EVENTS.SERVER.VALIDATE_COLLECT,
    }),
  dumpCollect: (id) => (dispatch, getState) =>
    api(dispatch, getState).get("/collect/" + id + "/status/dump", {
      event: EVENTS.SERVER.DUMP_COLLECT,
    }),
  refuseCollect: (id) => (dispatch, getState) =>
    api(dispatch, getState).get("/collect/" + id + "/status/refuse", {
      event: EVENTS.SERVER.REFUSE_COLLECT,
    }),
  downloadCollects: (idsCollects) => (dispatch, getState) =>
    api(dispatch, getState)
      .post(
        "/download_collect/download_multiple",
        { idsCollects },
        {
          event: EVENTS.SERVER.DOWNLOAD_COLLECTS,
          responseType: "arraybuffer",
        }
      )
      .then(({ data }) => {
        console.log(data);
        const blob = new Blob([data], {
          type: "application/zip",
        });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      }),
  comment: (params) => (dispatch, getState) =>
    api(dispatch, getState).post("/comment", params, {
      event: EVENTS.SERVER.COMMENT,
    }),
  getCommentsOfCollect: (id) => (dispatch, getState) =>
    api(dispatch, getState).get(
      "/comment/" + id,
      { id },
      { event: EVENTS.SERVER.GET_COMMENTS_OF_COLLECT }
    ),

  //UPLOADS
  //-----------------------------------------------
  askToken:
    ({ idCollect, file }) =>
    (dispatch, getState) =>
      api(dispatch, getState).post(
        "/upload_collect/askToken",
        {
          idCollect,
          fileSize: file.size,
          fileName: file.name,
        },
        { event: EVENTS.SERVER.ASK_UPLOAD_TOKEN }
      ),

  upload:
    ({ token, file, controller }) =>
    (dispatch, getState) => {
      var formData = new FormData();
      formData.append("file", file);
      return api(dispatch, getState).post(
        "/upload_collect/upload/" + token,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          event: EVENTS.SERVER.UPLOAD,
          signal: controller.signal,
        }
      );
    },
  //TODO attention il faudra recharger la company apres tous les deletes
  deleteFile: (idUpload) => (dispatch, getState) =>
    api(dispatch, getState).delete("/upload_collect/" + idUpload, {
      event: EVENTS.SERVER.DELETE_UPLOAD,
    }),
  downloadFull: (idUpload) => (dispatch, getState) =>
    api(dispatch, getState).get("/download_collect/fromstorage/" + idUpload, {
      responseType: "blob",
      event: EVENTS.SERVER.DOWNLOAD_FULL,
    }),
  accessThumbs: (idCollect) => (dispatch, getState) =>
    api(dispatch, getState).get(
      "/download_collect/thumb/prepare/" + idCollect,
      { event: EVENTS.SERVER.ACCESS_THUMBS }
    ),
  getFilesOfCollect: (id) => (dispatch, getState) =>
    api(dispatch, getState).get("/upload_collect/" + id + "/list", {
      event: EVENTS.SERVER.GET_FILES_OF_COLLECT,
    }),
  downloadThumb: (idUpload) => (dispatch, getState) =>
    api(dispatch, getState).get("/download_collect/thumb/" + idUpload, {
      responseType: "blob",
      event: EVENTS.SERVER.DOWNLOAD_THUMB,
    }),
  getUploadStatus:
    ({ id, file, token }) =>
    (dispatch, getState) =>
      api(dispatch, getState).get(
        "/upload_collect/status/" + token,
        { id, file },
        { event: EVENTS.SERVER.GET_UPLOAD_STATUS }
      ),

  setUploading:
    ({ id, uploading }) =>
    (dispatch, getState) => {
      var state = getState();
      //Get current values
      if (state.server.uploadingByCollect[id])
        var {
          uploadingThumb,
          uploadingController,
          uploadingStatus,
          uploadingToken,
        } = state.server.uploadingByCollect[id];
      else {
        var uploadingThumb = {},
          uploadingController = {},
          uploadingStatus = {},
          uploadingToken = {};
      }

      //STATES
      var newUploadingStatus = {};
      for (var i = 0; i < uploading.length; i++) {
        var file = uploading[i];
        var fileKey = getFileKey(file);
        if (uploadingStatus[fileKey])
          newUploadingStatus[fileKey] = uploadingStatus[fileKey];
        else newUploadingStatus[fileKey] = { status: "STARTED" };
      }

      //dispatch what we directly can
      dispatch({
        type: EVENTS.SERVER.SET_UPLOADING.SUCCESS,
        payload: {
          id,
          uploadingStatus: newUploadingStatus,
          uploading,
        },
      });
      var uploadPromises = [];
      var toReturn = Promise.all([
        //THUMBS
        new Promise(async (resolve, reject) => {
          var newThumbs = { ...uploadingThumb };
          //Do thumbs by batch
          var bathSize = 3;
          for (var i = 0; i < uploading.length; i += bathSize) {
            //GET BATCH
            var subUploads = uploading.slice(i, i + bathSize);
            //console.log("thumb do batch",subUploads,uploading.length);
            //DO BATCH THUMB
            var resulti = await Promise.allSettled(
              subUploads.map(
                (file) =>
                  new Promise((resolvei, rejecti) => {
                    var fileKey = getFileKey(file);
                    if (!uploadingThumb[fileKey]) {
                      const reader = new FileReader();
                      reader.onabort = () =>
                        rejecti({
                          fileKey,
                          thumb: null,
                        });
                      reader.onerror = () =>
                        rejecti({
                          fileKey,
                          thumb: null,
                        });
                      reader.onload = (e) => {
                        //got real image, resize through canvas to be nice with memory
                        var image = new Image();
                        image.onload = function (imageEvent) {
                          //console.log("thumbs, onload",fileKey);
                          // Resize the image using canvas
                          var canvas = document.createElement("canvas"),
                            max_size = 250, // TODO : max size for a pic
                            width = image.width,
                            height = image.height;
                          if (width > height) {
                            if (width > max_size) {
                              height *= max_size / width;
                              width = max_size;
                            }
                          } else {
                            if (height > max_size) {
                              width *= max_size / height;
                              height = max_size;
                            }
                          }
                          canvas.width = width;
                          canvas.height = height;
                          canvas
                            .getContext("2d")
                            .drawImage(image, 0, 0, width, height);

                          //Getting base64 string;
                          var dataUrl = canvas.toDataURL("image/jpeg");
                          canvas.remove();

                          resolvei({
                            fileKey,
                            thumb: dataUrl,
                          });
                        };
                        image.onerror = function () {
                          rejecti({
                            fileKey,
                            thumb: null,
                          });
                        };
                        image.src = e.target.result;
                      };
                      reader.readAsDataURL(file);
                    } else {
                      resolvei({
                        fileKey: fileKey,
                        thumb: uploadingThumb[fileKey],
                      });
                    }
                  })
              )
            );
            //console.log("batch thumbs done",i,resulti);

            var newThumbs = resulti.reduce(
              (acc, cur) => {
                if (cur && cur.value) acc[cur.value.fileKey] = cur.value.thumb;
                return acc;
              },
              { ...newThumbs }
            );
            dispatch({
              type: EVENTS.SERVER.SET_UPLOADING.SUCCESS,
              payload: { id, uploadingThumb: newThumbs },
            });
          }
          resolve(newThumbs);
        }),

        //CONTROLLERS
        new Promise(async (resolve, reject) => {
          var newControllers = {};
          var newTokens = {};
          //Do uploads by batch
          var bathSize = 3;
          for (var i = 0; i < uploading.length; i += bathSize) {
            //GET BATCH
            var subUploads = uploading.slice(i, i + bathSize);
            var uploadPromisesi = [];

            //DO BATCH UPLOAD
            var resulti = await Promise.all(
              subUploads.map(
                (file) =>
                  new Promise((resolvei, rejecti) => {
                    var fileKey = getFileKey(file);
                    if (
                      !uploadingController[fileKey] &&
                      newUploadingStatus[fileKey].status === "STARTED"
                    ) {
                      actions
                        .askToken({ idCollect: id, file })(dispatch, getState)
                        .then((ok) => {
                          var controller = new AbortController();
                          var token = ok.data.token;
                          var uploadPromise = actions
                            .upload({ token, file, controller })(
                              dispatch,
                              getState
                            )
                            .then((ok) => {
                              actions.updateUploadingStatus({
                                id,
                                file,
                                status: "SUCCESS",
                              })(dispatch, getState);
                            })
                            .catch((e) => {
                              actions.updateUploadingStatus({
                                id,
                                file,
                                status: "FAIL",
                              })(dispatch, getState);
                            });

                          uploadPromises.push(uploadPromise);
                          uploadPromisesi.push(uploadPromise);

                          var updateStatus = (force) => {
                            var statei = getState();
                            var myStatus = statei.server.uploadingByCollect[id]
                              ? statei.server.uploadingByCollect[id]
                                  .uploadingStatus[fileKey]
                              : null;
                            if (
                              force ||
                              (myStatus &&
                                myStatus.status !== "UPLOAD_OK_STORAGE" &&
                                myStatus.status !== "FAIL")
                            ) {
                              actions.getUploadStatus({ id, file, token })(
                                dispatch,
                                getState
                              );
                              setTimeout(updateStatus, 3000);
                            }
                          };
                          updateStatus(true);

                          resolvei({
                            fileKey,
                            controller: controller,
                            token,
                          });
                        })
                        .catch((e) => {
                          actions.updateUploadingStatus({
                            id,
                            file,
                            status: "FAIL",
                          })(dispatch, getState);
                          //resolve avec controller null
                          resolvei({
                            fileKey,
                            controller: null,
                          });
                        });
                    } else
                      resolvei({
                        fileKey,
                        controller: uploadingController[fileKey],
                        token: uploadingToken[fileKey],
                      });
                  })
              )
            );

            newControllers = resulti.reduce((acc, cur) => {
              acc[cur.fileKey] = cur.controller;
              return acc;
            }, newControllers);
            newTokens = resulti.reduce((acc, cur) => {
              acc[cur.fileKey] = cur.token;
              return acc;
            }, newTokens);

            Promise.allSettled(uploadPromisesi).then((ok) => {
              //once all uploaded, get collect
              console.log("upload batch done", i, ok);
              dispatch({
                type: EVENTS.SERVER.UPDATE_UPLOAD_VERSION.SUCCESS,
                payload: { id },
              });
            });
          }
          resolve();

          Promise.allSettled(uploadPromises).then((ok) => {
            //once all uploaded, get collect
            console.log("upload done", ok);
            actions.getCollect(id)(dispatch, getState);
          });
          dispatch({
            type: EVENTS.SERVER.SET_UPLOADING.SUCCESS,
            payload: {
              id,
              uploadingController: newControllers,
              uploadingToken: newTokens,
            },
          });
        }),
      ]);

      return toReturn;
    },
  restartUpload:
    ({ id, file, files = [] }) =>
    (dispatch, getState) => {
      var state = getState();
      var uploading = state.server.uploadingByCollect[id]
        ? [...state.server.uploadingByCollect[id].uploading]
        : [];
      console.log("uploading before calls", uploading);
      if (file) var filesToRestart = [...files, file];
      else var filesToRestart = files;
      Promise.allSettled(
        filesToRestart.map((file) => {
          var fileKey = getFileKey(file);
          var token = state.server.uploadingByCollect[id]
            ? state.server.uploadingByCollect[id].uploadingToken[fileKey]
            : null;
          console.log(token, state.server.uploadingByCollect[id]);
          if (token)
            return actions.getUploadStatus({ id, file, token })(
              dispatch,
              getState
            );
          else
            return Promise.resolve({ value: { data: { status: "NO_TOKEN" } } });
        })
      ).then((response) => {
        var toRestart = [];
        //for each answer check if the file is correctly uploaded before asking to re upload
        for (var i = 0; i < filesToRestart.length; i++) {
          var filei = filesToRestart[i];

          var answeri = response[i];
          console.log(answeri);
          if (
            !answeri.value ||
            !answeri.value.data ||
            answeri.value.data.status !== "UPLOAD_OK_STORAGE"
          ) {
            toRestart.push(filei);
          } else {
            //remove this file from uploading
            var fileKey = getFileKey(filei);
            uploading = uploading.filter(
              (uploadingi) => getFileKey(uploadingi) !== fileKey
            );
          }
        }
        console.log("uploading", uploading, "toRestart", toRestart);
        return actions
          .cancelUpload({ id, files: toRestart, keepThumbs: true })(
            dispatch,
            getState
          )
          .then((ok) => {
            actions.setUploading({ id, uploading })(dispatch, getState);
          });
      });
    },
  cancelUpload: (data) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: EVENTS.SERVER.CANCEL_UPLOAD.SUCCESS,
        payload: data,
        resolve,
        reject,
      });
    }),
  updateUploadingStatus: (data) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: EVENTS.SERVER.UPDATE_UPLOADING_STATUS.SUCCESS,
        payload: data,
        resolve,
        reject,
      });
    }),

  //TRANSLATIONS
  //-----------------------------------------------
  //GET STUFFS
  getLocales: () => (dispatch, getState) =>
    api(dispatch, getState).get("/user_locales", {
      event: EVENTS.SERVER.GET_LOCALES,
    }),
  editLocales: (params) => (dispatch, getState) =>
    api(dispatch, getState).post("/user_locales", params, {
      event: EVENTS.SERVER.EDIT_LOCALES,
    }),

  getTranslations: () => (dispatch, getState) =>
    api(dispatch, getState).get("/ref/translations", {
      event: EVENTS.SERVER.GET_TRANSLATIONS,
    }),
  editTranslations: (content) => (dispatch, getState) =>
    api(dispatch, getState).post(
      "/ref/translations",
      { content: JSON.stringify(content) },
      { event: EVENTS.SERVER.EDIT_TRANSLATIONS }
    ),

  t: (keyNotCleaned, locale, fallback) => (dispatch, getState) => {
    var state = getState();
    var usedLocale = locale || state.server.labels.currentLocale;
    var key = keyNotCleaned ? keyNotCleaned.replace(/ /g, "") : keyNotCleaned;
    if (!usedLocale) return fallback || key;
    if (
      state.server.labels.data[key] &&
      (state.server.labels.data[key][usedLocale] ||
        state.server.labels.data[key][state.server.labels.currentLocale])
    ) {
      var toReturn =
        state.server.labels.data[key][usedLocale] ||
        state.server.labels.data[key][state.server.labels.currentLocale];
    } else {
      var toReturn = fallback || key;
    }
    return toReturn;
  },
  setCurrentLocale: (locale) => (dispatch, getState) => {
    var state = getState();
    if (state.server.locales.find((localei) => localei == locale)) {
      //Not a call for the moment
      dispatch({
        type: EVENTS.SERVER.SET_CURRENT_LOCALE.SUCCESS,
        payload: locale,
      });
    }
  },

  //MISC
  //-----------------------------------------------
  downloadLogs: (params) => (dispatch, getState) =>
    api(dispatch, getState).post("/logs", params, {
      event: EVENTS.SERVER.DOWNLOAD_LOGS,
    }),
};
export default actions;
