import Select from "./Select"
import SelectWithOther from "./SelectWithOther";
import SelectCollector from "./SelectCollector";
import Text from "./Text";
import Switch from "./Switch";
import Date from "./Date";
import MatrixTable from "./MatrixTable";
import RichText from "./RichText";
import Radio from "./Radio";
import Number from "./Number";
import GeoLoc from "./GeoLoc";
export default {
	Date,
	Select,
	SelectWithOther,
	SelectCollector,
	Radio,
	Text,
	Switch,
	MatrixTable,
	RichText,
	Number,
	GeoLoc
}
export const isGroup = {
	MatrixTable:true
}

export const hasOptions = {
	Select:true,
	SelectWithOther:true,
	Radio:true,
}