import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import actions from '../../../actions/server';
import UIActions from "../../../actions/ui";
import { Formik, Form } from 'formik';
import produce from 'immer';
import * as Yup from 'yup';

import { Grid, Button, Box, useMediaQuery, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import Fields from '../Fields';
import selector from '../../../selectors';
import { useLocation } from 'react-router-dom';

/**
 * content of the Component to answer the form
 */
const FormContent = connect(
	(state, ownProps) => {
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////
			currentLocale: state.server.labels.currentLocale,
			notCollector: !state.server.me || state.server.me.role !== "COLLECTOR"
		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		...actions,
		//get default value of a field
		getDefaultValue: UIActions.getDefaultValue
	}, dispatch)
)(function ({
	onChange = () => { },
	id,
	notCollector,
	setValidationSchema,
	getDefaultValue,
	currentLocale,
	t,
	getCollect,
	...props }) {

	const location = useLocation();
	const formikBag = useFormikContext();
	const { currentSection, form = [], answers } = formikBag.values;
	const [showingSummary, setShowingSummary] = React.useState(false);
	//the object {...fieldName:componentName}, used to check a condition
	const [fields, setFields] = React.useState({});
	const [acceptedCurrentSectionFields, setAcceptedCurrentSectionFields] = React.useState([])
	const [overriddenEmptyFields, setOverriddenEmptyFields] = React.useState({});

	//UPDATE ALL FIELDS OBJECT WHEN NEW FORM
	React.useEffect(() => {
		var newFields = {};
		if (!form) return;
		//Loop thnough sections
		for (var sectionIndex = 0; sectionIndex < form.length; sectionIndex++) {
			var section = form[sectionIndex];
			//loop through fields
			for (var fieldIndex = 0; fieldIndex < section.fields.length; fieldIndex++) {
				var field = section.fields[fieldIndex];
				if (Array.isArray(field.fields)) {
					//loop through sub fields
					for (var i = 0; i < field.fields.length; i++) {
						var subField = field.fields[i];
						newFields[subField.name] = subField.component;
					}
				} else if (field.component !== "RichText") {
					newFields[field.name] = field.component;
				}
			}
			setFields(newFields);
		}
	}, [form])

	//When arriving in a section or updating value
	React.useEffect(() => {
		(async () => {
			if (!form || !form.length) return;
			//New array of fields in current section
			var newAcceptedCurrentSectionFields = [];
			var section = form[currentSection];
			//answers will be updated depending of default values of accepted current fields
			var newAnswers = { ...answers };
			//We store the fields that were overridden (automatically set from an empty value) because a default value for a fieldName can change
			var newOverridden = { ...overriddenEmptyFields };
			//hash of accepted fields (for fast access)
			var accepted = {};

			//loop through fields
			for (var fieldIndex = 0; fieldIndex < section.fields.length; fieldIndex++) {
				var field = section.fields[fieldIndex];
				var accept = acceptCondition((field.condition ? JSON.parse(field.condition) : field.condition));
				if (!accept) {
					//record that this field is not accepted if it is not already accepted
					if (!accepted[field.name])
						accepted[field.name] = false;

					//go to next field
					continue;
				};

				//this field is accepted
				accepted[field.name] = true;

				if (Array.isArray(field.fields)) {
					//loop through sub fields and add them as new accepted fields
					for (var i = 0; i < field.fields.length; i++) {
						newAcceptedCurrentSectionFields.push({
							...field.fields[i],
							toDelete: true
						});
					}
				}
				//also add the field (note this field can be a group and have no name)
				newAcceptedCurrentSectionFields.push(field);
			}
			//loop through each accepted field to update value if needed
			for (var i = 0; i < newAcceptedCurrentSectionFields.length; i++) {
				var field = newAcceptedCurrentSectionFields[i];
				if (field.component !== "RichText" && field.name) {
					//get the default value of this field
					var defaultValue = await getDefaultValue({ field, location });

					/*
					if default value is not empty AND (field is empty OR field was overridden (and the overridden value is the current value))
					We update the new value of this field cause the old value is obsolete
						example
						name a can have = [val1,val2]
						field 1 name b condition a=val1
						field 2 name b condition a=val2
						we have to update b if a change 
					*/
					if (
						(
							!newAnswers[field.name]
							||
							newAnswers[field.name] == newOverridden[field.name]
						) && defaultValue !== undefined) {
						//set the overridden value
						newOverridden[field.name] = defaultValue;
						//update the answer
						newAnswers[field.name] = defaultValue;
					}
				}
			}
			//Clear the values of the obsolete fields
			Object.keys(accepted).forEach(nameI => {
				if (accepted[nameI])
					return;
				//this field is not accepted and was overridden, reset it
				if (newOverridden[nameI]) {
					newAnswers[nameI] = "";
				}
			});

			//record the other infos
			setOverriddenEmptyFields(newOverridden);

			//We only set the parent fields (we will loop through them to render, we dont keep here the childs)
			newAcceptedCurrentSectionFields = newAcceptedCurrentSectionFields.filter(field => !field.toDelete);
			
			var updated = false
			if(JSON.stringify(newAcceptedCurrentSectionFields) != JSON.stringify(acceptedCurrentSectionFields)) {
				updated = true
				setAcceptedCurrentSectionFields(newAcceptedCurrentSectionFields);
			}
				
			//set Answers
			if(JSON.stringify(newAnswers) != JSON.stringify(answers)) {
				updated = true
				formikBag.setFieldValue("answers", newAnswers);
			}

			//call onChange to update listeners
			if(updated)
				onChange(formikBag.values);
		})();
	}, [
		currentSection,
		//A bit hardcore, but eh it works
		JSON.stringify(formikBag.values.answers)
	]);

	//UPDATE VALIDATION SCHEMA ON NEW SECTION
	React.useEffect(() => {
		var newValidation = Yup.object({
			answers: Yup.object(acceptedCurrentSectionFields.reduce((acc, cur) => {
				//parent field required
				if (cur.required && cur.name && cur.component !== "RichText" && !cur.isGroup) {
					if (cur.multiple === true) {
						acc[cur.name] = Yup.array()
							.min(1, (cur.label[currentLocale] || cur.label["default"] || cur.name) + " : " + t("form.required"))
							.required((cur.label[currentLocale] || cur.label["default"] || cur.name) + " : " + t("form.required"));
					}
					else
						acc[cur.name] = Yup.mixed().required((cur.label[currentLocale] || cur.label["default"] || cur.name) + " : " + t("form.required"));
				} else if (cur.required && cur.isGroup && cur.fields) {
					//if parent field required, childs required
					for (var i = 0; i < cur.fields.length; i++) {
						var curi = cur.fields[i];
						acc[curi.name] = Yup.mixed().required((curi.label[currentLocale] || curi.label["default"] || curi.name) + " : " + t("form.required"));
					}
				}
				return acc;
			}, {}))
		});
		//done
		setValidationSchema(newValidation);

		//set timeout cause setState validationSchema is not immediate....
		setTimeout(() => formikBag.validateForm(), 1);
	}, [acceptedCurrentSectionFields]);

	//TODO : acceptCondition should be a useRef and we should update acceptCondition.current in the useEffect already depending on JSON.stringify(formikBag.values.answers)
	//const operators = ["=", "|", "&", "!=", ">", "<"];
	const acceptCondition = React.useCallback((condition) => {
		//console.log("condition 1",condition);
		var answers = formikBag.values.answers;

		//1 - Accepted if no condition
		if (condition === "" || condition === null || condition.length == 0) {
			//	console.log("condition", condition, true);
			return true;
		}
		//2 - return value of field
		if (answers[condition] !== undefined) {
			if (Number(answers[condition]))
				return Number(answers[condition]);
			return answers[condition];
		}

		//3 - Condition is an array, have to go recursive
		if (Array.isArray(condition)) {
			//	console.log("condition 3.1",condition);
			//3.1 Weird case, 1 or 2 elements in array
			if (condition.length == 1) return acceptCondition(condition[0])
			if (condition.length == 2) {
				if (condition[1] === "!=") {
					return !acceptCondition(condition[0]);
				}
				if (condition[1] === "=" || condition[1] === "|" || condition[1] === "&") {
					return acceptCondition(condition[0]);
				}
				//> and < make no sense only one element
				return false
			}

			//3.2 normal array, get value for each element
			var operator = condition[condition.length - 1];
			var values = [...condition];
			values.pop();		
			switch (operator) {
				case "&":
					//each element must be accepted
					var ok = values.reduce((acc, cur) => acc && acceptCondition(cur), true);
					break;
				case "|":
					//at least one must be accepted
					var ok = values.reduce((acc, cur) => acc || acceptCondition(cur), false);
					break;
				case "!=":
				case "=":
					var valuesMaped = values.map(acceptCondition);
					var uniques = valuesMaped.filter((value, index, self) => {
						return self.indexOf(value) === index;
					});
					//= means there is only one unique
					if (operator === "=")
						var ok = uniques.length == 1;
					//!= means there is at least 2 uniques
					if (operator === "!=")
						var ok = uniques.length > 1;

					break;
				case ">":
				case "<":
					var valuesMaped = values.map(acceptCondition);
					if (operator == ">")
						var ok = valuesMaped.reduce((acc, cur, i) => {
							if (i)
								return acc && valuesMaped[i] > valuesMaped[i - 1];
							else
								return true;
						}, true);
					if (operator == "<")
						var ok = valuesMaped.reduce((acc, cur, i) => {
							if (i)
								return acc && valuesMaped[i] < valuesMaped[i - 1];
							else
								return true;
						}, true);
					break;
			}
			//console.log("condition", condition, ok);
			return ok;
		}

		//directly the value
		//check if this is a name, if yes the value is not in answers so this is a fail
		if (fields[condition])
			return false;
		return condition
	}, [formikBag.values.form, JSON.stringify(formikBag.values.answers)]);


	//process next section index
	//-------------------------------------
	var nextSection = -1;
	var finish = false;
	var i = formikBag.values.currentSection + 1;
	if (!formikBag.values || !formikBag.values.form) return null;
	//if no errors check next section, otherwise cant continue
	if (Object.keys(formikBag.errors) == 0)
		for (; i < formikBag.values.form.length; i++) {
			var sectioni = formikBag.values.form[i];
			var accept = acceptCondition(sectioni.condition ? JSON.parse(sectioni.condition) : sectioni.condition);
			//console.log("section",sectioni, accept);
			if (accept && !sectioni.checkpoint) {
				nextSection = i;
				break;
			} else if (!accept && sectioni.checkpoint)
				break;
		}
	if (nextSection == -1 && i == formikBag.values.form.length)
		finish = true;

	//Process previous section index
	//-------------------------------------
	var previousSection = -1;
	var i = formikBag.values.currentSection - 1;
	for (; i >= 0; i--) {
		var sectioni = formikBag.values.form[i];
		var accept = acceptCondition(sectioni.condition ? JSON.parse(sectioni.condition) : sectioni.condition);
		//console.log("section", sectioni, accept);
		if (accept && !sectioni.checkpoint) {
			previousSection = i;
			break;
		} else if (!accept && sectioni.checkpoint)
			break;
	}

	//OK RENDER
	//-------------------------------------
	return <Grid item xs sx={{
		display: "flex",
		flexDirection: "column",
		alignItems: "stretch",
		overflow: "hidden"
	}}>
		{notCollector && <Grid container justifyContent={"center"}>
			<Grid item>
				<Button variant={showingSummary ? "contained" : "default"} onClick={() => setShowingSummary(true)}>{t("summary")}</Button>
				<Button variant={!showingSummary ? "contained" : "default"} onClick={() => setShowingSummary(false)}>{t("details")}</Button>
			</Grid>
		</Grid>}
		<Box sx={{
			flex: 1,
			position: "relative"
		}}>
			<Box sx={{
				position: "absolute",
				top: 0, left: 0, right: 0, bottom: 0, overflow: "auto", padding: 1,
				"img": {
					width: "100%"
				}
			}}>
				{showingSummary ?
					<>
						<Box sx={{
							backgroundColor: "background.default",
							padding: 2,
							borderColor: "grey.200",
							borderWidth: 1,
							borderStyle: "solid"
						}}>
							<pre>{formikBag.values.answers && JSON.stringify(formikBag.values.answers, null, 2)}</pre>
						</Box>
					</>
					:
					<>
						{/*--------------------- THE FORM CONTENT -------------------------*/}
						{form[currentSection] && <>
							<Grid container spacing={1}>
								<Grid item xs={12} sx={{
									textAlign: "center"
								}}>
									<Typography variant={"h3"}>{form[currentSection].label[currentLocale]}</Typography>
								</Grid>
								{/*------------- SECTION EXISTS ------------------*/}
								{acceptedCurrentSectionFields.map(({ locked, id, sx = {
									fullWidth: true,
									width: "100%"
								}, isGroup, deleteable, visible, component, label, options, name, ...field }, i) => {
									//Get the component from the component name (field.component = string)
									var Component = Fields[component];
									if (!Component) {
										console.error("missing component type for", component, name);
										return null;
									}
									//Process options depending of currentLocale
									var optionProcessed = {};
									if (options) {
										optionProcessed.options = options.map(option => ({
											...option,
											label: typeof option.label === 'object' ? (option.label[currentLocale] || option.label["default"] || option.value) : (option.label || option.value)
										}));
									}
									//Process label depending of currentLocale
									var labelProcessed = {}
									if (label) {
										labelProcessed.label = typeof label === 'object' ?
											(
												label[currentLocale]
												||
												(
													label["default"]
													||
													name
												)
											)
											:
											(
												label
												||
												name
											);
									}

									// return the field
									return <Grid item xs={12} key={i+"answers." + name} sx={{
										width: "100%",
										display: visible ? undefined : "none !important"
									}}>
										<Component		
											{...{ ...field, export: undefined }}
											{...optionProcessed} {...labelProcessed}
											sx={sx}
											name={"answers." + name}
											disabled={field.disabled || !formikBag.values.editing || !formikBag.values.canEdit}
										/>
									</Grid>;
								})}
							</Grid>
						</>}
					</>}
			</Box>
		</Box>
		{/*--------------------- FORM ACTIONS -------------------------*/}
		<Box sx={{
			padding: 1,
		}}>
			<Grid container>
				<Grid item>
					{(formikBag.values.editing || !currentSection == 0) && <Button
						variant={"outlined"}
						disabled={previousSection == -1}
						onClick={(e) => {
							formikBag.setFieldValue("currentSection", previousSection);
						}}>{t("form.previous")}
					</Button>}
				</Grid>
				<Grid item xs >
					{formikBag.values.canEdit && <Grid container justifyContent={"center"}>
						{formikBag.values.canCancel && <Grid item>
							<Button variant={"outlined"} onClick={() => {
								if (!formikBag.values.editing)
									//save current values
									formikBag.setFieldValue("answers_saved", formikBag.values.answers);
								else if (formikBag.values.answers_saved) {
									//restore current values
									formikBag.setFieldValue("answers", formikBag.values.answers_saved);
								}

								formikBag.setFieldValue("editing", !formikBag.values.editing);
							}}>
								{formikBag.values.editing ? t("general.cancel.cta") : t("form.edit.cta")}
							</Button>
						</Grid>}

					</Grid>}

				</Grid>
				<Grid item>
					{((formikBag.values.canEdit && formikBag.values.editing) || !finish) && <Button
						variant={"outlined"}
						//type={finish ? "submit" : undefined}
						disabled={(!finish || Object.keys(formikBag.errors).length > 0) && nextSection == -1}
						onClick={(e) => {
							formikBag.validateForm().then(ok => {
								if (!formikBag.values.editing || Object.keys(ok).length == 0) {
									if (!finish)
										formikBag.setFieldValue("currentSection", nextSection);
									else
										formikBag.submitForm().then((ok) => {
											//Can cancel now and is no more editing
											formikBag.setFieldValue("editing", false);
											formikBag.setFieldValue("canCancel", true);
										})
								} else {
									console.log(ok);
									formikBag.setTouched(
										acceptedCurrentSectionFields.reduce((acc, cur) => {
											acc.answers[cur.name] = true;
											if (cur.required && cur.isGroup && cur.fields) {
												for (var i = 0; i < cur.fields.length; i++) {
													var curi = cur.fields[i];
													acc.answers[curi.name] = true;
												}
											}
											return acc;
										}, { answers: {} }), true);
								}
							});
						}}>{finish ? t("form.finish") : t("form.next")}</Button>}
				</Grid>
			</Grid>
		</Box >
	</Grid >
})

/**
 *  Component to answer the form
 */
const CollectForm = connect(
	(state, ownProps) => {
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////
			currentLocale: state.server.labels.currentLocale,
			//depending of the user / the fact that it is a on the fly form or a normal one
			canEdit: selector.canEditCollect(state, ownProps ? ownProps.id : null)
		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		...actions
	}, dispatch)
)(function ({
	//what do we do once the form is filled
	onSubmit = (values, { setSubmitting }) => { },
	canEdit,
	//the list of sections
	form = [],
	//what do we do if the user change the form
	onChange,
	//current answers
	answers,
	//section in which we are
	currentSection = 0,
	//style stuff
	xs, md, lg, sx,
	...props }) {
	//initial validation schema = nothing, this validation schema will change each time we change the section or the displayed field
	const [validationSchema, setValidationSchema] = React.useState(Yup.object({}))
	return <Formik
		enableReinitialize={true}
		validateOnChange={true}
		initialValues={{
			currentSection,
			canEdit,
			//currently editing if empty form
			editing: answers ? false : true,
			//can cancel if there is a filled form
			canCancel: answers ? true : false,
			//current answers
			answers: answers || {},
			form
		}}
		//on submit action : 
		onSubmit={(values, formikBag) => {
			//the result of the submit function
			var result = onSubmit(values, formikBag);
			//this was a promise
			if (result && typeof result.then == "function")
				result.then(ok => {
					//when ok, editing = false
					formikBag.setFieldValue("editing", false);
				});
			else {
				//was not a promise, directly editing = false
				formikBag.setFieldValue("editing", false);
			}
		}}
		validationSchema={validationSchema}
	>
		{(formikBag) => {
			return <Grid item xs={xs} md={md} lg={lg} sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "stretch",
				...sx
			}}><Form style={{
				flex: 1,
				flexDirection: "row",
				display: "flex",
				alignItems: "stretch"
			}}>
					<FormContent onChange={onChange} setValidationSchema={setValidationSchema} />
				</Form>
			</Grid>
		}}
	</Formik>
})

CollectForm.propTypes = {
	currentSection: PropTypes.number,
	answers: PropTypes.object,
	form: PropTypes.array,
	onSubmit: PropTypes.func,
	onChange: PropTypes.func,
}

export default CollectForm;