import selector from "../../../selectors";
import Select from "./Select";
import Autocomplete from "./Autocomplete";
import Condition from "./Condition";
import Text from "./Text";
import Date from "./Date";
import SelectLocale from "./SelectLocale";
import SelectComponent from "./SelectComponent";
import Switch from "./Switch";
import RichText from "./RichText";
import GeoLoc from "./GeoLoc";
import SelectWithOther from "./SelectWithOther";
import SelectCollector from "./SelectCollector";
import Number from "./Number";
import MatrixTable from "./MatrixTable";
import Radio from "./Radio";
import actions from "../../../actions/server";
import { matchPath } from "react-router-dom";
const Fields = {
	Autocomplete,
	Condition,
	Date,
	Select,
	GeoLoc,
	SelectWithOther,
	SelectLocale,
	SelectCollector,
	SelectComponent,
	Text,
	Number,
	Switch,
	RichText,
	MatrixTable,
	Radio
}

export const defaultValue = {
	//The selector must return as default value : my name if I am Collector 
	//or the name of the collect’s owner if we are in a collect 
	//or whatever if we are not in a collect (this is the TEST feature probably)
	SelectCollector: (state, location) => new Promise((resolve, reject) => {
		var me = state.server.me;
		var toReturn = undefined;
		if (me) {
			if (me.role !== "COLLECTOR") {
				//NOT COLLECTOR
				var match = matchPath("/collects/:id", location.pathname);
				if (match && match.params.id) {
					//are we in a collect, if yes get the collector id
					actions.getCollect(parseInt(match.params.id))(() => { }, () => state)
						.then(({ data }) => {
							var collectorId = data.idCurrrentUser;
							//get options ordered (first will be the collector id if exists)
							var options = selector.collectorsOptions(state, collectorId);
							resolve(options.length ? options[0].value : undefined);
						})
						.catch(e => resolve(undefined))
				} else {
					resolve(actions.t("collectorName")(() => { }, () => state));
				}

			} else {
				//COLLECTOR
				var options = selector.collectorsOptions(state, me.id);
				resolve(options.length ? options[0].value : undefined);
			}
		} else{
			resolve(undefined);
		}
	
	})
}
export default Fields;