import React from 'react';
import { Link, useNavigate, useParams, useResolvedPath } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../actions/server';
import { Button, Container, Grid, TextField, LinearProgress, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import Fields from '../../../../globals/Fields';
import ErrorMessage from '../../../../globals/ErrorMessage';
import SuccessMessage from '../../../../globals/SuccessMessage';
import * as Yup from 'yup';
import selector from '../../../../../selectors';
export default connect(
  (state, ownProps) => {
    return {
      //STATE TO PROPS
      ///////////////////////////////////////////////////////////////////
      users: selector.users(state),
      companies:state.server.companies,
      roles:state.server.roles
    }
  },
  (dispatch, props) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions
  }, dispatch)
)(function (props) {
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const { editUser, companies,users, roles,t } = props;
  const [user, setUser] = React.useState(null);
  let params = useParams();

  React.useEffect(() => {
    const newUser = users.find((useri) => useri.id == parseInt(params.id));
    setUser(newUser)
  }, [users, params.id])

  if (!user) return null;
  return <Container maxWidth="xs" sx={{paddingTop:5}}>
    <Formik
      initialValues={{
        ...user,
        companyId:user.companyId ||""
      }}
      onSubmit={({companyName,...values}, { setSubmitting }) => {}}
    //validationSchema={{}}
    >
      {(formikBag) => {
        return (
        <Form>
          <Grid container alignItems={"center"}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Fields.Text required fullWidth disabled name={"name"} />
              </Grid>
              <Grid item xs={12}>
                <Fields.Text required disabled fullWidth name={"email"} type={"email"}/>
              </Grid>
              <Grid item xs={12}>
                <Fields.Select required disabled fullWidth name={"role"} options={roles.map((role)=>{{
                  return {
                    value:role,
                    label:t(role+".label")
                  }}
                })}/>
              </Grid>
              <Grid item xs={12}>
                <Fields.SelectLocale disabled required fullWidth name={"locale"}/>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}}
    </Formik>
  </Container>
});
