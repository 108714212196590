import fill from "./fill";
var events = {};
[{
    key:"SERVER",
    events:["SET_GLOBALS",
	//AUTH
	"LOGIN","POSSESSION","LOGOUT","EXORCISE","REGISTER","PASSWORD_FORGOTTEN",
	
	//INFOS
	"GET_LOCALES","EDIT_LOCALES","ME","GET_TRANSLATIONS","EDIT_TRANSLATIONS",
	
	//COMPANIES
	"GET_COMPANIES","CREATE_COMPANY","DELETE_COMPANY","REACTIVATE_COMPANY","GET_COMPANY_STATS",
	
	//USERS
	"GET_USERS","CREATE_USER","EDIT_USER","DELETE_USER","REACTIVATE_USER","RESET_PASSWORD","GET_USERS_OF_COMPANY",
	
	//FORMS
	"CREATE_FORM","GET_ACTIVE_FORM","GET_FORMS","GET_FORM","ACTIVATE_FORM",

	//COLLECT
	"CREATE_COLLECT","DELETE_COLLECT", "UPDATE_COLLECT_ANSWERS","UPDATE_COLLECT_FORM","GET_COLLECT","GET_MY_COLLECTS","GET_ALL_COLLECTS","GET_COLLECTS_OF_COMPANY","GET_COLLECTS","VALIDATE_COLLECT","SEND_COLLECT","REFUSE_COLLECT","DUMP_COLLECTS","COMMENT","GET_COMMENTS_OF_COLLECT","DUMP_COLLECT",

	//UPLOADS
	"ASK_UPLOAD_TOKEN","UPLOAD","DOWNLOAD_FULL","ACCESS_THUMBS","DELETE_UPLOAD","GET_FILES_OF_COLLECT","GET_UPLOAD_STATUS","UPDATE_UPLOAD_VERSION",
	
	"SET_UPLOADING",
	"RESTART_UPLOAD","CANCEL_UPLOAD","UPDATE_UPLOADING_STATUS",

	//MISC
	"DOWNLOAD_LOGS","SET_CURRENT_LOCALE"
],
    children:[]
}
].forEach(elem => fill(events,elem,[]));


const EVENTS = events.SERVER;
export default EVENTS;