import React from 'react';
import { Link, Outlet, useNavigate, useParams, useResolvedPath, useMatch } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../actions/server';
import { Button, Container, Grid, TextField, LinearProgress, Typography, Tabs, Tab } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import LinkTabs from '../../../../globals/LinkTabs';
import Collect from '../../../../globals/Collect';


export default connect(
  (state, ownProps) => {
    return {
      //STATE TO PROPS
      ///////////////////////////////////////////////////////////////////
      collectStatus: state.server.collectStatus
    }
  },
  (dispatch, props) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions
  }, dispatch)
)(function (props) {
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const { collectStatus, deleteCollect, sendCollect,t, restartUpload, cancelUpload, deleteFile, getFilesOfCollect } = props;
  let params = useParams();
  let navigate = useNavigate();
  let id = parseInt(params.id);
  return <>
    <Collect
      back={() => { navigate("/") }}
      id={id}
      actions={[]}
      photosActions={[]}
    />
  </>
});
