import {
	connect
} from 'react-redux'
import {
	bindActionCreators
} from 'redux';

import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid,  LinearProgress } from '@mui/material';
import { Formik, Form } from 'formik';
import ErrorMessage from "../globals/ErrorMessage";
import * as Yup from 'yup';
import actions from '../../actions/server';
import { useLocation } from 'react-router-dom';

const DialogForm = connect(
	(state, ownProps) => {
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////
			dialogForm: state.ui.dialogForm
		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		...actions
	}, dispatch)
)(function (props) {
	const { dialogForm,t} = props;
	const [errors, setErrors] = React.useState(null);

	if (dialogForm)
		var { reject, resolve, text=null, initialValues={}, action, label, validationSchema = {}, fields,...otherProps } = dialogForm;
	const location = useLocation();
	React.useEffect(()=>{
		if(reject) {
			reject({closed:"closed"});
		}
	},[location.pathname])
	return <Dialog {...otherProps} open={dialogForm ? true : false} onClose={() => dialogForm ? dialogForm.reject({ fail: "closed" }) : null}>
		{dialogForm && <Formik
			enableReinitialize={true}
			initialValues={initialValues}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(true);
				if (action)
					return action(values)
						.then(resolve)
						.catch(setErrors);
				else
					resolve(values);
			}}
			validationSchema={Yup.object(validationSchema)}
		>
			{(formikBag) => (
				<Form>
					<DialogTitle>{label}</DialogTitle>
					<DialogContent>
						<Grid container spacing={2}>
							{errors && <Grid item xs={12}><ErrorMessage>{errors}</ErrorMessage></Grid>}
							{text && <Grid item xs={12}>{text}</Grid>}
							{fields && fields.map((fieldi, i) => {
								//console.log("fieldi",fieldi);
								//TODO props depending of values
								return <Grid item xs={12} key={i} {
									...(fieldi.wrapperProps ? {
										...fieldi.wrapperProps,
										sx: typeof fieldi.wrapperProps.sx == "function" ? fieldi.wrapperProps.sx(formikBag.values) : fieldi.wrapperProps.sx
									} : {})
								}>
									<fieldi.component {...{
										...fieldi.props,
										sx: typeof fieldi.props.sx == "function" ? fieldi.props.sx(formikBag.values) : fieldi.props.sx
									}} />
								</Grid>
							})}
							{formikBag.isSubmitting && <Grid item xs={12}><LinearProgress/></Grid>}
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button disabled={formikBag.isSubmitting} variant={"outlined"} onClick={() => reject(null)}>{t("general.cancel")}</Button>
						<Button disabled={formikBag.isSubmitting} type={"submit"} variant={"contained"} color={"primary"}>{t("general.go")}</Button>
					</DialogActions>
				</Form>
			)}
		</Formik>}
	</Dialog>
});

export default DialogForm;