import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../actions/server';
import { Autocomplete, Box, Button, Container, Grid, TextField, LinearProgress, Typography, Chip, ButtonBase } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import DataTable from '../../../globals/DataTable';
import { DateTime } from 'luxon';
import Fields from '../../../globals/Fields';
import * as Yup from 'yup';
import { produce } from 'immer';
import selector from '../../../../selectors';
/**
 * Non utilise c est juste un template
 */
export default connect(
  (state, ownProps) => {
    return {
      //STATE TO PROPS
      ///////////////////////////////////////////////////////////////////
      users: selector.users(state),
      locales: state.server.locales,
      currentLocale: state.server.labels.currentLocale,
      labels: state.server.labels.data,
      loaded:state.server.loaded.labels

    }
  },
  (dispatch, props) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions
  }, dispatch)
)(function (props) {
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const { getTranslations, loaded=true,editTranslations, labels = {}, locales, currentLocale, editLocales, t } = props;
  
  React.useEffect(()=>{
    getTranslations();
  },[])
  const [editingLocales, setEditingLocales] = React.useState(false);
  if (!labels) return null;
  return <Box sx={{ p: 2 }}>
    <Formik
      enableReinitialize={true}
      initialValues={{
        locales
      }}
      onSubmit={(values, { setSubmitting }) => {
        console.log(values);
        setSubmitting(true);
        editLocales({
          locales: values.locales.filter(locale => locale)
        })
          .then((ok) => {
            
          })
          .catch(e => {

          })
          .finally(e => {
            setSubmitting(false);
          });
      }}
    >
      {(formikBag) => (<Form>
        <Grid container spacing={1} sx={{
          padding: 1,
          marginBottom: 1,
          backgroundColor: "background.paper"
        }}>
          <Grid item xs></Grid>
          <Grid item>
            <ButtonBase onClick={() => { setEditingLocales(!editingLocales) }}>
              <Typography variant={"h6"}>{t("editLocales")}</Typography>
            </ButtonBase>
          </Grid>
          {editingLocales && <>
            <Grid item>
              <TextField sx={{
                input: {
                  padding: "5px"
                }
              }} value={formikBag.values.locales.join(" ")} onChange={(e) => {
                var newLocales = e.target.value.split(" ");
                formikBag.setFieldValue("locales", newLocales);
              }} />
            </Grid>
            <Grid item>
              {formikBag.values.locales.map((locale, i) => {
                if (!locale) return null;
                return <Chip key={locale} label={locale} variant="outlined" onDelete={() => {
                  var newLocales = [...formikBag.values.locales];
                  newLocales.splice(i, 1)
                  formikBag.setFieldValue("locales", newLocales);
                }
                } />
              })}
            </Grid>
            <Grid item>
              <Button variant={"outlined"} onClick={() => {
                setEditingLocales(false) ;
                formikBag.resetForm()}
                }>{t("general.cancel")}</Button>
            </Grid>
            <Grid item>
              <Button type={"submit"} variant={"contained"} color={"primary"}>{t("general.go")}</Button>
            </Grid>
          </>}
        </Grid>
      </Form>
      )}
    </Formik>
    <DataTable
      rows={Object.keys(labels).map(name => { return { name, ...labels[name] } })}
      loaded={loaded}
      getRowId={(row) => row.name}
      editAction={({ name, ...values }) => {
        var newLabels = produce(labels, (draft) => {
          draft[name] = values;
        });
        return editTranslations(newLabels)
      }}
      filters={{
        initialValues: {
          missingLocale: "",
          search: ""
        },
        action: (rows, values) => {
          return rows.filter((rowi) => {
            var accept = true;
            //locale translated
            if (values.missingLocale && labels[rowi.name][values.missingLocale])
              accept = false;

            //search
            if(values.search && JSON.stringify(rowi).toLowerCase().indexOf(values.search.toLowerCase())<0) 
              accept=false;
            return accept;
          })
        },
        fields: [{
          component: Fields.Autocomplete,
          props: {
            name: "missingLocale",
            label: t("translations.missingLocale"),
            options: ["", ...locales],
            getOptionLabel: (option) => {
              if (option) {
                return t("locales." + option + ".label")
              } else
                return t("locales.all.label")
            },
            fullWidth: true
          }
        },
        {
          component: Fields.Text,
          props: {
            name: "search",
            fullWidth: true,
            label: t("form.search.label"),
            variant: "outlined"
          }
        }
        ]
      }}

      actions={[
        {
          active: (rows) => rows.length ? true : false,
          action: (rows) => () => {
            var newLabels = produce(labels, (draft) => {
              rows.forEach(({ name }) => delete draft[name]);
            });
            console.log(newLabels);
            return editTranslations(newLabels).then(ok=>{
              getTranslations()
            })
          },
          label: (rows) => t("translations.delete.cta")
        }
      ]}
      columns={[
        {
          id: "localeKey",
          label: t("localeKey"),
          cellDataGetter: (row) => {
            return row.name;
          }
        }, ...locales.map(locale => {
          return {
            id: locale,
            label: t("locales." + locale + ".label"),
            cellDataGetter: (row) => row[locale]
          }
        })
      ]}
      
      newItem={{
        label: t("translations.new.cta"),
        action: ({ name, ...values }) => {
          var newLabels = produce(labels, (draft) => {
            draft[name] = values;
          });
          console.log(name, values, newLabels);
          return editTranslations(newLabels).then(ok=>{
            console.log(ok);
            getTranslations()
          })
        },
        active: true,
        initialValues: {
          "name": "",
          ...locales.reduce((acc, curr) => {
            acc[curr] = "";
            return acc;
          }, {})
        },
        validationSchema: {

        },
        fields: [{
          component: Fields.Text,
          props: {
            autoFocus: true,
            name: "name",
            label: t("users.name"),
            fullWidth: true,
            required: true
          }
        },
        ...locales.map(locale => {
          return {
            component: Fields.Text,
            props: {
              name: locale,
              label: t("locales." + locale + ".label"),
              fullWidth: true,
              //required: true
            }
          }
        })
        ]
      }}
    />

  </Box>
});
