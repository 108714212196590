import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../actions/server';
import UIActions from "../../actions/ui";
import { Popover, MenuItem, Box, Button, Grid, Typography, Divider } from '@mui/material';
import selector from '../../selectors';
import * as Yup from "yup";
import Fields from './Fields';
import {useNavigate } from 'react-router-dom';
/**
 * Non utilise c est juste un template
 */
export default connect(
	(state, ownProps) => {
		//console.log("state",state,"isPossessed",state.server.GLOBALS.token, state.server.GLOBALS.main_token);
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////
			locales: selector.localesOptions(state),
			currentLocale: state.server.labels.currentLocale,
			me: state.server.me,
			isPossessed : state.server.GLOBALS.token != state.server.GLOBALS.main_token && state.server.GLOBALS.main_token
		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		...actions,
		...UIActions
	}, dispatch)
)(function (props) {
	//COMPONENT
	///////////////////////////////////////////////////////////////////
	const {locales, exorcise,isPossessed,dialogForm, resetPassword, currentLocale, t, logout, setCurrentLocale, me } = props;
	const expandButton = React.useRef();
	const [userExpandOpened, setUserExpandOpened] = React.useState(false);
	console.log("isPossessed",isPossessed);
	const navigate = useNavigate();
	return <Grid container sx={{ p: 1 }}>
		<Grid item xs sx={{display:"flex",alignItems:"center"}}>
			<img src="/logo.png" style={{width:"90px",marginRight:10}}/>
		</Grid>
		<Grid item>
			<Button variant={userExpandOpened ? "contained" : "initial"} onClick={() => setUserExpandOpened(!userExpandOpened)} ref={expandButton} color={userExpandOpened ? "primary" : "inherit"}>{me.name}</Button>
			<Popover
				open={userExpandOpened}
				elevation={1}
				anchorEl={expandButton.current}
				onClose={() => { setUserExpandOpened(false) }}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
			>
				<MenuItem onClick={() => logout()}>{t("auth.logout.cta")}</MenuItem>
				{isPossessed && <MenuItem onClick={()=>exorcise().then(()=>navigate("/"))}>{ t("users.actions.exorcise.cta")}</MenuItem>}
				<Divider/>
				<MenuItem onClick={()=>{
					dialogForm({
						label: t("users.actions.changePassword.cta"),
						action: resetPassword,
						initialValues: {
							id:me.id,
							"newPassword": "",
							"passwordCheck": ""
						},
						validationSchema:{
							passwordCheck:Yup.string()
							.required(t("form.required"))
							.oneOf([Yup.ref('newPassword')], t("form.passwordCheck.error")),
						},
						fields: [
							{
							component:Fields.Text,
							props:{
								name:"newPassword",
								label:t("users.password"),
								type:"password",
								fullWidth:true,
								required:true
							}
							},
							{
							component:Fields.Text,
							props:{
								name:"passwordCheck",
								label:t("users.passwordCheck"),
								type:"password",
								fullWidth:true,
								required:true
							}
							}
						]
						})
				}}>{t("users.actions.changePassword.cta")}</MenuItem>
				<Divider/>
				{locales.map(locale => locale.value == "default" ? null : <MenuItem sx={{
					"&, &:hover": {
						backgroundColor: locale.value == currentLocale ? "primary.main" : undefined,
						color: locale.value == currentLocale ? "primary.contrastText" : undefined,
					}
				}} key={locale.value} onClick={() => {
					setCurrentLocale(locale.value);
					setUserExpandOpened(false);
				}}>{t(locale.label)}</MenuItem>)}
			</Popover>
		</Grid>
	</Grid>
})