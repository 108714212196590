import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import actions, { getFileKey } from '../../../actions/server';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import ViewListIcon from '@mui/icons-material/ViewList';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { Grid, Button, Box, useMediaQuery, Typography, IconButton, CircularProgress, Slider, ButtonBase } from '@mui/material';
import { useFormikContext } from 'formik';
import { useDropzone } from 'react-dropzone'
import ErrorMessage from '../ErrorMessage';
import CloseIcon from '@mui/icons-material/Close';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import produce from 'immer';
import DataTable from "../DataTable";
import Fields from '../Fields';
import SplitButton from '../SplitButton';
import UIActions from "../../../actions/ui";
import PreviewIcon from '@mui/icons-material/Preview';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
/**
 */
const Photos = connect(
	(state, ownProps) => {
		var uploadingInfos = {}
		if (state.server.uploadingByCollect[ownProps.id])
			uploadingInfos = state.server.uploadingByCollect[ownProps.id];

		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////
			...uploadingInfos,
			isCollector: state.server.me && state.server.me.role == "COLLECTOR",
			role: state.server.me ? state.server.me.role : undefined
		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		...actions,
		...UIActions
	}, dispatch)
)(function ({ role, id, t, isCollector, canUpload, majorUpdateVersion, askToken, actions, getFilesOfCollect, downloadThumb, downloadFull, accessThumbs, upload, getCollect,
	uploading = [], uploadingController, uploadingThumb, uploadingStatus,
	setUploading, cancelUpload, restartUpload,
	deleteFile, dialogForm,
	...props }) {
	const bigScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));
	const [viewType, setViewType] = React.useState("thumbs");
	const [thumbSize, setThumbSize] = React.useState(role == "ADMIN" || role == "VALIDATOR" ? 300 : 100);
	const [rejectedFiles, setRejectedFiles] = React.useState([]);
	const [loaded, setLoaded] = React.useState(false);
	//the upload files data
	const [uploads, setUploads] = React.useState([]);
	//the thumbs of the upload files
	const [uploadThumb, setUploadThumb] = React.useState([]);

	//selected files for action
	const [selected, setSelected] = React.useState([]);

	//the raw actions to give to the SplitButton of photo actions
	const [processedActions, setProcessedActions] = React.useState([]);

	//update the processed actions from the selection
	React.useEffect(() => {
		var newProcessedActions = actions.map(({ active, action, label }) => {
			return {
				active: active(selected),
				action: action(selected, setUploads),
				label: label(selected)
			};
		})
		setProcessedActions(newProcessedActions);
	}, [selected])

	//These function .current will be up to date when props / state change
	const onDrop = React.useRef();

	//if update the id of the collect, update the photos
	React.useEffect(() => {
		setLoaded(false)
		getFilesOfCollect(id).then(({ data }) => {
			setUploads(data);
			setLoaded(true);
		}).catch((e) => {
			setUploads([]);
			setLoaded(true);
		});
	}, [id]);

	//onDrop add uploading to currently uploading, so the function is updated at each render (ie each uploading change)
	onDrop.current = (acceptedFiles) => {
		if (!canUpload) return;
		var alreadyUploading = [];
		var newUploading = [...uploading];
		for (var i = 0; i < acceptedFiles.length; i++) {
			var newOne = acceptedFiles[i];
			if (uploading.find(file => getFileKey(file) == getFileKey(newOne))) {
				//allready uploading
				alreadyUploading.push(newOne);
			} else {
				//because of bad image mime type detection in windows / chrome, have to do that manually
				if(newOne.type && newOne.type.indexOf("image/")===0) {
					//accepted because image
					newUploading.push(newOne);
				} else {
					var extension = newOne.name.substring(newOne.name.lastIndexOf(".")+1).toLowerCase();
					//Desactivate file type check
					newUploading.push(newOne);
					/*if(extension === "raf" || extension==="raw" ||  extension=="dng" || extension =="arw" || extension ==="cr2") {
						//accepted because of extension
						newUploading.push(newOne);
					}*/
				}
			}
		}
		if (newUploading.length)
			setUploading({ id, uploading: newUploading });
		if (alreadyUploading) {
			console.error("alreadyUploading", alreadyUploading);
		}
	};

	//UPDATE UPLOADS WHEN UPLOADING STATES CHANGE (the uploads are done in batch, each major version is when a batch is finished)
	React.useEffect(() => {
		getFilesOfCollect(id).then(ok => {
			setUploads(ok.data);
		})
	}, [majorUpdateVersion])

	//GET THUMBS FOR UPLOADS
	React.useEffect(() => {
		//Update thumbs
		Promise.all(uploads.map(file => new Promise((resolve, reject) => {
			var fileKey = getFileKey(file);
			if (!uploadThumb[fileKey]) {
				downloadThumb(fileKey).then(response => {
					var reader = new FileReader();
					reader.onloadend = function (e) {
						resolve({
							fileKey,
							thumb: e.target.result
						});
					}
					reader.readAsDataURL(response.data);
				}).catch(e => {
					resolve({
						fileKey,
						thumb: null
					});
				})
			}
			else
				resolve({ fileKey: fileKey, thumb: uploadThumb[fileKey] })
		}))).then(result => {
			var newThumbs = result.reduce((acc, cur) => {
				acc[cur.fileKey] = cur.thumb;
				return acc;
			}, {});
			setUploadThumb(newThumbs);
		});
	}, [uploads]);

	//dropZone files beeing accepted
	const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
		//accept: "image/*,.raf,.raw,.dng,.arw",//'image/jpeg,image/png',
		onDrop: onDrop.current
	})

	//in case file are rejected
	React.useEffect(() => {
		setRejectedFiles(fileRejections);
	}, [fileRejections])

	//big screen : no photos
	if (!bigScreen) return null;
	return <Grid item xs sx={{
		display: "flex",
		flexDirection: "column",
		alignItems: "stretch"
	}}>
		{/*--------------------- PHOTO HEAD -------------------------*/}
		<Grid item sx={{
			background: "background.main",
			padding: 1
		}}>
			<Grid container spacing={1} alignItems={"center"}>
				<Grid item>
					<Grid container alignItems={"center"}>
						<Grid item>
							<Typography variant={"h6"}>
								{t("collect.pictures")}
							</Typography>
						</Grid>
						<Grid item xs></Grid>
						<Grid item>

						</Grid>
					</Grid>
				</Grid>
				<Grid item xs sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
					{viewType == "thumbs" && <Box sx={{
						width: 50,
						paddingTop: "6px"
					}}>
						{/*--------- change size of thumbs --------------*/}
						<Slider
							defaultValue={300}
							getAriaValueText={value => "" + value + "px"}
							valueLabelDisplay="off"

							min={100}
							max={300}
							value={thumbSize}
							onChange={(event, newValue) => setThumbSize(newValue)}
						/>
					</Box>}
				</Grid>

				<Grid item sx={{
					opacity: viewType == "thumbs" ? 1 : 0.4
				}} onClick={() => setViewType("thumbs")}>
					<IconButton>
						<PhotoSizeSelectActualIcon color={viewType == "thumbs" ? "primary" : "default"} />
					</IconButton>
				</Grid>
				<Grid item sx={{
					opacity: viewType == "thumbs" ? 0.4 : 1
				}} onClick={() => setViewType("list")}>
					<IconButton>
						<ViewListIcon color={viewType != "thumbs" ? "primary" : "default"} />
					</IconButton>
				</Grid>
			</Grid>

		</Grid>
		<Grid item xs sx={{
			position: "relative"
		}}>
			<Box
				sx={{
					position: "absolute", display: "flex", alignItems: "flex-start",
					backgroundColor: canUpload && isDragActive ? "grey.200" : undefined,
					top: 0, left: 0, right: 0, bottom: 0, overflow: "auto", padding: 1
				}}
				{...(canUpload ? getRootProps() : {})}
			>
				{canUpload && <input {...getInputProps()} />}
				{
					canUpload && (isDragActive || (uploads.length == 0 && uploading.length == 0)) ?
						<Box sx={{
							margin: "auto", alignSelf: "center", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"
						}}>
							<UploadFileIcon style={{ width: 100, height: 100, opacity: 0.3 }} />
							{t("collect.dropFilesHere")}
						</Box> :
						<>
							<Grid container>
								{rejectedFiles.length > 0 && <Grid item xs={12}>
									<ErrorMessage sx={{ position: "relative" }}><ul>
										{rejectedFiles.map(({ file, errors }) => (
											<li key={file.path}>
												{file.path} - {file.size} bytes
												<ul>
													{errors.map(e => (
														<li key={e.code}>{e.message}</li>
													))}
												</ul>
											</li>
										))}
										<IconButton
											style={{
												position: "absolute",
												top: 0,
												right: 0
											}}
											color={"error"}
											onClick={(e) => {
												e.stopPropagation();
												setRejectedFiles([]);
											}}
										>
											<CloseIcon />
										</IconButton>
									</ul>
									</ErrorMessage>
								</Grid>}
								{/*----------------------- SHOW AS THUMBS ----------------*/}
								{viewType == "thumbs" && <>
									{/*----------------------- UPLOADING FILES ----------------*/}
									{uploading.length > 0 && <Grid item xs={12}><Typography variant={"h7"}>{t("collect.uploading")} ({uploading.length})</Typography></Grid>}
									{uploading.map((file, i) => {
										var fileKey = getFileKey(file);
										var thumb = uploadingThumb[fileKey];
										var status = uploadingStatus[fileKey];
										var selectedIndex = selected.findIndex((selectedi => getFileKey(selectedi) == fileKey));
										var isSelected = selectedIndex > -1;
										//console.log(fileKey,state);
										return (
											<Grid
												item key={"uploading-" + i}
												sx={{
													margin: 1, borderRadius: 1,
													position: 'relative',
													flexDirection: "column",
													borderWidth: 3, borderStyle: "solid",
													borderColor: isSelected ? "primary.main" : (status.status == "FAIL" ? "error.main" : "grey.200"),
													alignSelf: "stretch", display: "flex", alignItems: "stretch"
												}}
												onClick={(e) => {
													e.stopPropagation()
													var newSelected = produce(selected, draft => {
														if (!isSelected)
															draft.push(file);
														else
															draft.splice(selectedIndex, 1);
													});

													setSelected(newSelected);
												}}>
												<Grid container sx={{ flex: 1, padding: 1, justifyContent: "center" }}>
													<Grid item style={{
														display: "flex", alignItems: "center",
														justifyContent: "center"
													}}>
														{thumb ?
															<img src={thumb} style={{
																maxWidth: thumbSize,
																maxHeight: thumbSize
															}} />
															:
															<InsertPhotoIcon sx={{
																width: thumbSize,
																height: thumbSize,
																opacity: 0.2
															}} />
														}
													</Grid>
												</Grid>
												<Grid sx={{
													padding: 1,
													fontSize: Math.max(0.7, thumbSize / 300) + "em",
													textAlign: "center",
													backgroundColor: "background.default"
												}}>
													{file.path}
												</Grid>
												{(status.status == "STARTED" || status.status == "DEFINED") && <CircularProgress
													size={48}
													value={status.bytesUploaded * 100 / status.bytesTotal}
													variant={status.bytesTotal ? "determinate" : "indeterminate"}
													sx={{
														position: "absolute",
														top: 1,
														right: 1
													}}
												/>}
												<IconButton
													sx={{
														position: "absolute",
														top: 5,
														right: 5,
														backgroundColor: "background.paper"
													}}
													onClick={(e) => {
														cancelUpload({ id, file });
													}}
												>
													<CloseIcon />
												</IconButton>
												{status.status == "FAIL" && <IconButton
													sx={{
														position: "absolute",
														top: 5,
														left: 5,
														backgroundColor: "background.paper",
														"hover": {
															background: "red"
														}
													}}
													onClick={(e) => {
														restartUpload({ id, file });
														getFilesOfCollect(id).then(ok => setUploads(ok.data));
													}}
												>
													<RestartAltIcon />
												</IconButton>}

											</Grid>)
									})}




									{/*----------------------- UPLOADED FILES ----------------*/}
									{uploads.length > 0 && <Grid item xs={12}><Typography variant={"h7"}>{t("collect.uploads")}  ({uploads.length})</Typography></Grid>}
									{uploads.map((file, i) => {
										var fileKey = getFileKey(file);
										var thumb = uploadThumb[fileKey];
										var status = file.status;
										var selectedIndex = selected.findIndex((selectedi => getFileKey(selectedi) == fileKey));
										var isSelected = selectedIndex > -1;

										return (
											<Grid
												item key={"upload-" + i}
												sx={{
													margin: 1, borderRadius: 1,
													position: 'relative',
													flexDirection: "column",
													borderWidth: 3, borderStyle: "solid",
													borderColor: isSelected ? "primary.main" : "grey.200",
													alignSelf: "stretch", display: "flex", alignItems: "stretch"
												}}
												onClick={(e) => {
													e.stopPropagation();
													var newSelected = produce(selected, draft => {
														if (!isSelected)
															draft.push(file);
														else
															draft.splice(selectedIndex, 1);
													});
													console.log(newSelected);
													setSelected(newSelected);
												}}>
												<Grid container sx={{ flex: 1, padding: 1, justifyContent: "center" }}>
													<Grid item style={{
														display: "flex", alignItems: "center",
														justifyContent: "center"
													}}>
														{thumb ?
															<img src={thumb} style={{
																maxWidth: thumbSize,
																maxHeight: thumbSize
															}} />
															:
															<InsertPhotoIcon sx={{
																width: thumbSize,
																height: thumbSize,
																opacity: 0.2
															}} />
														}
													</Grid>
												</Grid>
												<Grid sx={{
													padding: 1,
													fontSize: Math.max(0.7, thumbSize / 300) + "em",
													textAlign: "center",
													backgroundColor: "background.default"
												}}>
													{/*----------------------- SHOW FULL SIZE IF NOT COLLECTOR ----------------*/}
								
													{isCollector ?
														file.originalNameFile
														:
														<Button
															startIcon={<PreviewIcon />}
															onClick={(e) => {
																e.stopPropagation();
																downloadFull(fileKey).then(response => {
																	var reader = new FileReader();
																	reader.onloadend = function (e) {
																		console.log(e.target.result);
																		dialogForm({
																			label: <Button startIcon={<FileDownloadIcon/>} onClick={()=>{
																				const link = document.createElement("a");
																				link.href = e.target.result;
																				link.download = file.originalNameFile;
																				link.click();
																				link.remove();
																			}}>{file.originalNameFile}</Button>,
																			text: <Box style={{ display: "flex", justifyContent: "center" }}>
																				<img src={e.target.result} />
																			</Box>,
																			fullWidth: true, maxWidth: "xl"
																		});
																	}
																	reader.readAsDataURL(response.data);
																}).catch(e => {

																})
															}}>{file.originalNameFile}</Button>}
												</Grid>

												{canUpload && <IconButton
													sx={{
														position: "absolute",
														top: 5,
														right: 5,
														backgroundColor: "background.paper"
													}}
													onClick={(e) => {
														dialogForm({
															label: t("users.actions.deletePhoto.title"),
															text: t("users.actions.deletePhoto.text"),
														}).then(ok => deleteFile(file.id).then(ok => getFilesOfCollect(id).then(ok => setUploads(ok.data))));
														//delete
														//cancelUpload({id,file});
													}}
												>
													<CloseIcon />
												</IconButton>}
											</Grid>)
									})}


								</>}
								{/*----------------------- SHOW AS LIST ----------------*/}
								{viewType == "list" && <Box onClick={(e) => e.stopPropagation()}>
									<DataTable
										selected={selected} setSelected={setSelected}
										rows={[...uploads, ...uploading.map(upload => {
											var fileKey = getFileKey(upload);
											return {
												...upload,
												path: upload.path,
												size: upload.size,
												status: uploadingStatus[fileKey] || upload.status
											};
										})]}
										loaded={loaded}
										onClick={(id) => {
											console.log("onClick", id);
										}}
										getRowId={getFileKey}
										filters={{
											initialValues: {
												"status": "",
												"search": ""
											},
											action: (rows, values) => {
												return rows.filter((rowi) => {
													var accept = true;
													//date min
													if (values.status && (rowi.status !== values.status && rowi.status.status !== values.status))
														accept = false;
													
													//search
													if (values.search && JSON.stringify(rowi).indexOf(values.search) < 0)
														accept = false;
													return accept;
												})
											},
											fields: [{
												component: Fields.Autocomplete,
												props: {
													name: "status",
													label: t("upload.status"),
													options: ["", "FAIL", "SUCCESS", "UPLOAD_OK_STORAGE", "DEFINED","STARTED"],
													getOptionLabel: (option) => {
														if (option) {
															return t("upload.status." + option + ".label")
														} else
															return t("upload.status.all.label")
													},
													fullWidth: true
												}
											},
											{
												component: Fields.Text,
												props: {
													name: "search",
													fullWidth: true,
													label: t("form.search.label"),
													variant: "outlined"
												}
											}
											]
										}}
										columns={[
											{
												id: "name",
												label: t("upload.name"),
												cellDataGetter: (row) => {
													return row.name || row.originalNameFile ||row.path;
													//return getFileKey(row)
												}
											},
											{
												id: "size",
												label: t("upload.size"),
												cellDataValue: (row) => row.size,
												cellDataGetter: (row) => {
													var _size = row.size;
													var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
														i = 0; while (_size > 900) { _size /= 1024; i++; }
													var exactSize = (Math.round(_size * 100) / 100) + ' ' + fSExt[i];
													return exactSize;
												}
											},
											{
												id: "status",
												label: t("upload.status"),
												cellDataGetter: (row) => {
													return t(row.status.status || row.status);
												}
											}
										]}

									/>

								</Box>}
							</Grid>



						</>

				}

				{false && Array(1000).fill("photos content").join(" ")}
			</Box>
		</Grid>
		<Grid item sx={{
			padding: 1,
		}}>
			<Grid container>
				<Grid item xs></Grid>
				<Grid item>
					<SplitButton options={processedActions} />
				</Grid>
			</Grid>

		</Grid>
	</Grid>
});

export default Photos;