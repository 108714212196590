import React from 'react';
import {Link,useNavigate } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import actions from "../../../actions/server";
import { Button, Container, Grid, TextField,LinearProgress, Typography } from '@mui/material';
import { Formik, Form, Field} from 'formik';
import ErrorMessage from '../../globals/ErrorMessage';
import * as Yup from 'yup';
/**
 * Non utilise c est juste un template
 */
 export default connect(
  (state, ownProps) =>{return {
    //STATE TO PROPS
    ///////////////////////////////////////////////////////////////////
  }},
  (dispatch, props) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions
  }, dispatch)
)(function(props){
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const {login,t} = props;
  //which error is there in the form
  const [error,setError]= React.useState(null);
  const navigate = useNavigate()
  return <Container style={{height:"100vh",display:"flex",alignItems:"center"}} maxWidth={"xs"}>
	<Formik
       initialValues={{ email: '', password: '' }}
	   onSubmit={(values, { setSubmitting }) => {
		   setSubmitting(true);
		   login(values)
			.then((ok)=>{
				setError(null);
				navigate("/");

			})
			.catch(e=>{
				console.log("error",e);
				setError(e.response ? e.response.data.label:"global.unknown");
			})
			.finally(e=>{
				setSubmitting(false);
			});
	  }}
	  validationSchema={Yup.object().shape({
        email:Yup.string().email(t("form.field.email.invalid"))
      })}
    >
		{(formikBag) => (
         <Form>
			<Grid container alignItems={"center"}>
				<Grid item container spacing={2}>
					{error && <Grid item xs={12}>
						<ErrorMessage>
							{t(error)}
						</ErrorMessage>
					</Grid>}
					<Grid item xs={12}>
						<Field required fullWidth name={"email"} as={TextField} label={t("form.email.label")} variant="outlined" type="email"/>
						<ErrorMessage name="email" />
					</Grid>
					<Grid item xs={12}>
						<Field required fullWidth name={"password"} as={TextField}  label={t("form.password.label")} variant="outlined" type="password"/>
						<ErrorMessage name="password" />
					</Grid>
					{formikBag.isSubmitting && <Grid item xs={12}><LinearProgress /></Grid>}
					<Grid item xs={12}>
						<Button type={"submit"} fullWidth variant={"contained"}>{t("auth.login.go")}</Button>
					</Grid>
					
					<Grid item xs={12}>
						<Typography component={Link} to={"/password_forgotten"}>{t("auth.passwordForgotten.cta")}</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Form>
	   )}
	</Formik>
  </Container>
});
