import React from 'react';
import { Link, Outlet, useNavigate, useParams, useResolvedPath, useMatch } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../actions/server';
import { Button, Box,Container, Grid, TextField, LinearProgress, Typography, Tabs, Tab } from '@mui/material';
import { Formik, Form, Field } from 'formik';


const CustomLink = React.forwardRef((props, ref) => {
	return <a ref={ref} style={{ display: "flex", alignItems: "center", flexWrap: "nowrap", flexDirection: "row", padding: 10, maxWidth: "unset" }} {...props}>{props.children}</a>
})

/**
 * Tab component, get route, isHome (if also match home) getLabel to get label of sub item
 */
export default connect(
	(state, ownProps) => {
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////
		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////

	}, dispatch)
)(function ({ tabs, tabSx = (active, i) => { }, ...props }) {

	let navigate = useNavigate();
	let params = useParams();
	let selectedTabIndex = 0;

	let tabsToRender = tabs.map((routes, tabIndex) => {
		let match = false;

		let label = <>
			{routes.map(({ route, end, getLabel }, i) => {

			//build the to href
			let to = "/" + route;

			//Resolve match (active)
			let resolved = useResolvedPath(to);
			let matchi = useMatch({ path: resolved.pathname, end: end });
			match = match || matchi;

			return <Box key={i}>{getLabel(matchi, params)}</Box>;
		})}</>;
		
		if (match)
			selectedTabIndex = tabIndex;
		return <Tab
			key={tabIndex}
			sx={(tabSx(match, tabIndex))}
			component={CustomLink}
			onClick={e => navigate("/" + routes[0].route)}
			label={label}
			to={"/" + routes[0].route}
		/>
	});

	return <Tabs value={selectedTabIndex} {...props}>
		{tabsToRender}
	</Tabs>
})
