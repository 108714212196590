import React from 'react';
import {Link,Outlet,useNavigate,useParams ,useResolvedPath,useMatch} from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import actions from '../../../../../actions/server';
import { Button, Container, Grid, TextField,LinearProgress, Typography, Tabs,Tab } from '@mui/material';
import { Formik, Form, Field} from 'formik';
import LinkTabs from '../../../../globals/LinkTabs';


export default connect(
  (state, ownProps) =>{return {
    //STATE TO PROPS
    ///////////////////////////////////////////////////////////////////
  }},
  (dispatch, props) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions
  }, dispatch)
)(function(props){
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const {t} = props;
  let params = useParams();
  return <>
    <LinkTabs tabs={[
      [
        {route:"users/"+params.id,end:true,getLabel:(active,params)=>t("information")},
        {route:"users/"+params.id+"/information",end:true,getLabel:(active,params)=>""}
      ],
      [
        {route:"users/"+params.id+"/collects",end:true,getLabel:(active,params)=>t("collects")}
      ]
    ]}/>
    <Outlet/>
  </>
});
