import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {Button,Grid, Select, FormControl, InputLabel, NativeSelect, MenuItem } from '@mui/material';
import { Field, getIn, useFormikContext } from 'formik';
import Text from './Text';

export default connect(

	(state, ownProps) => ({
		//STATE TO PROPS
		///////////////////////////////////////////////////////////////////

	}),
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		
	}, dispatch)
)(function (props) {
	//COMPONENT
	///////////////////////////////////////////////////////////////////
	return <Text type={"number"} {...props}/>

})