import { applyMiddleware, compose, createStore,combineReducers } from 'redux'
import thunk from 'redux-thunk';
import server from './reducers/server';
import ui from "./reducers/ui";

export default function configureStore(preloadedState) {
  const store = createStore(
    combineReducers({
      server,
      ui
       //...
    }),
    preloadedState,
    compose(
      applyMiddleware(
        thunk,
        // ... other middlewares ...
      ),
    ),
  )

  return store
}