import Home from "./Home";
import OneWrapper from "./one";
import OneCollects from "./one/collects";
import OneForm from "./one/form";
import OneInformation from "./one/information";
import OneStatistics from "./one/statistics";
import OneUsers from "./one/users";

export const AdminCompaniesHome = Home;
export const AdminCompaniesOneWrapper = OneWrapper;
export const AdminCompaniesOneCollects = OneCollects;
export const AdminCompaniesOneForm = OneForm;
export const AdminCompaniesOneInformation = OneInformation;
export const AdminCompaniesOneStatistics = OneStatistics;
export const AdminCompaniesOneUsers = OneUsers;
