import EVENTS from "../events/ui";
import { defaultValue } from "../components/globals/Fields";
import { matchPath, useLocation } from "react-router-dom";

const actions = {
    //copy a section data to the clipboard
    copySection: (params) => {
        return (dispatch,getState) => {
            dispatch({
                type: EVENTS.COPY_SECTION.SUCCESS,
                payload:params
            });
        }
    },
    //copy a field data to the clipboard
    copyField: (params) => {
        return (dispatch,getState) => {
            dispatch({
                type: EVENTS.COPY_FIELD.SUCCESS,
                payload:params
            });
        }
    },
    //copy a full form data to the clipboard
    copyForm:(params)=>(dispatch,getState)=>dispatch({
        type:EVENTS.COPY_FORM.SUCCESS,
        payload:params
    }),

    //Get default value of a component in the form, or the given static default value or the dynamic one
    getDefaultValue:({field,location})=>(dispatch,getState)=>{
        //can have 0 as default value
        if(field.defaultValue !== undefined && field.defaultValue !== "")
            return Promise.resolve(field.defaultValue);
        return defaultValue[field.component] ? defaultValue[field.component](getState(),location):Promise.resolve(undefined);
    },
    /**
     *  launch the dialog form  
     * @param  {text=null, initialValues, action, label, validationSchema = {}, fields } params 
     * @returns Promise
     */
    dialogForm:(params)=>(dispatch,getState)=>new Promise((resolve,reject)=>{
        dispatch({
            type:EVENTS.DIALOG_FORM.SUCCESS,
            payload:{
                ...params,
                resolve,reject}
        });
    }).finally(ok=>{
        dispatch({
            type:EVENTS.DIALOG_FORM.SUCCESS,
            payload:null
        });
    })

};

export default actions;
