import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

/**
 * Take as input options [{
 * 	active : boolean
 * 	action : function 
 *  label : element / text
 * }]
 */
const SplitButton = connect(
	(state, ownProps) => {
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////
		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		
	}, dispatch)
)(function ({options=[],...props}) {
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const [loading,setLoading] = React.useState(false) 
	if(options.length==0 || !options.find(option=>option.active))
		return null;
	return (
		<React.Fragment>
			<ButtonGroup {...props} ref={anchorRef}  >
				<Button 
					onClick={()=>options[selectedIndex].action(setLoading)}
					disabled={loading||!options[selectedIndex].active}
					startIcon={loading ? <CircularProgress/>:null}
				>
					
					{options[selectedIndex].label}
				</Button>
				{options.length>1 && 
					<Button
						size="small"
						aria-controls={open ? 'split-button-menu' : undefined}
						aria-expanded={open ? 'true' : undefined}
						aria-label="select merge strategy"
						aria-haspopup="menu"
						disabled={loading}
						sx={{
							width:30
						}}
						onClick={() => {
							setOpen((prevOpen) => !prevOpen);
						}}
					>
						<ArrowDropDownIcon />
					</Button>
				}
			</ButtonGroup>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				sx={{zIndex:1000}}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={(event) => {
								if (anchorRef.current && anchorRef.current.contains(event.target)) {
									return;
								}

								setOpen(false);
							}}>
								<MenuList id="split-button-menu">
									{options.map(({active, action,label}, index) => (
										<MenuItem
											key={index}
											disabled={!active}
											selected={index === selectedIndex}
											onClick={(event) => {
												setSelectedIndex(index);
												action(setLoading);
												setOpen(false);
											}}
										>
											{label}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</React.Fragment>
	);
})

SplitButton.propTypes = {
	options:PropTypes.arrayOf(PropTypes.shape({
		active:PropTypes.bool.isRequired,
		action:PropTypes.func.isRequired,
		label:PropTypes.string.isRequired,
	}))
  }
export default SplitButton;