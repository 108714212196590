import { TextField } from '@mui/material';
import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, getIn, useFormikContext } from 'formik';
import Select from './Select';
import actions from '../../../actions/server';
import ErrorMessage from '../ErrorMessage';
import PropTypes from 'prop-types';
import selector from "../../../selectors";
const SelectComponent = connect(

	(state, ownProps) => ({
		//STATE TO PROPS
		///////////////////////////////////////////////////////////////////
		options: selector.componentsOptions(state)
	}),
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
	}, dispatch)
)(function ({ options, name, isGroupName, sx = { minWidth: 200 }, ...props }) {
	//COMPONENT
	///////////////////////////////////////////////////////////////////
	//Import global styles
	const formikBag = useFormikContext();
	var value = getIn(formikBag.values, name);
	//console.log("select",options);
	return <>
		<Field as={Select} options={options} {...props} sx={sx} value={value} onChange={(e) => {
			formikBag.setFieldValue(name, e.target.value);
			var option = options.find(o => o.value == e.target.value)
			console.log(option);
			if (option && option.isGroup) {
				console.log("component is group", option);
				
				formikBag.setFieldValue(isGroupName, true);
			}
			else
				formikBag.setFieldValue(isGroupName, false);


		}} />
		{name && <ErrorMessage sx={{marginTop:1}} name={name} />}
	</>
});


SelectComponent.prototype = {
	options: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.any,
		label: PropTypes.string
	}))
}
export default SelectComponent;