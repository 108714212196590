
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { BrowserRouter } from "react-router-dom";
import configureStore from './store'
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from './styles/theme';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey(
 atob('NTYwOGE0NGI2YjdmYjg0M2M1Y2I0YTYwNTljZGNiOTVUMUpFUlZJNk5ERTNPRGNzUlZoUVNWSlpQVEUyT0RFME5qUXlNak13TURBc1MwVlpWa1ZTVTBsUFRqMHg=')
);

const container = document.getElementById('root');
const root = createRoot(container);

const store = configureStore()

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
    <CssBaseline />
      
      {/*<React.StrictMode>*/}
        <BrowserRouter>
            <App />
        </BrowserRouter>
      {/*</React.StrictMode>*/}
    </ThemeProvider>
  </Provider>)

serviceWorkerRegistration.register();