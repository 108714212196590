import { TextField ,Grid} from '@mui/material';
import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field } from 'formik';
import ErrorMessage from '../ErrorMessage';
import actions from '../../../actions/server';
export default connect(
	(state, ownProps) => {
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////

		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		t:actions.t
	}, dispatch)
)(function ({name, sx={},t,...props}) {
	//COMPONENT
	///////////////////////////////////////////////////////////////////
	//Import global styles
	//console.log("geoloc",props);
	return <Grid container spacing={1} sx={sx} >
		<Grid item xs>
			<Field as={TextField} type={"number"} {...props} name={name + "[0]"} label={t("latitude")} />
		</Grid>
		<Grid item xs>
			<Field as={TextField} type={"number"} {...props} name={name + "[1]"} label={t("longitude")} />
		</Grid>
		{props.name && <Grid item xs={12}><ErrorMessage sx={{marginTop:1}} name={props.name} /></Grid>}
	</Grid>
});