import { TextField } from '@mui/material';
import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, useFormikContext, getIn } from 'formik';
import Fields from '../../../../../../globals/Fields';

export default connect(
	(state, ownProps) => {
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////

		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////

	}, dispatch)
)(function (props) {
	//COMPONENT
	///////////////////////////////////////////////////////////////////
	const formikBag = useFormikContext();
	
	return <Fields.RichText 
		input={true}
		key={getIn(formikBag.values, props.name).id}
		name={props.name + ".value["+formikBag.values.currentLocale+"]"}
	/>;
});