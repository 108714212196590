import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, useFormikContext, getIn, Formik, Form } from 'formik';
import actions from '../../../actions/server';
import * as Yup from "yup";
import Fields from '.';
import Autocomplete from './Autocomplete';
import produce from 'immer';
import { hasOptions } from '../../routes/admin/companies/one/form/fieldsConf';
const operators = ["=", "|", "&", "!=", ">", "<"];

export default connect(
	(state, ownProps) => {
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////
			currentLocale: state.server.locales.currentLocale,
		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		t: actions.t
	}, dispatch)
)(function ({ availableFields, t, currentLocale, ...props }) {
	//COMPONENT
	///////////////////////////////////////////////////////////////////
	const [opened, setOpened] = React.useState(false);
	const [arrayValue, setArrayValue] = React.useState([]);
	const [error, setError] = React.useState(null);
	const formikBag = useFormikContext();
	var val = getIn(formikBag.values, props.name);
	
	React.useEffect(() => {

		if (val) {
			try {
				setArrayValue(JSON.parse(val));
				setError(null);
			} catch (error) {
				//console.log(error);
				setArrayValue([]);
				setError(t("form.condition.error.invalid"));
			}
		} else {
			setArrayValue([]);
			setError(null);
		}
	}, [val]);
	
	return <>
		<Box sx={{
			position: "relative"
		}}>
			<Field as={TextField} {...props} error={error ? true : false} sx={{
				input: { paddingRight: "120px" }
			}} />
			<Button
				disabled={props.disabled}
				variant={"outlined"}
				onClick={() => setOpened(true)}
				sx={{ position: "absolute", top: 0, right: 0, margin: 1 }}
			>
				{t("form.condition.edit.cta")}
			</Button>
			<Dialog maxWidth={"lg"} open={opened} onClose={() => setOpened(false)}>
				{opened && <Formik
					//enableReinitialize={true}
					initialValues={{ condition: (arrayValue && arrayValue.length >= 2) ? arrayValue : ["", "", ""] }}
					onSubmit={({ condition }, { setSubmitting }) => {
						if (condition) {
							var newVal = JSON.stringify(condition);
							//console.log(newVal,props.name,condition);
							formikBag.setFieldValue(props.name, newVal)
						} else
							formikBag.setFieldValue(props.name, null);
						setOpened(false);
					}}
					validationSchema={Yup.object({
					})}
				>
					{(formikBagSub) => {
						//console.log("render sub",JSON.stringify(formikBagSub.values));
						var renderConditionItem = ({ item, nameParent, valueParent, index, count }) => {
							var name = nameParent + "[" + index + "]";
							var value = getIn(formikBagSub.values, name);

							//possible values of the autocomplete : field names + siblings fields values
							var options = [];

							Object.values(availableFields).forEach((field) => {
								//AJOUTE LA POSSIBILITE DE CHOISIR LE FIELD
								//RichText n a pas d’output possible
								if (field.component != "RichText")
									options.push({
										kind: t("form.condition.kind.field"),
										value: field.name,
										//label can be or not language object or just a string
										label: typeof field.label === 'object' ? (field.label[formikBag.values.currentLocale || field.label["default"] || currentLocale] || field.name) : (field.label || field.name)
									})
								if(field.component==="SelectWithOther") {
									options.push({
										kind:t("form.condition.kind.macro"),
										value:JSON.stringify([field.name,[field.name,...field.options.map(option=>option.value),"!="],"&"]),
										label:(field.label[formikBag.values.currentLocale]||field.name) +" "+t("HAS_OTHER_VALUE")
									});
								}
								//SI UN AUTRE ENFANT A CE FIELD, RAJOUTE LA POSSIBILITE DE CHOISIR UNE DES VALUES
								if (valueParent.find((fieldi) => fieldi == field.name)) {
									if (hasOptions[field.component]) {
										//console.log(field.options);
										options = options.concat((field.options || []).map(option => ({
											...option,
											//label can be or not language object or just a string
											label: typeof option.label === 'object' ? (option.label[formikBag.values.currentLocale] || option.label["default"] || option.value) : option.label || option.value,
											kind: t("form.condition.kind.value")
										})) || []);
									}
								}
							});
							options = options.sort((a, b) => a.kind < b.kind ? -1 : 1);
							//console.log("name",name,options);
							return (
								<Grid item xs={12} key={name}>
									<Grid container spacing={1} sx={{
										flexWrap: "nowrap"
									}}>
										{/*-----------------------PAS LE DERNIER DES ENFANTS --------------------*/}
										{index !== count - 1 ?
											//ITEM = LISTE : render children
											<>
												<Grid item>
													{/*---------------- render me -------------------*/}
													<Field
														as={Autocomplete}
														options={options}
														getOptionLabel={(option) => {
															if (!option) return "";
															return (option && option.value) ? option.label : Array.isArray(option) ? JSON.stringify(option) : option;
														}}
														isOptionEqualToValue={(option, value) => {
															//console.log("compare", option, value);
															return option == value || (option && option.value == value) || (value && option == value.value) || (!option && !value)
														}
														}
														onInputChange={(e, value) => {
															//console.log(name,e,value);
															var newItem = value;
															try {
																var newItemArray = JSON.parse(newItem);
															} catch (error) {
																var newItemArray = null;
															}
															if(e)
																formikBagSub.setFieldValue(name, newItemArray || newItem);
														}}
														sx={{
															width: 220,
														}}
														groupBy={(option) => option.kind}
														onChange={(e, selected) => {
															console.log(e, selected);
															//console.log(selected.value);
															try {
																var newItemArray = JSON.parse(selected.value);
															} catch (error) {
																var newItemArray = null;
															}
															//console.log(name,newItemArray || selected.value);
															formikBagSub.setFieldValue(name, newItemArray || (selected ? selected.value:""));
														}}
														name={name}
													/>
												</Grid>
												{/*---------------- if array render childs -------------------*/}
												<Grid item>
													{
														Array.isArray(item) ?
															//IS ARRAY - AFFICHE ENFANTS
															<Grid container spacing={1}>
																{item.map((subi, j) => renderConditionItem({ item: subi, nameParent: name, valueParent: value, index: j, count: item.length }))}
															</Grid>
															:
															//SINON PROPOSE CREER ENFANTS
															<Button
																onClick={() => {
																	formikBagSub.setFieldValue(name, ["", "", ""]);
																}}
																sx={{
																	width: 220,
																	input: { padding: 0 }
																}}
																variant={"contained"}>{t("form.condition.expand")}
															</Button>
													}
												</Grid>
											</>
											:
											<>
												<Grid item>
													<Grid container spacing={1}>
														{/*-----------------------SI LE DERNIER PEUT AVOIR PLUS D ITEMS CTA  --------------------*/}
														{(
															valueParent[valueParent.length - 1] == "&"
															||
															valueParent[valueParent.length - 1] == "|"
														) && <>
																<Grid item xs={12}>
																	<Button
																		disabled={valueParent.length<=2}
																		sx={{
																			width: 220
																		}}
																		variant={"outlined"} onClick={() => {
																			var itemParent = getIn(formikBagSub.values, nameParent);
																			var newValue = produce(itemParent, (draft) => {
																				draft.splice(draft.length-2,1)
																			});
																			formikBagSub.setFieldValue(nameParent, newValue);
																		}}
																	>
																		{t("form.condition.remove")}
																	</Button>
																</Grid>
																<Grid item xs={12}>
																	<Button
																		sx={{
																			width: 220
																		}}
																		variant={"outlined"} onClick={() => {
																			var itemParent = getIn(formikBagSub.values, nameParent);
																			var newValue = produce(itemParent, (draft) => {
																				draft.splice(draft.length - 1, 0, "")
																			});
																			formikBagSub.setFieldValue(nameParent, newValue);
																		}}
																	>
																		{t("form.condition.add")}
																	</Button>
																</Grid>
															</>}
														<Grid item>
															<Fields.Select
																/*----------------------- DERNIER DES ENFANTS  --------------------*/
																sx={{
																	width: 220,
																	input: { padding: 0 }
																}}
																label={t("form.conditions.selectOperator")}
																options={operators.map((operator) => {
																	return {
																		value: operator,
																		label: t("form.condition." + operator + ".label")
																	}
																})}
																//value={value}
																onChange={(e) => {
																	//console.log(e);
																	if (e.target.value == "<" || e.target.value == ">") {
																		//var parentValue = [...getIn(formikBagSub.values, nameParent).slice(0, 2), e.target.value];
																		var parentValue = produce(getIn(formikBagSub.values, nameParent), (draft) => {
																			while (draft.length > 2) {
																				draft.pop();
																			}
																			draft.push(e.target.value);
																		});
																		//formikBagSub.setFieldValue(nameParent,newValue);
																		formikBagSub.setFieldValue(nameParent, parentValue);
																	} else if (e.target.value == "=" || e.target.value == "!="){
																		var parentValue = produce(getIn(formikBagSub.values, nameParent), (draft) => {
																			while (draft.length < 3) {
																				draft.splice(draft.length-1,0,"");
																			}
																			draft[draft.length-1]=e.target.value;
																		});
																		//formikBagSub.setFieldValue(nameParent,newValue);
																		formikBagSub.setFieldValue(nameParent, parentValue);
																	} else 
																		formikBagSub.setFieldValue(name, e.target.value);

																}}
																//Name necessaire pour s updater meme si ca me plait pas d avoir le onchange et le name
																name={name}
															/>
														</Grid>
													</Grid>
												</Grid>
											</>}
									</Grid>
								</Grid>)
						};
						if (!opened) return null;
						return <Form>
							<DialogTitle>{t("form.condition.edit")}</DialogTitle>
							<DialogContent>
								<Grid container>
									<Grid item xs={12} sx={{
										padding: 1,
										marginBottom: 1,
										borderColor: "grey.400",
										borderWidth: 1,
										borderStyle: "solid",
										borderRadius: 2,
										backgroundColor: "background.default"
									}}>{JSON.stringify(formikBagSub.values.condition)}</Grid>
									<Grid item xs={12}>
										<Grid container spacing={2} sx={{
											overflow: "auto"
										}}>
											{formikBagSub.values.condition.map(((sub, index) => {
												//console.log("render main",sub,index);
												return renderConditionItem({ item: sub, nameParent: "condition", valueParent: formikBagSub.values.condition, index, count: formikBagSub.values.condition.length });
											}))}
										</Grid>
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions>
								<Button variant={"outlined"} onClick={() => setOpened(false)}>{t("general.cancel")}</Button>
								<Button type={"submit"} variant={"contained"} color={"primary"}>{t("general.go")}</Button>
							</DialogActions>
						</Form>
					}}
				</Formik>}
			</Dialog>
		</Box>
	</>
});