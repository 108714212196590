import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Box} from '@mui/material';
import {ErrorMessage, getIn, useFormikContext} from 'formik';
import actions from '../../actions/server';
/**
 * Error with children or formik one with name
 */
export default connect(
	(state, ownProps) =>{return {
		//STATE TO PROPS
		///////////////////////////////////////////////////////////////////
	}},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		...actions
	}, dispatch)
	)(function(props){
	//COMPONENT
	///////////////////////////////////////////////////////////////////
	const {sx={},children} = props;
	const style = {
		...sx,
		bgcolor: 'background.paper',
		borderWidth:2,
		borderStyle:"solid",
		borderColor:"error.main",
		color:"error.main",
		borderRadius: 2,
		padding: 2
	}
	
	if(!props.name && (!children || children==="null")) return null;
	if(props.name) {
		var formikBag = useFormikContext();
		var error = getIn(formikBag.errors,props.name);
		var touched = props.touched || getIn(formikBag.touched,props.name);
		var toDisplay = touched && error ? error:null;
		//console.log(props.name,error,touched,toDisplay);
	} else if(children){
		var toDisplay = children;
	}
	if(!toDisplay) return null;
	return (
		<Box {...props} sx={style}>{toDisplay}</Box>
	)
});
	