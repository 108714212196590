import React from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../actions/server';
import UIActions from "../../../../../actions/ui";
import { Button, Container, Grid, TextField, LinearProgress, Typography, Paper, Chip, Divider, Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { Formik, Form, Field, getIn, setIn } from 'formik';
import DataTable from '../../../../globals/DataTable';
import { DateTime } from 'luxon';
import Fields from '../../../../globals/Fields';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import produce from 'immer';
import { Box } from '@mui/system';
import CollectList from '../../collectList';

export default connect(
  (state, ownProps) => {
    var id = state.server.me && state.server.me.role === "SUPERVISOR" ? state.server.me.companyId : undefined;
    return {
      //STATE TO PROPS
      ///////////////////////////////////////////////////////////////////
      id,
      currentLocal: state.server.labels.currentLocal,
      role:state.server.me ? state.server.me.role:undefined
    }
  },
  (dispatch, props) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions,
    ...UIActions
  }, dispatch)
)(function (props) {
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const { id, role,currentLocal, dialogForm,getCollectsOfCompany, getCompanyStats, t } = props;
  //const [userCollects,setUserCollects] = React.useState([])
  let params = useParams();


  const [possibleGroupBy, setPossibleGroupBy] = React.useState([
    "authors"/*, "camera_height", "camera_tilt", */, "country", "crop", /*"date", "dicot", "gps_tag", "monocot", "perennial",*/ "place", /*"plot", "sky",*/ "status"/*,"soil_color"*/]);
  
  const [groupBy, setGroupBy] = React.useState(possibleGroupBy.map(key => ({ key, active: key == "crop" || key == "place" })));
  const [groupedCollects, setGroupedCollects] = React.useState(null);
  const [collects, setCollects] = React.useState([]);

  React.useEffect(() => {
    if (id || params.id) {
      //getCompanyStats(id || params.id).then(({ data }) => setStats(data));
      getCollectsOfCompany(id || params.id).then(({ data }) => setCollects(data.map(collect => ({
        ...collect, fields: {
          ...collect.fields,
          //le superviseur voit pas la difference entre dumped et validated
          status: role !== "SUPERVISOR" ? collect.status: (collect.status =="DUMPED" ? "VALIDATED":collect.status),
          authors: collect.nameCurrentUser || collect.emailCurrentUser,
          date: collect.fields.date ? function () {
            try {
              var toReturn = DateTime.fromISO(collect.fields.data || collect.timeCreation).setLocale(currentLocal).toISODate();
            }
            catch (e) {
              var toReturn = DateTime.fromISO(collect.fields.data || collect.timeCreation).toISODate();
            }
            return toReturn;
          }() : undefined
        }
      }))));
    }
  }, [params.id, id])

  React.useEffect(() => {
    if (collects) {
      //during exploration, will be used to know if we are a leaf or a node
      var lastGroupIndex = groupBy.length - 1 - [...groupBy].reverse().findIndex(group => group.active);

      //there were nothing found
      if (lastGroupIndex == groupBy.length) lastGroupIndex = -1;

      if (lastGroupIndex == -1) {
        setGroupedCollects({
          values: collects,
          path: []
        })
      } else {
        //for each groupBy  
        var newGroupedCollects = groupBy.reduce((acc, group, groupIndex) => {
          //not active do nothing
          if (!group.active) return acc;

          for (var i = 0; i < collects.length; i++) {
            //for each collect
            var collect = collects[i];
            //the value of this collect for this group
            var collectValue = collect.fields[group.key] ? collect.fields[group.key]
              .replaceAll(",", "|").replaceAll(".", ",").replaceAll("[", "").replaceAll("]", "")
              : "undefined";

            var collectParentPath = acc.path
              //for each path, get the collect value
              .map(key => collect.fields[key] ? collect.fields[key].replaceAll(",", "|").replaceAll(".", ",").replaceAll("[", "").replaceAll("]", "") : "undefined")
              .join(".");


            var currentParentValue = getIn(acc.values, collectParentPath) || {};

            if (groupIndex == lastGroupIndex) {
              //last one in group, add collect to a list
              currentParentValue[collectValue] = currentParentValue[collectValue] ?
                [...currentParentValue[collectValue], collect]
                :
                [collect];
            } else if (!currentParentValue[collectValue])
              //otherwise this is an object ready to get child groups
              currentParentValue[collectValue] = {}

            //set the value in acc or node or leaf
            if (collectParentPath)
              acc.values = setIn(acc.values, collectParentPath, currentParentValue);
            else
              acc.values = currentParentValue;
          }
          //add key to path
          acc.path.push(group.key);

          return acc;
        }, {
          values: {},
          path: []
        });
        setGroupedCollects(newGroupedCollects);
      }

    }
  }, [groupBy, collects]);

  const getRowSpan = (value) => {
    if (Array.isArray(value)) {
      var rowSpan = 1;
    } else {
      var rowSpan = Object.values(value).map(valuei => getRowSpan(valuei)).reduce((acc, cur) => acc + cur, 0);
    }
    return rowSpan;
  }

  const renderNode = ({value, key, rows, columnsCount,row=0,col=0,currPath=[]}) => {
    var rowSpan = getRowSpan(value);
    //console.log(row,col,key,rowSpan,Array.isArray(value));
    if(!rows) {
      var rowsToFill = Array(rowSpan).fill(Array(columnsCount).fill(null).map(i=>null)).map(arr=>[...arr]);
    } else 
      var rowsToFill = rows;
    //console.log("0fill",row,col,t(key, null, key),rowSpan);
    rowsToFill[row][col] = <TableCell key={key+""+row+""+col} sx={{borderWidth:1,borderStyle:"solid",borderColor:"divider"}} rowSpan={rowSpan}>
      <Typography variant={"body1"}>{t(key, null, key)}</Typography>
    </TableCell>

    //if is array : only one cell : count
    if(Array.isArray(value)) {
      //console.log("1fill",row,col+1,value.length,rowSpan);
      rowsToFill[row][col+1]=<TableCell key={key+""+row+""+(col+1)} onClick={()=>{
        dialogForm({
          fullWidth:true,
          maxWidth:"lg",
          label:currPath.map(key=>t(key,null,key)).join("/"),
          text:<CollectList collects={value}/>
        });
      }} sx={{borderWidth:1,borderStyle:"solid",borderColor:"divider",cursor:"pointer",
      ":hover":{backgroundColor:"grey.100"}}}>
        <Typography variant={"body1"}><strong>{value.length}</strong></Typography>
      </TableCell>
    } else {
      var currentDelta=0;
      Object.keys(value).forEach((keyi,i) =>{
        
        renderNode({value:value[keyi], key:keyi, rows:rowsToFill,col:col+1,row:row+currentDelta,columnsCount,currPath:[...currPath,keyi]})
        currentDelta += getRowSpan(value[keyi]);
      })
    }
    
    if(!rows) {
      return rowsToFill.map((row,i)=><TableRow key={key+i}>{row.filter(cell=>cell)}</TableRow>)
    } 
  }

  if (!groupedCollects) return null;
  //console.log(groupedCollects);
  return <Container maxWidth={"lg"}>
    <Paper sx={{ padding: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <DragDropContext
            onDragEnd={(result) => {
              if (!result.destination || result.destination.index === result.source.index) {
                return;
              }

              //REORDER
              const newGroupBy = Array.from(groupBy);
              const [removed] = newGroupBy.splice(result.source.index, 1);
              newGroupBy.splice(result.destination.index, 0, removed);

              setGroupBy(newGroupBy);
            }}>
            <Droppable droppableId="list" direction="horizontal">
              {provided => {
                return <div ref={provided.innerRef} {...provided.droppableProps}>
                  {groupBy.map(({ key, active }, i) => <Draggable key={key} draggableId={key} index={i}>
                    {provided => <>
                      <Chip
                        onClick={(e) => {
                          e.stopPropagation();
                          var newGroupBy = produce(groupBy, draft => {
                            draft[i].active = !draft[i].active;
                          });
                          setGroupBy(newGroupBy);
                        }}
                        ref={provided.innerRef}
                        color={active ? "primary" : "default"}
                        sx={{ marginRight: 1 }}
                        label={<div style={{ display: "flex", alignItems: "center" }}>{t(key)}</div>}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      />

                    </>}</Draggable>
                  )}
                </div>
              }}
            </Droppable>
          </DragDropContext>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"h3"}>{t("nbCollects")}</Typography>
          {groupBy.filter(({ active }) => active).length > 0 && <Typography variant={"h7"}>
            {t("by")} {groupBy.filter(({ active }) => active).map(({ key }) => t(key)).join(" " + t("then") + " " + t("by") + " ")}
          </Typography>}
          <Divider />
        </Grid>

        {groupedCollects.values && <Grid item xs={12} sx={{marginTop:1}}>
          <TableContainer component={Box}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {[...groupedCollects.path, "nbCollects"].map((key, i) => <TableCell sx={{borderWidth:1,borderStyle:"solid",borderColor:"divider",backgroundColor:"grey.100"}} key={i}>{t(key)}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedCollects.path.length > 0 ? 
                  Object.keys(groupedCollects.values).map(key => {
                    return renderNode({value:groupedCollects.values[key], key,columnsCount:groupedCollects.path.length+1,currPath:[key]})
                  })
                  :
                  <TableRow><TableCell><Typography variant={"body1"}><strong>{collects.length}</strong></Typography></TableCell></TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>

        </Grid>}
      </Grid>
    </Paper>
  </Container>
});
