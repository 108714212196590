import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes, { string } from 'prop-types';
import actions from '../../../actions/server';
import ErrorMessage from '../ErrorMessage';
import { Grid, Button, Box, ButtonBase, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CollectPhotos from "./Photos";
import CollectForm from "./Form";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SplitButton from '../SplitButton';
import Comments from './Comments';
import selector from '../../../selectors';
import SuccessMessage from '../SuccessMessage';
/**
 */
const Collect = connect(
	(state, ownProps) => {
		
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////
			collect:state.server.collectDetail[ownProps.id],
			canEdit:selector.canEditCollect(state,ownProps.id)
		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		...actions
	}, dispatch)
)(function ({ collect, canEdit, back = null, id, t, actions, photosActions, getCollect, updateCollectAnswers, ...props }) {
	//The current collect
	//const [collect, setCollect] = React.useState(null);
	const [success,setSuccess] = React.useState(null);
	//Is big screen or not
	const bigScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));

	//Error fetching the collect
	const [error, setError] = React.useState(null);

	//Basically to go back
	const navigate = useNavigate();

	//GET colllect when ID changes
	React.useEffect(() => {
		getCollect(id).catch(e => {
			console.log("error get collect",e.response.data.label);
			setError(e.response.data.label);
		})
	}, [id, getCollect]);
	
	//set error to null when collect exists
	React.useEffect(() => {
		if (collect) {
			setError(null);
		}
	}, [collect])

	//do we have the form content
	if(collect) {
		var formContent = collect.form.content ? JSON.parse(collect.form.content).form : [];
	}
	//console.log(actions);
	return <Grid container sx={{
		flex: 1, overflow: "hidden"
	}}>
		{/*---------------------------- ERROR ---------------------------*/}
		{error && <ErrorMessage sx={{
			alignSelf: "center",
			justifySelf: "center",
			margin: "auto",
		}}>
			<>
				<div>{t(error)}</div>
				<Button fullWidth color={"error"} onClick={() => {
					if (back)
						back();
					else
						navigate(-1);
				}}>
					{t("navigate.back")}
				</Button>
			</>
		</ErrorMessage>}
		{!error && collect && <Box style={{
			flex: 1,
			flexDirection: "column",
			display: "flex",
			alignItems: "stretch"
		}}>
			<Grid container sx={{
				backgroundColor: "background.default"
			}}>
				<Grid item sx={{ p: 1 }}>
					{/*-------- BACK BUTTON ----------*/}
					<Button
						startIcon={<ArrowBackIosIcon />}
						onClick={() => {
							if (back)
								back();
							else
								navigate(-1);
						}}>
						{bigScreen && <>{t("navigate.back")}</>}
					</Button>
				</Grid>
				{/*-------- TITTLE & COMMENTS ----------*/}
				<Grid item xs sx={{
					display: "flex", justifyContent: "center", alignItems: "center",
				}}>
					{bigScreen && <Typography variant={"body1"}>
						{collect.name}
					</Typography>}
					<Comments id={id}/>
				</Grid>
				{/*-------- ACTIONS ----------*/}
				{actions && <Grid item sx={{ p: 1 }}>
					<SplitButton options={actions.map(({ active, action, label }) => ({
						active: active(collect),
						action: action(collect,setSuccess),
						label: label(collect)
					}
					))} />
				</Grid>}
			</Grid>
			<Box sx={{
				backgroundColor: "background.paper",
				flex: 1, display: "flex",
				overflow: "hidden", flexWrap: "wrap"
			}}>

				<Grid container alignItems={"stretch"} sx={{
					flex: 1,
					marginTop: 1,
					overflow: "hidden"
				}}>
					{/*-------- FORM ----------*/}
					<CollectForm 
						id={id}
						currentSection={0}
						answers={collect.answers.content ? JSON.parse(collect.answers.content) : null}
						form={formContent} 
						onSubmit={(values, { setSubmitting }) => {
							console.log(values,formContent);
							var fieldsToExport = {};
							for(var i=0;i<formContent.length;i++){
								var sectioni = formContent[i];
								for(var j=0;j<sectioni.fields.length;j++){
									var field = sectioni.fields[j];
									if(field.export) {
										//this field is to export
										if(field.fields) {
											for(var k=0;k<field.fields.length;k++) {
												fieldsToExport[field.fields[k].name]=true;
											}
										}
										if(field.name) {
											fieldsToExport[field.name]=true;
										}
									}
								}
							}
							return updateCollectAnswers({ 
								id, 
								//real data
								content:JSON.stringify(values.answers),
								//each field value is a string
								fields: Object.keys(values.answers).filter(field=>fieldsToExport[field]).map(field=>{
									var value = values.answers[field];
									
									if(value==null) 
										var stringValue="";
									else if(Array.isArray(value))
										//ARRAY with semicolon as separator
										var stringValue = "["+value.join(";")+"]";//JSON.stringify(value);
									else if(typeof value === 'object') 
										var stringValue = JSON.stringify(value);
									else if(Number(value)==value)
										var stringValue=""+value;
									else if(typeof value=="boolean")
										if(value) 
											var stringValue="True";
										else 
											var stringValue = "False";
									else if(typeof value === "string")
											var stringValue = value
									else
											var stringValue= JSON.stringify(value);
									return {
										field,value:stringValue
									};
								}).reduce((acc,cur)=>{
									acc[cur.field]=cur.value;
									return acc;
								},{})
							}).then(ok=>{
								setSuccess(<>{t("collect.actions.updateCollectAnswers.ok")}</>);
								getCollect(id);
							});
						}}
						xs={12} md={6} lg={4}
					/>
					{/*-------- PHOTOS ----------*/}
					<CollectPhotos id={id} canUpload={canEdit} actions={canEdit ? photosActions:[]}/>
				</Grid>
			</Box>
		</Box>}		
		{success && <SuccessMessage timeout={3000}  reset={()=>setSuccess(null)} notif={true}>
			{success}
		</SuccessMessage>}
	</Grid>
});


Collect.propTypes = {
	back: PropTypes.func,
	id: PropTypes.number.isRequired,
	actions: PropTypes.arrayOf(PropTypes.shape({
		active: PropTypes.func.isRequired,
		action: PropTypes.func.isRequired,
		label: PropTypes.func.isRequired,
	})),
	photosActions: PropTypes.arrayOf(PropTypes.shape({
		active: PropTypes.func.isRequired,
		action: PropTypes.func.isRequired, //takes rows & setUploads as params
		label: PropTypes.func.isRequired,
	}))

}
export default Collect;

