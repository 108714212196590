import React from 'react';
import {Link,useNavigate, useParams } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../actions/server';
import CollectList from '../../admin/collectList';


export default connect(
  (state) => {
    return {
      //STATE TO PROPS
      ///////////////////////////////////////////////////////////////////
      me: state.server.me
    }
  },
  (dispatch) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions
  }, dispatch)
)(function (props) {
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const { getCollectsOfCompany,me } = props;
  const params = useParams();
  const [collects,setCollects] = React.useState([]);
  const [loaded,setLoaded] = React.useState(false);
  React.useEffect(() => {
    if (me.companyId)
      getCollectsOfCompany(me.companyId).then(ok => {
        setCollects(ok.data);
        setLoaded(true);
      });
  }, [me,getCollectsOfCompany])

  return <CollectList loaded={loaded} collects={collects} updateCollects={()=>{
    if (me.companyId)
      getCollectsOfCompany(me.companyId).then(ok => {
        setCollects(ok.data);
        setLoaded(true);
      });
    }}/>
  });