import { TextField, FormLabel, Grid, Box, Button } from '@mui/material';
import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, useFormikContext, getIn } from 'formik';
import actions from '../../../../../../../actions/server';
import { getNewFieldId } from '..';
import produce from 'immer';
import Fields from '../../../../../../globals/Fields';
const MatrixTable = connect(
	(state, ownProps) => {
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////
			locales: state.server.locales,
		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		t: actions.t
	}, dispatch)
)(function ({ t, locales, ...props }) {
	//COMPONENT
	///////////////////////////////////////////////////////////////////
	const formikBag = useFormikContext();
	const [columnCount, setColumnCount] = React.useState(() => {
		var field = getIn(formikBag.values, props.name);
		if (field.fields && field.fields.length) {
			return field.fields[0].options.length;
		} else
			return 0;
	});

	var field = getIn(formikBag.values, props.name);
	const getEmptyOption = React.useCallback(() => {
		return {
			value: "",
			label: locales.reduce((acc, curr) => {
				acc[curr] = "";//t("form.chooseName.label", curr);
				return acc;
			}, {})
		}
	}, [locales]);

	const getEmptyField = React.useCallback(() => {
		return {
			"id": getNewFieldId(),
			"component": "Radio",
			"visible": true,
			"name": "",
			"label": locales.reduce((acc, curr) => {
				acc[curr] = "";//t("form.chooseName.label", curr);
				return acc;
			}, {}),
			"export": field.export,
			"condition": field.condition,
			"deleteable": field.deleteable,
			"props": {
				"required": field.required
			},
			options: Array(columnCount).fill("").map((elem) => getEmptyOption())

		};
	}, [locales, field.condition, field.deleteable, field.required, field.export, columnCount]);

	//update fields if param change
	React.useEffect(() => {
		var newFields = produce(field.fields || [], (draft) => {
			for (var i = 0; i < draft.length; i++) {
				draft[i].condition = field.condition;
				draft[i].deleteable = field.deleteable;
				draft[i].export = field.export;
				draft[i].required = field.required;
				console.log(columnCount, draft[i].options.length);
				if (draft[i].options.length > columnCount) {
					//delete column to match column count
					draft[i].options.splice(columnCount);
				} else if (columnCount > 0)
					// add columns to match column count
					draft[i].options.splice(draft[i].options.length, 0, ...Array(columnCount - draft[i].options.length).fill(getEmptyOption()))
			}
		});
		console.log("updateFields", field.fields, newFields);
		formikBag.setFieldValue(props.name + ".fields", newFields);
	}, [field.condition, field.deleteable, field.required, field.export, columnCount]);

	React.useEffect(() => {
		if (columnCount == 0) {
			setColumnCount(1);
		} else if (!field.fields || field.fields.length == 0) {
			var newFields = produce(field.Fields || [], draft => {
				formikBag.setFieldValue(props.name + ".fields", [getEmptyField()]);
			});
		}
	}, [(field.fields || []).length, columnCount])
	//Import global styles
	return <>
		<FormLabel>{t("matrix.explanation.label")}</FormLabel>
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Fields.RichText
					input={true}
					key={field.id}
					name={props.name + ".explanation[" + formikBag.values.currentLocale + "]"}
				/>
			</Grid>

			<Grid item xs sx={{ position: "relative", minHeight: 270 }}>
				<Box sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, overflow: "auto", paddingTop: 1 }}>
					<Grid container spacing={1} sx={{ overflow: "visible" }}>
						{field.fields && field.fields.length > 0 && field.fields[0].options && field.fields[0].options.length > 0 && <Grid item xs={12} >
							<Grid container spacing={1} sx={{ flexWrap: "nowrap" }}>
								<Grid item><div style={{ width: 220 }}></div></Grid>
								{field.fields[0].options.map((optioni, j) => {

									return <React.Fragment key={field.id + ".option" + j}>
										<Grid item sx={{ width: 230 }}>
											<Grid container spacing={1}>
												<Grid item xs={12}><Fields.Text sx={{ width: 220 }} value={optioni.value} onChange={(e) => {
													var newFields = produce(field.fields || [], (draft) => {
														for (var i = 0; i < draft.length; i++) {
															var isDefault = draft[i].options[j].value === draft[i].defaultValue;
															draft[i].options[j].value = e.target.value;
															if (isDefault) draft[i].defaultValue = e.target.value;
														}
													});
													formikBag.setFieldValue(props.name + ".fields", newFields);
												}} label={t("form.field.value.label")} /></Grid>
												<Grid item xs={12}><Fields.Text sx={{ width: 220 }} value={optioni.label[formikBag.values.currentLocale]} onChange={(e) => {
													var newFields = produce(field.fields || [], (draft) => {
														for (var i = 0; i < draft.length; i++) {
															draft[i].options[j].label[formikBag.values.currentLocale] = e.target.value;
														}
													});
													formikBag.setFieldValue(props.name + ".fields", newFields);
												}} label={t("form.field.label." + formikBag.values.currentLocale + ".label")} /></Grid>
											</Grid>
										</Grid>
									</React.Fragment>
								})}
							</Grid>
						</Grid>}

						{(field.fields || []).map((fieldi, i) => {
							var defaultColumn = fieldi.options.findIndex((option, index) => option.value == fieldi.defaultValue);
							return <Grid item xs={12} key={fieldi.id}>
								<Grid container spacing={1} sx={{ flexWrap: "nowrap" }}>
									<Grid item sx={{ width: 230 }}>
										<Grid container spacing={1}>
											<Grid item xs={12}><Fields.Text sx={{ width: 220 }} name={props.name + ".fields[" + i + "].name"} label={t("form.field.name.label")} /></Grid>
											<Grid item xs={12}><Fields.Text sx={{ width: 220 }} name={props.name + ".fields[" + i + "].label[" + formikBag.values.currentLocale + "]"} label={t("form.field.label." + formikBag.values.currentLocale + ".label")} /></Grid>
										</Grid>
									</Grid>
									{fieldi.options.map((optioni, j) => <React.Fragment key={fieldi.id + ".option" + j}>
										<Grid item sx={{ display: "flex", alignItems: "stretch", flexShrink: 0, flexBasis: 230 }}>
											<Button sx={{ flex: 1 }} fullWidth variant={j == defaultColumn ? "contained" : "outlined"} onClick={() => {
												console.log(props.name + ".fields[" + i + "].defaultValue",defaultColumn);
												if(j == defaultColumn)
													formikBag.setFieldValue(props.name + ".fields[" + i + "].defaultValue",undefined);
												else
													formikBag.setFieldValue(props.name + ".fields[" + i + "].defaultValue", optioni.value)}
												}>
												{
													t("form.field.isDefault")
												}</Button>
										</Grid>
									</React.Fragment>)}
								</Grid>
							</Grid>
						})}

					</Grid>
				</Box>
			</Grid>
			<Grid item>
				<Box sx={{ paddingBottom: 1 }}>
					<Button variant={"outlined"} onClick={() => {
						console.log(columnCount);
						setColumnCount(columnCount - 1);
					}}>{t("matrix.removeColumn.cta")}</Button>
				</Box>
				<div>
					<Button variant={"outlined"} onClick={() => {
						setColumnCount(columnCount + 1);

					}}>{t("matrix.addColumn.cta")}</Button>
				</div>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={1}>
					<Grid item>
						<Button variant={"outlined"} onClick={() => {
							var newFields = produce(field.fields || [], (draft) => {
								console.log(field.fields);
								draft.pop()
							});
							formikBag.setFieldValue(props.name + ".fields", newFields);
						}}>{t("matrix.removeRow.cta")}</Button>
					</Grid>
					<Grid item>
						<Button variant={"outlined"} onClick={() => {
							var newFields = produce(field.fields || [], (draft) => {
								//new exept options 
								if(draft.length) {
									var toAdd = {
										...getEmptyField(),
										options:[...draft[0].options]
									}
								}
								draft.push(toAdd);
							});
							console.log(newFields);
							formikBag.setFieldValue(props.name + ".fields", newFields);
						}}>{t("matrix.addRow.cta")}</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	</>
});
MatrixTable.isGroup = true;
export default MatrixTable;