import { Grid, Box, ButtonBase, TextField } from '@mui/material';
import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, getIn, useFormikContext } from 'formik';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import ErrorMessage from '../ErrorMessage';
import actions from '../../../actions/server';
import CancelIcon from '@mui/icons-material/Cancel';
import { DateTime } from "luxon"
export default connect(
	(state, ownProps) => {
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////
			currentLocale: state.server.labels.currentLocale,
		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		t: actions.t
	}, dispatch)
)(function ({ t, currentLocale, sx = {}, ...props }) {
	//COMPONENT
	///////////////////////////////////////////////////////////////////
	const formikBag = useFormikContext();

	let value = getIn(formikBag.values,props.name);
	//console.log("value date",props,value);
	if(value && value.toISO){
		//value is a date
		value = value.toISO();
	} 
	else if(!Array.isArray(value)){
		//set same day as default (defaultValue=true) or if we are the "date" field the same day is set if no value specified 
		if ((typeof value === "boolean" && value==true) || (props.name=="answers.date" && !value)){
			formikBag.setFieldValue(props.name, DateTime.now().toISO());
		} 
	}

	return <>
		<LocalizationProvider dateAdapter={AdapterLuxon} locale={currentLocale}>
			{Array.isArray(value) ?
				<Grid container sx={sx}>
					<Grid item xs>
						<Field as={DateRangePicker}
							startText={props.label[0]}
							endText={props.label[1]}
							showToolbar={true}
							renderInput={(startProps, endProps) => (
								<React.Fragment>
									<TextField fullWidth {...{ ...props, ...startProps }} />
									<Box sx={{ mx: 2 }}> {t("to")} </Box>
									<TextField {...{ ...props, ...endProps }} />
								</React.Fragment>
							)}
							{...props}
							sx={{
								width: "100%",
							}}
							value={value || null}
							onChange={(val) => {
								//val is the variable which contain the selected date
								//You can set it anywhere
								var value = [
									val[0] ? (val[0].toISO ? val[0].toISO():val[0]) : null,
									val[1] ? (val[1].toISO ? val[1].toISO():val[1]) : null,
								]
								formikBag.setFieldValue(props.name, value);
							}}
						/>
					</Grid>
					{(value[0] !== null || value[1] !== null) && <Grid item sx={{ display: "flex", alignItems: "center", paddingLeft: 1 }}>
						<ButtonBase onClick={() => formikBag.setFieldValue(props.name, [null, null])}>
							<CancelIcon />
						</ButtonBase>
					</Grid>
					}
				</Grid> :
				<Field sx={{ ...sx, width: "100%" }} as={DatePicker}
					renderInput={(subProps) => <TextField fullWidth {...{ ...props, ...subProps }} />}
					{...props}
					value={value || null}
					onChange={(val) => {
						//val is the variable which contain the selected date
						//You can set it anywhere
						formikBag.setFieldValue(props.name, val &&val.toISO ? val.toISO():val);
					}}
				/>}
		</LocalizationProvider>
		{props.name && <ErrorMessage sx={{marginTop:1}} name={props.name} />}
	</>
});

