import React from 'react';
import { Link, useNavigate, useParams, useResolvedPath } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../actions/server';
import { Button, Container, Grid, TextField, LinearProgress, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import Fields from '../../../../globals/Fields';
import ErrorMessage from '../../../../globals/ErrorMessage';
import SuccessMessage from '../../../../globals/SuccessMessage';
import * as Yup from 'yup';
import selector from '../../../../../selectors';
export default connect(
  (state, ownProps) => {
    return {
      //STATE TO PROPS
      ///////////////////////////////////////////////////////////////////
      users: selector.users(state),
      companies:state.server.companies,
      roles:state.server.roles
    }
  },
  (dispatch, props) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions
  }, dispatch)
)(function (props) {
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const { editUser, companies,users, roles,t } = props;
  const [user, setUser] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [success,setSuccess] = React.useState(null);
  let params = useParams();

  React.useEffect(() => {
    const newUser = users.find((useri) => useri.id == parseInt(params.id));
    setUser(newUser)
  }, [users, params.id])

  if (!user) return null;
  return <Container maxWidth="xs" sx={{paddingTop:5}}>
    <Formik
      initialValues={{
        ...user,
        companyId:user.companyId ||""
      }}
      validationSchema={Yup.object().shape({
        companyId:Yup.number()
          .test('not admin', t("form.required"), function(value) {
            console.log(value,this.parent,!value && this.parent.role!=="ADMIN");
              if(!value && this.parent.role!=="ADMIN") return false;
              return true;
          }),
          email:Yup.string().email(t("form.field.email.invalid"))
      })}
      onSubmit={({companyName,...values}, { setSubmitting }) => {
        setSubmitting(true);
        console.log(values);
  
        editUser(values)
          .then((ok) => {
            console.log("ok edit");
            setSuccess(t("form.information.submitSuccess"));
            setError(null);
            
          })
          .catch(e => {
            console.log("error", e);
            setSuccess(null);
            setError(e.response ? e.response.data.label : "global.unknown");
          })
          .finally(e => {
            setSubmitting(false);
          });
      }}
    //validationSchema={{}}
    >
      {(formikBag) => {
        console.log("success",success);
        return (
        <Form>
          <Grid container alignItems={"center"}>
            <Grid item container spacing={2}>
              {success && <Grid item xs={12}>
                <SuccessMessage timeout={3000} reset={()=>setSuccess(null)}>
                  {success}
                </SuccessMessage>
              </Grid>}
              {error && <Grid item xs={12}>
                <ErrorMessage>
                  {t(error)}
                </ErrorMessage>
              </Grid>}
              <Grid item xs={12}>
                <Fields.Text required fullWidth name={"name"} label={t("name")}/>
              </Grid>
              <Grid item xs={12}>
                <Fields.Text required fullWidth name={"email"} type={"email"}  label={t("email")}/>
              </Grid>
              <Grid item xs={12}>
                <Fields.Select required fullWidth name={"role"}  label={t("role")} options={roles.map((role)=>{{
                  return {
                    value:role,
                    label:t(role+".label")
                  }}
                })}/>
              </Grid>
              {formikBag.values.role !== "ADMIN" && formikBag.values.role !== "VALIDATOR" && <Grid item xs={12}>
                <Fields.Select disabled={true} required fullWidth name={"companyId"} label={t("company")} options={companies.map(company=>{return {value:company.id,label:company.name}})}/>
              </Grid>}
              <Grid item xs={12}>
                <Fields.SelectLocale required fullWidth name={"locale"} label={t("locale")}/>
              </Grid>
              

              {formikBag.isSubmitting && <Grid item xs={12}><LinearProgress /></Grid>}
              <Grid item xs={12}>
                <Button type={"submit"} fullWidth variant={"contained"}>{t("general.go")}</Button>
              </Grid>

            </Grid>
          </Grid>
        </Form>
      )}}
    </Formik>
  </Container>
});
