import React from 'react';
import {Link,useNavigate } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import actions from '../../../actions/server';
import UIActions from "../../../actions/ui";
import {Autocomplete, Box, Button, Container, Grid, TextField,LinearProgress, Typography, Chip } from '@mui/material';
import { Formik, Form, Field} from 'formik';
import DataTable from '../../globals/DataTable';
import { DateTime } from 'luxon';
import Fields from '../../globals/Fields';
import * as Yup from 'yup';
import selector from '../../../selectors';
import PropTypes from 'prop-types';


/**
 * Non utilise c est juste un template
 */
 const UsersList = connect(
  (state, ownProps) =>{return {
    //STATE TO PROPS
    ///////////////////////////////////////////////////////////////////
    locales:state.server.locales,
    currentLocale:state.server.labels.currentLocale,
    companies:state.server.companies,
    roles:state.server.roles,
	  canAct:state.server.me && (state.server.me.role==="ADMIN"|| state.server.me.role==="VALIDATOR")
  }},
  (dispatch, props) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions,
    dialogForm:UIActions.dialogForm
  }, dispatch)
)(function(props){
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const {createUser,users=[],loaded=true,canAct, deleteUsers,reactivateUsers,updateUsers, resetPassword,dialogForm,roles,currentLocale,companies=[],t} = props;
  return <Box sx={{p:2}}>
  	
    <DataTable
      rows={users}
      loaded={loaded}
      route={(id)=>"/users/"+id}
      filters={{
        initialValues:{
         "role":"",
         "company":"",
         "search":""
        },
        action:(rows,values)=>{
          return rows.filter((rowi)=>{
            var accept=true;
            //role
            if(values.role && rowi.role && rowi.role!==values.role)
              accept=false;
              
            //Company
            if(values.company && ""+rowi.companyId != ""+values.company.id)
              accept=false;
              
            //search
            if(values.search && JSON.stringify(rowi).toLowerCase().indexOf(values.search.toLowerCase())<0) 
              accept=false;

            return accept;
          })
          
        },
        fields:[{
            component:Fields.Autocomplete,
            props:{
              name:"role",
              label:t("user.role"),
              options:["",...roles],
              getOptionLabel: (option) => {
                if(option) {
                  return t(option+".label")
                } else 
                  return t("roles.all.label")
              },
              fullWidth:true
            }
          },
          {
            component:Fields.Autocomplete,
            props:{
              name:"company",
              label:t("user.companyId"),
              options:["",...companies],
              getOptionLabel: (option) => {
                if(option) {
                  return option.name
                } else 
                  return t("companies.all.label")
              }
            }
          },
          {
            component:Fields.Text,
            props:{
              name:"search",
              fullWidth:true,
              label:t("form.search.label"),
              variant:"outlined"
            }
          }
        ]
      }}
      getRowId={user=>user.id}
      actions={canAct ? [
        {
          active:(rows)=>rows.filter(row=>row.activated).length>0 ? true:false,
          action:(rows)=>()=>{
            dialogForm({
              label: t("users.actions.deleteUser.cta"),
              action: deleteUsers,
              text:<>
                {t("users.actions.deleteUser.text")}
              <div>
                {rows.filter(row=>row.activated).map(row=>row.name).join(",")}
              </div>
              </>,
              initialValues: {
                ids:rows.filter(row=>row.activated).map(row=>row.id)
              }
            })
          },
          label:(rows)=>t("users.actions.deleteUser.cta")
        },
        {
          active:(rows)=>rows.filter(row=>!row.activated).length>0 ? true:false,
          action:(rows)=>()=>{
            dialogForm({
              label: t("users.actions.restoreUser.cta"),
              action: reactivateUsers,
              text:<>
                {t("users.actions.restoreUser.text")}
              <div>
                {rows.filter(row=>!row.activated).map(row=>row.name).join(",")}
              </div>
              </>,
              initialValues: {
                ids:rows.filter(row=>!row.activated).map(row=>row.id)
              }
            })
          },
          label:(rows)=>t("users.actions.restoreUser.cta")
        },
        {
          active:(rows)=>rows.length==1?true:false,
          action:(rows)=>()=>{
            dialogForm({
              label: t("users.actions.resetPassword.cta"),
              action: resetPassword,
              initialValues: {
                id:rows[0].id,
                "newPassword": "",
                "passwordCheck": ""
              },
              validationSchema:{
                newPassword:Yup.string().min(6,t("form.password.minimum")),
                passwordCheck:Yup.string()
                  .required(t("form.required"))
                  .oneOf([Yup.ref('newPassword')], t("form.passwordCheck.error")),
              },
              fields: [
                {
                  component:Fields.Text,
                  props:{
                    name:"newPassword",
                    label:t("users.password"),
                    type:"password",
                    fullWidth:true,
                    required:true
                  }
                },
                {
                  component:Fields.Text,
                  props:{
                    name:"passwordCheck",
                    label:t("users.passwordCheck"),
                    type:"password",
                    fullWidth:true,
                    required:true
                  }
                }
              ]
            })
          },
          label:(rows)=>t("users.actions.resetPassword.cta")
        }
      ] : []}
      columns = {[
      {
        id:"name",
        label:t("users.name"),
        cellDataGetter:(row)=>{
          return row.name;}
      },
      {
        id:"companyName",
        label:t("users.companyName"),
        cellDataGetter:(row)=>row.companyName
      },
      {
        id:"role",
        label:t("users.role"),
        cellDataGetter:(row)=>row.role
      },
      {
        id:"activated",
        label:t("user.activated"),
        cellDataValue:(row)=>row.activated,
        cellDataGetter:(row)=><Chip variant={'outlined'} color={row.activated ? "default":"error"} label={row.activated ? t("user.activated"):t("user.notActivated")}/>
      } 
    ]}
    //TODO
    newItem={canAct ? {
      label:t("users.new.cta"),
      action:(values)=>createUser(values).then(ok=>updateUsers()),
      active:true,
      initialValues:{
        "name":"",
        "email":"",
        "companyId":"",
        "password":"",
        "passwordCheck":"",
        "role":"COLLECTOR",
        "locale":currentLocale
      },
      validationSchema:{
        email:Yup.string().email(t("form.field.email.invalid")),
        password:Yup.string().min(6,t("form.password.minimum")),
        passwordCheck:Yup.string()
          .required(t("form.required"))
          .oneOf([Yup.ref('password')], t("form.passwordCheck.error")),
        companyId:Yup.number()
          .test('not admin', t("form.required"), function(value) {
              if(!value && this.parent.role!=="ADMIN" && this.parent.role!=="VALIDATOR") return false;
              return true;
          })
      },
      fields:[{
        component:Fields.Text,
        props:{
          name:"name",
          label:t("users.name"),
          autoFocus:true,
          fullWidth:true,
          required:true
        }
      },
      {
        component:Fields.Text,
        props:{
          name:"email",
          label:t("users.email"),
          fullWidth:true,
          required:true,
          type:"email"
        }
      },
      {
        component:Fields.Text,
        props:{
          name:"password",
          label:t("users.password"),
          type:"password",
          fullWidth:true,
          required:true
        }
      },
      {
        component:Fields.Text,
        props:{
          name:"passwordCheck",
          label:t("users.passwordCheck"),
          type:"password",
          fullWidth:true,
          required:true
        }
      },
      
      {
        component:Fields.Select,
        props:{
          name:"role",
          label:t("users.role"),
          fullWidth:true,
          required:true,
          options:roles.map((role)=>{{
            return {
              value:role,
              label:t(role+".label")
            }}
          })
        }
      },
      {
        component:Fields.Select,
        props:{
          name:"companyId",
          label:t("users.company"),
          fullWidth:true,
          options:companies.map((company)=>{{
            return {
              value:company.id,
              label:company.name
            }}
          })
        },
        wrapperProps:{
          sx:(values)=>{
            return {
              display:values.role=="ADMIN" || values.role=="VALIDATOR"?"none":"block"
            };
          },
        }
      },
      {
        component:Fields.SelectLocale,
        props:{
          name:"locale",
          label:t("users.locale"),
          fullWidth:true,
          required:true
        }
      }
    ]
    }:undefined}
    />
	</Box>  
});

UsersList.propTypes = {
  //is the list loaded
  loaded: PropTypes.bool,
  //list of users to display
  users: PropTypes.array,
  //update the users
  updateUsers: PropTypes.func
}
export default UsersList;