import fill from "./fill";
var events = {};
[{
    key:"UI",
    events:["COPY_SECTION","COPY_FIELD","COPY_FORM","DIALOG_FORM"],
    children:[]
}
].forEach(elem => fill(events,elem,[]));


const EVENTS = events.UI;
export default EVENTS;