import React from 'react';
import { useNavigate, useResolvedPath, useMatch, useLocation, useParams } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from "../../../actions/server";
import { AppBar, Box, Tabs, Tab, Popover, Button, Toolbar, MenuItem, Typography, Divider } from '@mui/material';
import LinkTabs from '../../globals/LinkTabs';
import selector from "../../../selectors/";
import { LocalSee } from '@mui/icons-material';
/**
 * Non utilise c est juste un template
 */
export default connect(
	(state, ownProps) => {
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////
			me: state.server.me,
			collects:state.server.collects,
			users:selector.users(state),
			locales: selector.localesOptions(state),
			currentLocale: state.server.labels.currentLocale
		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		...actions
	}, dispatch)
)(function (props) {
	//COMPONENT
	///////////////////////////////////////////////////////////////////
	const { me, logout, t, collects, users, getUsersOfCompany, currentLocale, setCurrentLocale, getCollectsOfCompany, locales } = props;

	//popup on the right 
	const expandButton = React.useRef();
	const [userExpandOpened, setUserExpandOpened] = React.useState(false);

	React.useEffect(() => {
		if (me.companyId) {
			getCollectsOfCompany(me.companyId)
			getUsersOfCompany(me.companyId);
		}
	}, [me,getCollectsOfCompany,getUsersOfCompany])


	if (!me) return null;
	return (
		<AppBar position="sticky" color={"transparent"} elevation={0} sx={{
			bgcolor: "grey.A100"
		}}>
			<Toolbar disableGutters variant="dense">
				<LinkTabs tabs={[
					[
						{ route: "", end: true, getLabel: (active, params) => t("statistics.cta") },
						{ route: "statistics", end: true, getLabel: (active, params) => "" }
					],
					[
						{ route: "collects", end: true, getLabel: (active, params) =>  t("collects.cta") },
						{
							route: "collects/:id", end: false, getLabel: (active, params) => {
								if (!active || !params) return "";
								var collect = collects.find(itemi => itemi.id == parseInt(params.id));

								return collect ? (" > " + collect.name) : "";
							}
						}
					],
					[
						{ route: "users", end: true, getLabel: (active, params) => t("users.cta") },
						{
							route: "users/:id", end: false, getLabel: (active, params) => {
								if (!active || !params) return "";
								var user = users.find(itemi => itemi.id == parseInt(params.id));

								return user ? (" > " + user.name) : "";
							}
						}
					]
				]}
					tabSx={(active, i) => {
						return {
							bgcolor: active ? "primary.main" : "transparent",
							color: active ? "white !important" : "inherit",
							opacity: 1,
							transition: "all ease 0.5s 0.2s"
						}
					}}
					variant="scrollable"
					scrollButtons="auto"
					aria-label="scrollable auto tabs example"
					sx={{ flexGrow: 1 }}
				/>
				<>
					<Button variant={userExpandOpened ? "contained" : "initial"} onClick={() => setUserExpandOpened(!userExpandOpened)} ref={expandButton} color={userExpandOpened ? "primary" : "inherit"}>{me.name}</Button>
					<Popover
						open={userExpandOpened}
						elevation={1}
						anchorEl={expandButton.current}
						onClose={() => { setUserExpandOpened(false) }}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
					>
						<MenuItem onClick={() => logout()}>{t("auth.logout.cta")}</MenuItem>
						<Divider />
						{locales.map(locale => locale.value == "default" ? null : <MenuItem sx={{
							"&, &:hover": {
								backgroundColor: locale.value == currentLocale ? "primary.main" : undefined,
								color: locale.value == currentLocale ? "primary.contrastText" : undefined,
							},
						}} key={locale.value} onClick={() => {
							setCurrentLocale(locale.value);
							setUserExpandOpened(false);
						}}>{t(locale.label)}</MenuItem>)}
					</Popover>
				</>
			</Toolbar>
		</AppBar>
	)
});
