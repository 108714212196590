import React from 'react';
import { Link, Outlet, useNavigate, useParams, useResolvedPath, useMatch } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../actions/server';
import UIActions from '../../../../../actions/ui';
import { Button, Container, Grid, TextField, LinearProgress, Typography, Tabs, Tab } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import LinkTabs from '../../../../globals/LinkTabs';
import Collect from '../../../../globals/Collect';


export default connect(
  (state, ownProps) => {
    return {
      //STATE TO PROPS
      ///////////////////////////////////////////////////////////////////
      collectStatus: state.server.collectStatus,
      role: state.server.me ? state.server.me.role : undefined
    }
  },
  (dispatch, props) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions,
    ...UIActions

  }, dispatch)
)(function (props) {
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const { t, role, dialogForm, collectStatus, backToSendCollect, sendCollect, deleteCollect, restartUpload, validateCollect, refuseCollect, dumpCollect, downloadCollects, cancelUpload, deleteFile, getFilesOfCollect } = props;
  let params = useParams();
  let navigate = useNavigate();
  let id = parseInt(params.id);
  return <>
    <Collect
      back={() => { navigate("/") }}
      id={id}
      actions={
        [
          /*{
            active: (values) => values.canSend && values.status == collectStatus.STARTED,
            action: (values) => () => {
              //if (values.status == collectStatus.STARTED)
              sendCollect(values.id);
            },
            label: (values) => t("collect.actions.send.cta")
          },*/
          {
            active: (values) => values.status === "SENT",
            action: (values,setSuccess) => () => {
              return validateCollect(values.id).then(ok=>setSuccess(<>{t("collect.actions.validate.ok")}</>));
            },
            label: (values) => t("collects.actions.validate.cta")
          },
          {
            active: (values) => values.status === "SENT",
            action: (values,setSuccess) => () => {
              return refuseCollect(values.id).then(ok=>setSuccess(<>{t("collect.actions.refuse.ok")}</>));;
            },
            label: (values) => t("collects.actions.refuse.cta")
          },
          {
            active: (values) => values.status === "VALIDATED",
            action: (values,setSuccess) => () => {
              return downloadCollects([values.id]).then(ok=>setSuccess(<>{t("collect.actions.download.ok")}</>));;
            },
            label: (values) => t("collects.actions.download.cta")
          },
          {
            active: (values) => values.status === "VALIDATED",
            action: (values,setSuccess) => () => {
              return dumpCollect(values.id).then(ok=>setSuccess(<>{t("collect.actions.dump.ok")}</>));;
            },
            label: (values) => t("collects.actions.dump.cta")
          },
          ...(role == "ADMIN" ? [
            {
              active: (values) => values.status === "VALIDATED" || values.status === "REFUSED",
              action: (values,setSuccess) => () => {
                return backToSendCollect(values.id).then(ok=>setSuccess(<>{t("collect.actions.backToSendCollect.ok")}</>));;
              },
              label: (values) => t("collects.actions.backToSendCollect.cta")
            },

          ] : []),
          {
            active: (values) => values.status == collectStatus.STARTED,
            action: (values,setSuccess) => () => {
              //if (values.status == collectStatus.STARTED)
              return deleteCollect(values.id).then(ok => navigate("/"));
            },
            label: (values) => t("collect.actions.delete.cta")
          }
        ]}

      photosActions={[
        {
          active: (rows) => {
            return rows.filter(row => row.status !== "UPLOAD_OK_STORAGE").length ? true : false;
          },
          action: (rows, setUploads) => () => { restartUpload({ id, files: rows.filter(row => row.status !== "UPLOAD_OK_STORAGE") }) },
          label: (rows) => t("collects.actions.restart.cta")
        },
        {
          active: (rows) => rows.length ? true : false,
          action: (rows, setUploads) => () => {
            dialogForm({
              label: t("users.actions.deletePhoto.title"),
              text: t("users.actions.deletePhoto.text"),
            }).then(ok => {
              var uploading = [];
              var uploads = [];
              for (var i = 0; i < rows.length; i++) {
                if (rows[i].status === "UPLOAD_OK_STORAGE")
                  uploads.push(rows[i]);
                else
                  uploading.push(rows[i]);
              }
              if (uploading.length > 0)
                cancelUpload({ id, files: uploading });

              Promise.allSettled(uploads.map(upload => deleteFile(upload.id))).then(ok => {
                getFilesOfCollect(id).then(({ data }) => {
                  setUploads(data);
                });
              });
            })
          },
          label: (rows) => t("collects.actions.cancel.cta")
        }
      ]}
    />
  </>
});
