import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import actions from '../../../actions/server';
import { Formik, Form } from 'formik';
import produce from 'immer';
import * as Yup from 'yup';

import { ButtonBase, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button, Box, useMediaQuery, Typography, IconButton } from '@mui/material';
import { useFormikContext } from 'formik';
import Fields from '../Fields';
import ErrorMessage from '../ErrorMessage';
import RichText from '../Fields/RichText';
import CloseIcon from '@mui/icons-material/Close';
import { DateTime } from 'luxon';

/**
 * This component display the small button of comments in the collect and also manage the popup to see comments and send new ones
 */
const Comments = connect(
	(state, ownProps) => {
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////
			currentLocale: state.server.labels.currentLocale,
			role: state.server.me ? state.server.me.role : "",
			currentLocale: state.server.labels.currentLocale
		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		...actions
	}, dispatch)
)(function ({ id, role, currentLocale, t, getCommentsOfCollect, comment, ...props }) {
	//The comments of the collect
	const [comments, setComments] = React.useState([]);
	//is the comment modal opened ?
	const [opened, setOpened] = React.useState(false);
	//are there any errors
	const [commentErrors, setCommentErrors] = React.useState(null);
	//the ref of the container for the scroll
	const container = React.useRef();

	//constructor : get comments 
	React.useEffect(() => {
		getCommentsOfCollect(id).then(ok => {
			setComments(ok.data);
		});
	}, [id]);

	//in case new comments/new opened : scroll down the comment list
	React.useEffect(() => {
		//Simplest way to scroll
		setTimeout(() => {
			if (container.current)
				container.current.scrollTop = container.current.scrollHeight
		}, [1])
	}, [comments, opened, container.current]);

	return <>
	{/**---------------------- THE BUTTON --------------------------- */}
		<ButtonBase sx={{
			textDecoration: "underline",
			fontSize: "0.8em", marginLeft: 2, padding: 1
		}}
			onClick={() => setOpened(true)}
		>
			{comments.length == 1 ? t("collect.1Comment") : (
				comments.length > 1 ?
					comments.length + " " + t("collect.nthComments")
					:
					t("collect.noComment")
			)}
		</ButtonBase>

		<Dialog
			PaperProps={{
				sx: {
					minWidth: "50vw"
				}
			}}

			open={opened}
			onClose={() => setOpened(false)}>
			{opened && <Box style={{ position: "relative" }}>
				<DialogTitle>{t("collect.comments")}</DialogTitle>
				<DialogContent>
					<Grid container ref={container} spacing={2} style={{
						overflow: "auto",
						maxHeight: "70vh",
					}}>
						<Grid item xs={12}>
							<ErrorMessage>{commentErrors}</ErrorMessage>
						</Grid>
						{/**---------------------- THE LIST --------------------------- */}
						{comments.map((comment, i) => {
							var toMe = (
								(comment.direction == "FROM_COMPANY_TO_EXR" && role != "COLLECTOR")
								||
								(comment.direction == "FROM_EXR_TO_COMPANY" && role == "COLLECTOR")
							);
							return <React.Fragment key={i}>
								{!toMe && <Grid item xs={4}></Grid>}
								<Grid item xs={8}>
									<Box>
										<Typography variant={"body1"}>
											<strong>
												{DateTime.fromISO(comment.timeComment).setLocale(currentLocale).toISODate()}
											</strong>
											<> - </>
											{!toMe && <>{comment.senderEmail}</>}
											{toMe && (
												role !== "COLLECTOR" ?
													t("collector.label")
													:
													t("validator.label")
											)
											}
										</Typography>
									</Box>
									<Box
										sx={{
											backgroundColor: "grey.200",
											borderRadius: 3,
											borderBottomLeftRadius: toMe ? 0 : 20,
											borderBottomRightRadius: toMe ? 20 : 0,
											img: { width: "100%" },
											paddingBottom: 20,
											marginBottom: 3,
											padding: 2
										}}
									>
										<Fields.RichText asText={true} key={i} value={comment.message} />
									</Box>
								</Grid>
								{toMe && <Grid item xs={4}></Grid>}
							</React.Fragment>
						})}
					</Grid>
					{/**---------------------- THE FORM --------------------------- */}
					<Formik
						enableReinitialize={true}
						validateOnBlur={false}
						initialValues={{
							idCollect: id,
							message: ""
						}}
						onSubmit={(values, { setSubmitting,setFieldValue,setTouched }) => {
							console.log(values);
							setSubmitting(true);
							return comment(values)
								.then((ok) => {
									getCommentsOfCollect(id).then(ok => {
										setComments(ok.data);
										setFieldValue("message","");
										setTouched({message:false});
										
									});
									setCommentErrors(null);
								})
								.catch(e => {
									console.log("error", e);
									setCommentErrors(e.response ? e.response.data.label : "global.unknown");
								})
								.finally(e => {
									setSubmitting(false);
								});
						}}
						validationSchema={Yup.object({
							idCollect: Yup.number().required(t("form.field.error.required")),
							message: Yup.string().required(t("form.field.error.required"))
						})}
					>
						{(formikBag) => {
							console.log(formikBag);
							return <Form >
								<Grid container spacing={1}>
									<Grid i tem xs={12} md={true}>
										{/*----------- RICH TEXT FOR EXR ONLY ----- */}
										{role !== "COLLECTOR" ?
											<Fields.RichText asText={true} tools={["image"]} fullWidth input={true} name={"message"}  />
											:
											<Fields.Text autoComplete={"off"} fullWidth name={"message"} onBlur={()=>{
												formikBag.setTouched({message:false});
											}}/>
										}
									</Grid>
									<Grid item alignItems={"flex-end"} style={{
										display: "flex", alignItems: "flex-end"
									}}>
										<Button
											fullWidth
											disabled={!formikBag.values.message}
											type={"submit"}
											variant={"contained"} color={"primary"}>{t("general.send")}
										</Button>
									</Grid>
								</Grid>
							</Form>
						}}
					</Formik>


				</DialogContent>
				{/**---------------------- CLOSE BUTTON --------------------------- */}
				<IconButton
					style={{
						position: "absolute",
						top: 0,
						right: 0
					}}
					onClick={() => {
						setOpened(false)
					}}
				>
					<CloseIcon />
				</IconButton>
			</Box>}
		</Dialog>
	</>
})
export default Comments;