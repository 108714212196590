import { TextField } from '@mui/material';
import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, getIn, useFormikContext } from 'formik';
import ErrorMessage from '../ErrorMessage';
import { Quill } from 'quill';
import DOMPurify from 'dompurify'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
function isEmpty(html) {
	if(html || (html.indexOf && html.indexOf("<img"))) {
		return false;
	}
    return new DOMParser()
        .parseFromString(html, "text/html")
        .documentElement.textContent ? true : false;
}

export default connect(
	(state, ownProps) => {
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////
			currentLocale: state.server.labels.currentLocale
		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////

	}, dispatch)
)(function ({ value, ...props }) {
	const {asText=false,tools=["link","image"], currentLocale, input = false } = props;
	//COMPONENT
	///////////////////////////////////////////////////////////////////
	//Import global styles
	const formikBag = useFormikContext();
	
	if(!input) {
		if(value && value[currentLocale] && !isEmpty(value[currentLocale]))
			var text = value[currentLocale];
		else if(value && value["default"] && !isEmpty(value["default"]))
			var text = value["default"];
		else
			var text = value;
		
		if(asText==true) {
			try{
				text = JSON.parse(text)
			} catch(e){}
		}
	} else {
		var text = getIn(formikBag.values, props.name) || ""
		if(typeof text == "string" && asText==true) {
			try{
				text = JSON.parse(text)
			} catch(e){}
		}
	}
	
	return <>
		{input ?[""].map(()=>{
				
				
				return <ReactQuill
				{...props}		
				value={text}
				onChange={(content, delta, source, editor) => {
					formikBag.setFieldValue(props.name, asText ? JSON.stringify(editor.getContents()) : editor.getContents())
				}}
				modules={{
					toolbar: [
						[{ 'header': [1, 2, false] }],
						['bold', 'italic', 'underline', 'strike', 'blockquote'],
						[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
						tools
					],
				}}
				formats={[
					'header',
					'bold', 'italic', 'underline', 'strike', 'blockquote',
					'list', 'bullet', 'indent',
					'link', 'image'
				]} />
			})
			:
			<>
				<div dangerouslySetInnerHTML={{ __html: "<style>.ql-toolbar{display:none} .ql-toolbar.ql-snow + .ql-container.ql-snow {border-width: 0px !important;} .ql-editor, .ql-container {min-height:0}</style>"}}></div>
				<ReactQuill
					{...props}
					value={text || ""}
					readOnly={true}/>
			</>
		}	
	</>;
});