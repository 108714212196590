import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../actions/server';
//TODO a faire
export default  connect(
	(state, ownProps) => {
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////
		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		t:actions.t
	}, dispatch)
)(function (props) {
	return null;
	const {t}=props;
	const [open,setOpen] = React.useState(false);
	React.useEffect(() => {
	  window.addEventListener('beforeunload', alertUser)
	  
	  return () => {
		window.removeEventListener('beforeunload', alertUser)
	  }
	}, [])
	const alertUser = e => {
		console.log(e);
	  	e.preventDefault()
	  	e.returnValue = ''
	}
	
	return (
		<Dialog
			open={open}
			onClose={()=>setOpen(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
        <DialogTitle id="alert-dialog-title">
		{t("general.leavePage.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("general.leavePage.text")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setOpen(true)} autoFocus>{t("general.leavePage.cancel")}</Button>
          <Button onClick={()=>setOpen(false)} >
		  {t("general.leavePage.ok")}
          </Button>
        </DialogActions>
      </Dialog>
	)
  })