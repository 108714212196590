import EVENT from "../events";
import produce from "immer";

const ui = produce((draft, action) => {
	//console.log(action);
        switch (action.type) {
			//GLOBALES
            case EVENT.UI.COPY_SECTION.SUCCESS:
                draft.clipboard.section = action.payload;
                break;
			case EVENT.UI.COPY_FIELD.SUCCESS:
				draft.clipboard.field = action.payload;
				break;
			case EVENT.UI.COPY_FORM.SUCCESS:
				draft.clipboard.form = action.payload;
				break;
			case EVENT.UI.DIALOG_FORM.SUCCESS:
				draft.dialogForm=action.payload;
				break;
			default:
				break;
        }
    },
    //INITIAL STATE
    {
       clipboard:{
		   field:null,
		   section:null,
		   form:null
	   },
	   dialogForm:null
    }
);
export default ui