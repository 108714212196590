import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../actions/server';
import { Autocomplete, Box, Button, Container, Grid, TextField, LinearProgress, Typography, Chip } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import DataTable from '../../globals/DataTable';
import { DateTime } from 'luxon';
import Fields from '../../globals/Fields';
import selector from '../../../selectors';
import PropTypes from 'prop-types';

/**
 * Non utilise c est juste un template
 */
const CollectList = connect(
  (state, ownProps) => {
    return {
      //STATE TO PROPS
      ///////////////////////////////////////////////////////////////////
      locales: state.server.locales,
      currentLocale: state.server.labels.currentLocale,
      collectsStatusOptions: selector.collectsStatusOptions(state),
      canAct: state.server.me && (state.server.me.role === "ADMIN" || state.server.me.role === "VALIDATOR"),
      role: state.server.me ? state.server.me.role : undefined
    }
  },
  (dispatch, props) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions
  }, dispatch)
)(function (props) {
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const {loaded=true, role, backToSendCollect, collectsStatusOptions, canAct, validateCollect, refuseCollect, dumpCollect, downloadCollects, updateCollects, collects = [], locales, currentLocale, t } = props;
  
  return <Box sx={{ p: 2 }}>
    <DataTable
      rows={collects}
      loaded={loaded}
      route={(id) => "/collects/" + id}
      filters={{
        initialValues: {
          "date": [null, null],
          "status": "",
          "search": ""
        },
        action: (rows, values) => {
          var searchCollects = {}
          try {
            if (values.search) {
              var searchCollects = JSON.parse(values.search).reduce((acc, cur) => {
                acc[cur] = true;
                return acc;
              }, {});
            }
          } catch (e) {

          }
          return rows.filter((rowi) => {
            var accept = true;
            var rowDate = rowi.fields.date || rowi.timeCreation;
            var rowDate = rowDate ? DateTime.fromISO(rowDate) : null;

            //date min
            if (values.date[0] && rowDate && rowDate < DateTime.fromISO(values.date[0]))
              accept = false;
            //date max
            if (values.date[1] && rowDate && rowDate > DateTime.fromISO(values.date[1]))
              accept = false;
            //status
            if (values.status && rowi.status !== values.status)
              return false;

            //search
            if (!(searchCollects[rowi.name] && rowi.status !== "STARTED") && values.search && JSON.stringify(rowi).toLowerCase().indexOf(values.search.toLowerCase()) < 0)
              accept = false;
            return accept;
          })
        },
        fields: [
          {
            component: Fields.Select,
            wrapperProps: {
              width: 200
            },
            props: {
              name: "status",
              label: t("status"),
              fullWidth: true,
              options: collectsStatusOptions
            }
          },
          {
            component: Fields.Date,
            props: {
              name: "date",
              label: [t("date.from"), t("date.to")],
              fullWidth: true,
            }
          },
          {
            component: Fields.Text,
            props: {
              name: "search",
              fullWidth: true,
              label: t("form.search.label"),
              variant: "outlined"
            }
          }
        ]
      }}
      actions={canAct ? [
        {
          active: (rows) => rows.filter(row => row.status === "SENT").length ? true : false,
          action: (rows) => () => {
            Promise.allSettled(rows.filter(row => row.status === "SENT").map(row => validateCollect(row.id))).then((ok) => {
              //once everything done, update list
              updateCollects();
            });
          },
          label: (rows) => t("collects.actions.validate.cta")
        },
        {
          active: (rows) => rows.filter(row => row.status === "SENT").length ? true : false,
          action: (rows) => () => {
            Promise.allSettled(rows.filter(row => row.status === "SENT").map(row => refuseCollect(row.id))).then((ok) => {
              //once everything done, update list
              updateCollects();
            });
          },
          label: (rows) => t("collects.actions.refuse.cta")
        },
        {
          active: (rows) => rows.filter(row => row.status === "VALIDATED").length ? true : false,
          action: (rows) => () => {
            downloadCollects(rows.filter(row => row.status === "VALIDATED").map(row => row.id));
          },
          label: (rows) => t("collects.actions.download.cta")
        },
        {
          active: (rows) => rows.filter(row => row.status === "VALIDATED").length ? true : false,
          action: (rows) => () => {
            Promise.allSettled(rows.filter(row => row.status === "VALIDATED").map(row => dumpCollect(row.id))).then((ok) => {
              //once everything done, update list
              updateCollects();
            });
          },
          label: (rows) => t("collects.actions.dump.cta")
        },
        ...(role == "ADMIN" ? [
          {
            active: (rows) => rows.filter(row => row.status === "VALIDATED" || row.status === "REFUSED").length ? true : false,
            action: (rows) => () => {
              Promise.allSettled(rows.filter(row => row.status === "VALIDATED" || row.status === "REFUSED").map(row => backToSendCollect(row.id))).then((ok) => {
                //once everything done, update list
                updateCollects();
              });
            },
            label: (values) => t("collects.actions.backToSendCollect.cta")
          }
        ] : []),
        {
          active: (rows) => rows.length ? true : false,
          action: (rows) => () => {
            const blob = new Blob([JSON.stringify(rows.map(row => row.name))], {
              type: 'text/json'
            });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
          },
          label: (rows) => t("collects.actions.getList.cta")
        }
      ] : []}
      columns={[
        {
          id: "date",
          label: t("collects.date"),
          cellDataValue: row => DateTime.fromISO(row.fields.date || row.timeCreation).toUnixInteger(),
          cellDataGetter: (row) => DateTime.fromISO(row.fields.date || row.timeCreation).setLocale("fr").toISODate()
        }, {
          id: "name",
          label: t("collects.name"),
          cellDataGetter: (row) => row.name
        },
        {
          id: "uploadCount",
          label: t("collects.uploadCount"),
          cellDataGetter: (row) => row.uploadCount
        },
        {
          id: "status",
          label: t("collects.status"),
          cellDataValue: row => row.status,
          cellDataGetter: (row) => {
            return <Grid container spacing={1} alignItems={"center"}>
              {row.deleted ? 
                <Grid item><Chip label={t("collects.status.DELETED")} color={"error"} variant={"contained"} /></Grid>
              :
              <>
                {row.status == "STARTED" && <Grid item><Chip label={t("collects.status.STARTED")} color={"default"} variant={"outlined"} /></Grid>}

                {row.status == "STARTED" && <Grid item><Chip label={row.formValid ? t("collects.formValid") : t("collects.formNotValid")} color={"default"} variant={row.formValid ? "contained" : "outlined"} /></Grid>}

                {row.status == "SENT" && <Grid item><Chip label={t("collects.status.SENT")} color={"default"} variant={"contained"} /></Grid>}
                {row.status == "VALIDATED" && <Grid item><Chip label={t("collects.status.VALIDATED")} color={"success"} variant={"outlined"} /></Grid>}
                {row.status == "REFUSED" && <Grid item><Chip label={t("collects.status.REFUSED")} color={"error"} variant={"outlined"} /></Grid>}
                {row.status == "DUMPED" && <Grid item><Chip label={t("collects.status.DUMPED")} color={"success"} variant={"contained"} /></Grid>}
              </>}
              
            </Grid>

          }
        }
      ]}
    />
  </Box>
});


CollectList.propTypes = {
  //is the list loaded
	loaded: PropTypes.bool,
  //list of collects to display
	collects: PropTypes.array,
  //update the collects
	updateCollects: PropTypes.func
}
export default CollectList;