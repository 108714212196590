import { TextField } from '@mui/material';
import React from 'react'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Field } from 'formik';
import ErrorMessage from '../ErrorMessage';
export default connect(
	 (state, ownProps) =>{return {
	   //STATE TO PROPS
	   ///////////////////////////////////////////////////////////////////
   
	 }},
	 (dispatch, props) => bindActionCreators({
	   //ACTIONS
	   ///////////////////////////////////////////////////////////////////
	 
	 }, dispatch)
   )(function(props){
	 //COMPONENT
	 ///////////////////////////////////////////////////////////////////
	 //Import global styles
	 return <>
	 	<Field as={TextField} {...props}/>
		 {props.name && <ErrorMessage touched={props.touched} name={props.name} />}
	</>
   });