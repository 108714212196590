/**
 * Remplit "data" des evenements demandes par l objet elen et va recursivement gerer ses enfants
 * @param {r} data l objet parent
 * @param {*} elem l objet en cours {key,events,children}
 * @param {*} contextKeys la contextKey en cours 
 */
 var fill = (data,elem,contextKeys)=>{
    data[elem.key] = {};
    elem.events.forEach(key=>{
        data[elem.key][key]={
            PROCESSING: 'EVENT/'+contextKeys.join("/")+(contextKeys.length ? "/":"")+elem.key+"/"+key+'/PROCESSING',
            SUCCESS:    'EVENT/'+contextKeys.join("/")+(contextKeys.length ? "/":"")+elem.key+"/"+key+'/SUCCESS',
            FAIL:       'EVENT/'+contextKeys.join("/")+(contextKeys.length ? "/":"")+elem.key+"/"+key+'/FAIL',
            ABORT:      'EVENT/'+contextKeys.join("/")+(contextKeys.length ? "/":"")+elem.key+"/"+key+'/ABORT',
        };
    });
    if(elem.children)
        elem.children.forEach(child=>fill(data[elem.key],child,[...contextKeys,elem.key]));
};
export default fill;

