import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../actions/server';
import UsersList from '../usersList';
import selector from '../../../../selectors';
/**
 * Non utilise c est juste un template
 */
export default connect(
  (state) => {
    return {
      //STATE TO PROPS
      ///////////////////////////////////////////////////////////////////
      users:selector.users(state),
      loaded:state.server.loaded.users
    }
  },
  (dispatch) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions
  }, dispatch)
)(function (props) {
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const { getUsers, users = [],loaded } = props;

  return <UsersList users={users} loaded={loaded} updateUsers={getUsers}/>
  });