import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {Grid, FormControl, InputLabel, MenuItem, useMediaQuery, Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import { Field, getIn, useFormikContext } from 'formik';
import ErrorMessage from '../ErrorMessage';
import Select from './Select';
import Text from "./Text";
import actions from '../../../actions/server';
export const OTHER_VALUE = "" + Number.MAX_SAFE_INTEGER;
export default connect(
	/* ownProps : {
		visible:boolean,
		name,
		required,
		options:[
			{
				value,
				label
			}
		],
		defaultValue:value,
		value,
		name
	}
	 }*/
	(state, ownProps) => {
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////

		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		t: actions.t
	}, dispatch)
)(function ({ t, name, sx = {}, ...props }) {
	//COMPONENT
	///////////////////////////////////////////////////////////////////
	const { visible = true, label, variant = "outlined", options = [], ...inputProps } = props;
	const formikBag = useFormikContext();
	
	const [value, setValue] = React.useState(()=>getIn(formikBag.values,name));
	const [textValue, setTextValue] = React.useState(()=>getIn(formikBag.values,name));
	const [isOtherValue,setIsOtherValue] = React.useState(()=>getIn(formikBag.values,name) == OTHER_VALUE)
	

	React.useEffect(()=>{
		if(options.filter(elem=>elem.value==value).length==0) {
			//or other or option not found
			setIsOtherValue(true)
		}
		else	{
			setIsOtherValue(false);
		}
	},[value,options])
	
	React.useEffect(()=>{
		setValue(textValue)
	},[textValue])
	
	//console.log("real value",getIn(formikBag.values,name),"isOtherValue",isOtherValue,"value",value,"textValue",textValue);

	return <Box sx={sx}>
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Select {...props} options={[...options, {
					value: OTHER_VALUE,
					label: t("form.field.otherValue")
				}
				]} value={isOtherValue ? OTHER_VALUE: value} onChange={(e) => {
					setValue(e.target.value)
					if(e.target.value !=OTHER_VALUE)
						formikBag.setFieldValue(name, e.target.value);
					else
						formikBag.setFieldValue(name, textValue || "");
				}} />
			</Grid>

			{isOtherValue && <Grid item xs={12} sx={{ paddinqgTop: 1 }}>
				<Text {...props} value={textValue} label={t("form.field.otherValue")} onChange={(e) => {
					setTextValue(e.target.value)
					formikBag.setFieldValue(name, e.target.value);
				}} />
			</Grid>}
			{name && <Grid item xs={12} style={{marginTop:0,paddingTop:0}}><ErrorMessage sx={{marginTop:1}} name={name} /></Grid>}
		</Grid>
	</Box>
});