import React from 'react';
import { Route, Routes } from 'react-router' // react-router v4/v5
import { Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from "./actions/server";
//AUTH
import Login from './components/routes/login';
import PasswordForgotten from './components/routes/password_forgotten';
import DialogForm from './components/globals/DialogForm';
//ADMIN
//-----------------------------------------------------------
//-----------------------------------------------------------
  import AdminHeader from "./components/routes/admin/header";

  //COLLECTES
  //----------------------------------------------------
  import {
    AdminCollectsHome,
    AdminCollectsOneWrapper
  } from "./components/routes/admin/collects";

  //COMPANIES
  //----------------------------------------------------
  import {
    AdminCompaniesHome,
    AdminCompaniesOneWrapper,
    AdminCompaniesOneStatistics,
    AdminCompaniesOneInformation,
    AdminCompaniesOneUsers,
    AdminCompaniesOneForm,
    AdminCompaniesOneCollects
  } from "./components/routes/admin/companies";

  //USERS
  //----------------------------------------------------
  import { AdminUsersHome, AdminUsersOneCollects, AdminUsersOneInformation, AdminUsersOneWrapper } from './components/routes/admin/users';

  //TRANSLATION
  //----------------------------------------------------
  import AdminTranslations from "./components/routes/admin/translations";
  import { Box, CircularProgress } from '@mui/material';
  import PreventLeave from './components/globals/PreventLeave';

//COLLECTOR
//-----------------------------------------------------------
//-----------------------------------------------------------
import {
  CollectorCollectsHome,
  CollectorCollectsOneWrapper,
} from "./components/routes/collector/collects";


//SUPERVISOR
//-----------------------------------------------------------
//-----------------------------------------------------------
  import SupervisorHeader from "./components/routes/supervisor/header";

  //COLLECTES
  //----------------------------------------------------
  import {
    SupervisorCollectsHome,
    SupervisorCollectsOneWrapper
  } from "./components/routes/supervisor/collects";
  import SupervisorStatistics from "./components/routes/supervisor/statistics";

  //USERS
  //----------------------------------------------------
  import { SupervisorUsersHome, SupervisorUsersOneCollects, SupervisorUsersOneInformation, SupervisorUsersOneWrapper } from './components/routes/supervisor/users';

//VALIDATOR
//-----------------------------------------------------------
//-----------------------------------------------------------
import {
  ValidatorCollectsHome,
  ValidatorCollectsOneWrapper
} from "./components/routes/validator/collects";

export default connect(
  (state, ownProps) => {
    return {
      //STATE TO PROPS
      ///////////////////////////////////////////////////////////////////
      GLOBALS: state.server.GLOBALS,
      me: state.server.me,
      labelsV: state.server.labels.v,
      currentLocale: state.server.labels.currentLocale,
      loaded: state.server.loaded.app,
    }
  },
  (dispatch, props) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions
  }, dispatch)
)(function (props) {
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const { init, logout,getTranslations, getLocales, loaded, me, currentLocale } = props;

  //CONSTRUCTOR
  React.useEffect(() => {
    init({
      baseURL: process.env.REACT_APP_BASE_URL
    });
    getLocales();
    getTranslations().catch(error=>{
      return logout().then((ok)=>{
        console.log("logout",ok)
      })
    });
  }, [init, getLocales]);


  return (
    <> {
      me ?
        //-----------------------CONNECTE-----------------------
        //-----------------------ADMIN-----------------------
        <>
          {me.role === "ADMIN" &&
            <Routes>
              <Route path="/" element={<Outlet />}>
                {/*--------------- HOME ADMIN --------------------*/}
                <Route path={"/companies"} element={
                  <Box sx={{
                    display: "flex", flex: 1,
                    flexDirection: "column",
                    alignItems: "stretch"
                  }}>
                    <AdminHeader />
                    <Outlet />
                  </Box>
                }
                >
                  <Route index element={<AdminCompaniesHome />} />
                  <Route path={":id"} element={<AdminCompaniesOneWrapper />}>
                    <Route path={"statistics"} element={<AdminCompaniesOneStatistics />} />
                    <Route path={"form"} element={<AdminCompaniesOneForm />} />
                    <Route path={"information"} element={<AdminCompaniesOneInformation />} />
                    <Route path={"collects"} element={<AdminCompaniesOneCollects />} />
                    <Route path={"users"} element={<AdminCompaniesOneUsers />} />
                    <Route index element={<AdminCompaniesOneStatistics />} />
                  </Route>

                </Route>
                <Route path={"/users"} element={
                  <>
                    <AdminHeader />
                    <Outlet />
                  </>
                }
                >
                  <Route index element={<AdminUsersHome />} />
                  <Route path={":id"} element={<AdminUsersOneWrapper />}>
                    <Route path={"information"} element={<AdminUsersOneInformation />} />
                    <Route path={"collects"} element={<AdminUsersOneCollects />} />
                    <Route index element={<AdminUsersOneInformation />} />
                  </Route>
                </Route>
                <Route path={"/collects"} element={
                  <>
                    <AdminHeader />
                    <Outlet />
                  </>
                }
                >
                  <Route path={":id"} element={<AdminCollectsOneWrapper />}>

                  </Route>
                  <Route index element={<AdminCollectsHome />} />
                </Route>
                <Route path={"/translations"} element={
                  <>
                    <AdminHeader />
                    <AdminTranslations />
                  </>
                } />
                <Route index element={<>
                  <AdminHeader />
                  <AdminCollectsHome />
                </>} />
              </Route>
              <Route path="*" element={
                <>
                  <AdminHeader />
                </>
              } />
            </Routes>}
          {/*---------------------- COLLECTOR --------------------------*/}
          {me.role === "COLLECTOR" && <Routes>
            <Route path="/" element={<Outlet />}>
              <Route path={"/collects"} element={
                <>
                  <Outlet />
                </>
              }
              >
                <Route path={":id"} element={<CollectorCollectsOneWrapper />}/>
                <Route index element={<CollectorCollectsHome />} />
              </Route>
            </Route>
            <Route index element={<CollectorCollectsHome />} />
          </Routes>}
          {/*---------------------- SUPERVISOR --------------------------*/}
          {me.role === "SUPERVISOR" &&
            <Routes>
              <Route path="/" element={<Outlet />}>
                {/*--------------- HOME ADMIN --------------------*/}
                
                <Route path={"/users"} element={
                  <>
                    <SupervisorHeader />
                    <Outlet />
                  </>
                }
                >
                  <Route index element={<SupervisorUsersHome />} />
                  <Route path={":id"} element={<SupervisorUsersOneWrapper />}>
                    <Route path={"information"} element={<SupervisorUsersOneInformation />} />
                    <Route path={"collects"} element={<SupervisorUsersOneCollects />} />
                    <Route index element={<SupervisorUsersOneInformation />} />
                  </Route>
                </Route>
                <Route path={"/collects"} element={
                  <>
                    <SupervisorHeader />
                    <Outlet />
                  </>
                }
                >
                  <Route path={":id"} element={<SupervisorCollectsOneWrapper />}>

                  </Route>
                  <Route index element={<SupervisorCollectsHome />} />
                </Route>
                
                <Route index element={<>
                  <SupervisorHeader />
                  <SupervisorStatistics />
                </>} />
              </Route>
              <Route path="*" element={
                <>
                  <SupervisorHeader />
                </>
              } />
            </Routes>}
           {/*---------------------- VALIDATOR --------------------------*/}
           {me.role === "VALIDATOR" && <Routes>
            <Route path="/" element={<Outlet />}>
              <Route path={"/collects"} element={
                <>
                  <Outlet />
                </>
              }
              >
                <Route path={":id"} element={<ValidatorCollectsOneWrapper />}/>
                <Route index element={<ValidatorCollectsHome />} />
              </Route>
            </Route>
            <Route index element={<ValidatorCollectsHome />} />
          </Routes>}
        </>
        :
        loaded ?
          //-----------------------PAS CONNECTE-----------------------
          <Routes>
            <Route path={"password_forgotten"} element={<PasswordForgotten />} />
            <Route path={"login"} element={<Login />} />
            <Route path={"*"} element={<Login />} />
          </Routes>
          :
          <Box sx={{
            display: "flex",
            alignItems: "center", justifyContent: "center",
            height: "100vh",
            width: "100vw"
          }}>
            <CircularProgress />
          </Box>
    }
      <PreventLeave />
      <DialogForm/>
    </>
  );
});
