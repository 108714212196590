import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0, // small phone
      nativeInputs:500,
      xs: 300, // phone
      sm: 600, // tablets
      md: 900, // small laptop
      lg: 1200, // desktop
      xl: 1536 // large screens
    }
  },
  palette: {
    background:{
      default:"#F5F5F5",
      paper:"#FFFFFF"
    },
    primary: {
      main: '#0589A5',
      
    },
    secondary: {
      main: '#FF5D06',
    },
    error: {
      main: red.A400,
    },
  },
  components:{
    ".test":{
      backgroundColor:"red"
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          minHeight:"100vh",
          
        },
        "#root":{
          minHeight:"100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        },
        ".ql-editor,.ql-container":{
          background:"white",
          minHeight:250,
          borderBottomLeftRadius:4,
          borderBottomRightRadius:4
        },
        ".quill":{
          borderRadius:4
        }
      }
    },
    MuiListItem:{
      styleOverrides:{
        dense:{
          padding:0
        }
      }
    },
    MuiInputBase:{
      styleOverrides:{
        colorPrimary:{
          backgroundColor:"white"
        }
        
      }
    },
    MuiAutocomplete:{
      styleOverrides:{
        inputRoot:{
          //padding:0
        }
      }
    },
    MuiSelect:{
      styleOverrides:{
        select:{
          //paddingTop:8,
          //paddingBottom:8,
        }
      }
    },
    MuiNativeSelect:{
      styleOverrides:{
        select:{
          width:"100%"
        }
      }
    },
    MuiFormControl:{
      styleOverrides:{
        root:{
          width:"100%"
        }
      }
    },
    MuiTab:{
      styleOverrides: {
        root: {
          whiteSpace: "nowrap"
        }
      }
    },
    MuiDialogContent:{
      styleOverrides: {
        root: {
          paddingTop: "4px !important",
        },
      },

    }
  }
});

export default theme;