import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Grid, Select, FormControl, InputLabel, NativeSelect, MenuItem } from '@mui/material';
import { Field, getIn, useFormikContext } from 'formik';
import ErrorMessage from "../../../../../../globals/ErrorMessage";
import Fields from '../../../../../../globals/Fields';
import actions from '../../../../../../../actions/server';
import produce from 'immer';

export default connect(

	(state, ownProps) => ({
		//STATE TO PROPS
		///////////////////////////////////////////////////////////////////
		locales: state.server.locales
	}),
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		t: actions.t
	}, dispatch)
)(function ({ t, name, locales, ...props }) {
	//COMPONENT
	///////////////////////////////////////////////////////////////////
	const formikBag = useFormikContext();
	

	const getEmptyOption = React.useCallback(()=>{
		return {
			value: "",
			label: locales.reduce((acc, cur) => {
				acc[cur] = "";
				return acc;
			}, {})
		}
	},[locales])
	var field = getIn(formikBag.values, name);
	var options = field.options||[getEmptyOption()];
	if (!field)
		return null;
	return <Grid container spacing={1}>
		{options.map((option, i) => {
			if(field.multiple) {
				//defaultValue is array, have to check if option value is in this array 
				var value = getIn(formikBag.values, name + ".options[" + i + "].value");
				var def = getIn(formikBag.values, name + ".defaultValue") || [];
				if(def && !Array.isArray(def)) {
					//if we just switched from !multiple, update accordingly
					def = [def];
					formikBag.setFieldValue(name + ".defaultValue",def);
				}
				var isDefault = def.find(valuei=>valuei==value) ? true : false;
				
			} else {
				var def = getIn(formikBag.values, name + ".defaultValue");
				if(def && Array.isArray(def)) {
					//if we just switched from multiple, update accordingly
					if(def[0]) 
						formikBag.setFieldValue(name + ".defaultValue",def[0]);
				}
				var isDefault = getIn(formikBag.values, name + ".options[" + i + "].value") === getIn(formikBag.values, name + ".defaultValue");
			}
			
			return <Grid item xs={12} key={i+formikBag.values.currentLocale}>
				<Grid container spacing={1} alignItems={"center"}>
					<Grid item xs={3}>
						<Fields.Text fullWidth name={name + ".options[" + i + "].value"} label={t("form.field.value.label")} />
					</Grid>

					<Grid item xs={5}>
						<Fields.Text fullWidth name={name + ".options[" + i + "].label[" + formikBag.values.currentLocale + "]"} label={t("form.field.label." + formikBag.values.currentLocale + ".label")} />
					</Grid>

					<Grid item xs={2}>
						<Button disabled={options.length==1} fullWidth onClick={() => {
							if(options.length==1)
								return;
							var newOptions = produce(options,(draft)=>{
								draft.splice(i, 1);
							})
							if(isDefault) {
								if(field.multiple) {
									formikBag.setFieldValue(name + ".defaultValue",def.filter(val=>val !== value));
								} else 
									formikBag.setFieldValue(name + ".defaultValue",undefined);
							}
								
							formikBag.setFieldValue(name + ".options", newOptions);
						}}>
							{t("general.delete")}
						</Button>
					</Grid>
					<Grid item xs={2}>
						<Button
							fullWidth
							variant={isDefault ? "contained" : "outlined"}
							color={"primary"}
							onClick={() => {
								if(isDefault) {
									if(field.multiple) {
										
										formikBag.setFieldValue(name + ".defaultValue",def.filter(val=>val !== value));
									} else 
										formikBag.setFieldValue(name + ".defaultValue",undefined);
								} else
									if(field.multiple) {
										def.push(value);
										formikBag.setFieldValue(name + ".defaultValue",def);
									} else
										formikBag.setFieldValue(name + ".defaultValue", getIn(formikBag.values, name + ".options[" + i + "].value"));
							}}>
							{t("form.setDefaultValue")}
						</Button>
					</Grid>
					{i == options.length - 1 && <>
						<Grid item xs={8}></Grid>
						<Grid item xs={4}><Button onClick={()=>{
							var newOptions = produce(options,(draft)=>{
								draft.push(getEmptyOption())
							})
							console.log(newOptions,name+".options");
							formikBag.setFieldValue(name + ".options", newOptions);
						}}fullWidth variant={"outlined"}>{t("form.addOption")}</Button></Grid>
					</>}
				</Grid>
			</Grid>
		})}
	</Grid>
});