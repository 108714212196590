import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {Button,Grid, Select, FormControl, InputLabel, NativeSelect, MenuItem } from '@mui/material';
import { Field, getIn, useFormikContext } from 'formik';
import ErrorMessage from "../../../../../../globals/ErrorMessage";
import Fields from '../../../../../../globals/Fields';
import actions from '../../../../../../../actions/server';

export default connect(

	(state, ownProps) => ({
		//STATE TO PROPS
		///////////////////////////////////////////////////////////////////

	}),
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		t:actions.t
	}, dispatch)
)(function ({t, name, ...props }) {
	//COMPONENT
	///////////////////////////////////////////////////////////////////
	//const formikBag = useFormikContext();
	//var field = getIn(name);
	
	
	return <Grid container>
		<Fields.Text fullWidth name={name+".defaultValue"} label={t("form.field.defaultValue")}/>
	</Grid>

})