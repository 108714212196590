import React from 'react';
import { Link, useNavigate, useParams, useResolvedPath } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../../actions/server';
import UIActions from "../../../../../../actions/ui";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box, Button, Container, Grid, ListItem, ListItemText, ListItemButton, CommentIcon, TextField, LinearProgress, MenuItem, IconButton, Typography, ButtonBase, Popover, CircularProgress, FormControl, InputLabel, Select, Chip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Formik, Form, Field, useFormikContext } from 'formik';
import Fields from '../../../../../globals/Fields';
import ErrorMessage from '../../../../../globals/ErrorMessage';
import * as Yup from 'yup';
import SplitButton from '../../../../../globals/SplitButton';
import produce from 'immer';
import selector from '../../../../../../selectors';
import SectionContent from './SectionContent';
import SubmitForm from './SubmitForm';
import { DateTime } from 'luxon';
import { DragHandle } from '@mui/icons-material';

//get a new field id
export const getNewFieldId = () => {
  return "" + Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)
}

//Fields required
export const requiredFields = {
  authors: true,
  country: true,
  gps_tag: true,
  crop: true,
  monocot: true,
  dicot: true,
  perennial: true,
  soil_color: true,
  sky: true,
  camera_height: true,
  camera_tilt: true
}

const emptySection = {
  label: {},
  deleteable: true,
  locked: {},
  condition: "",
  fields: [],
};
export default connect(
  (state, ownProps) => {
    return {
      //STATE TO PROPS
      ///////////////////////////////////////////////////////////////////
      locales: state.server.locales,
      currentLocale: state.server.labels.currentLocale,
      clipboard: state.ui.clipboard,
      companies: state.server.companies
    }
  },
  (dispatch, props) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions,
    ...UIActions
  }, dispatch)
)(function (props) {
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const {
    companies, getForm, getFormsOfCompany, createFormForCompany,
    locales, currentLocale, clipboard, copySection,
    t
  } = props;
  const [loaded, setLoaded] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);

  //Route params
  const params = useParams();

  //Get company from ID
  var company = companies.find((c) => c.id == parseInt(params.id)) || {};

  const [companyForms, setCompanyForms] = React.useState([]);
  const [companyFormsLoaded, setCompanyFormsLoaded] = React.useState(false);
  const [currentFormId, setCurrentFormId] = React.useState(null);
  const [currentForm, setCurrentForm] = React.useState(null);
  const [openedSectionMenu, setOpenedSectionMenu] = React.useState({
    index: -1,
    element: null
  });

  //Update list of forms and loads last form if current form not in list
  const updateForms = React.useCallback(() => {
    return getFormsOfCompany(params.id).then(ok => {
      setCompanyForms(ok.data.forms);
      if (!currentFormId || !ok.data.forms.find((formi => formi.id == currentFormId))) {
        setCurrentFormId(ok.data.forms.length ? ok.data.forms[ok.data.forms.length - 1].id : null);
      }
    }).finally(() => {
      setCompanyFormsLoaded(true);
    });
  }, [getFormsOfCompany, params.id, currentFormId])


  //start : get forms
  React.useEffect(() => {
    updateForms();
    return ()=>{
      localStorage.removeItem(params.id);
    }
  }, []);

  //whem current form il change -> loads it
  React.useEffect(() => {
    if (companyFormsLoaded) {
      setFormLoading(true);
      if (currentFormId)
        getForm(currentFormId).then(ok => {
          var form = JSON.parse(ok.data.content);
          if(ok.data.activatedOnce) {
            form.activatedOnce = true;
          }
          //Add id to section if doesnt have
          form.form = form.form.map(section => {
            if (section.id)
              return section;
            else
              return { ...section, id: getNewFieldId() }
          });
          //set values which are outside form
          form.name = ok.data.name;
          form.id = ok.data.id;
          form.status = ok.data.status;

          //Have to update locales of form in case with old list of locales
          //--------------------------------------------------------------

          var possibleLocales = ["default", ...locales];
          var check = (toCheck) => {
            if(!toCheck) return toCheck;
            //do the check
            for (var j = 0; j < possibleLocales.length; j++) {
              var localej = possibleLocales[j];
              if (!toCheck[localej])
                toCheck[localej] = "";
            }
            return toCheck;
          }
          
          //all labels to check
          for (var i = 0; i < form.form.length; i++) {
            var section = form.form[i];
            section.label = check(section.label);
            for (var j = 0; j < section.fields.length; j++) {
              var field = section.fields[j];
              if (field.label) field.label = check(field.label);
              if (field.explanation) field.explanation = check(field.explanation);
              if (field.options) {
                for (var l = 0; l < field.options.length; l++) {
                  if(field.options[l].label)
                    field.options[l].label = check(field.options[l].label);
                }
              }
              if (field.fields)
                for (var k = 0; k < field.fields.length; k++) {
                  var fieldi = field.fields[k];
                  if (fieldi.label)
                    fieldi.label = check(fieldi.label);
                  if (fieldi.options) {
                    for (var l = 0; l < fieldi.options.length; l++) {
                      if(fieldi.options[l].label)
                        fieldi.options[l].label = check(fieldi.options[l].label);
                    }
                  }
                }
            }
          }
          //console.log("store form version",form.form);
          localStorage.setItem(params.id,JSON.stringify(form.form));
          setCurrentForm(form);
        }).catch(ok => {
          setCurrentForm(getEmptyFormikValues());
        }).finally(() => {
          setLoaded(true);
          setFormLoading(false);
        })
      else {
        setCurrentForm(getEmptyFormikValues());
        setFormLoading(false);
        setLoaded(true);
      }
    }
  }, [companyFormsLoaded, currentFormId])


  //depends of the locals
  //Depending of the locals return an empty form filled with the good local values
  //containing the first section with mandatory fields
  const getEmptyFormikValues = React.useCallback(() => {
    try {
      var name = DateTime.now().setLocale(company.localeDefault).toLocaleString();
    } catch (e) {
      var name = DateTime.now().toLocaleString();
    }

    return {
      currentLocale: currentLocale,
      currentSection: 0,
      form: [
        {
          id: getNewFieldId(),
          name,
          label: locales.reduce((acc, curr) => {
            acc[curr] = t("form.section", curr) + " 1";
            return acc;
          }, {}),
          condition: "",
          deleteable: false,
          locked: { deleteable: true },
          fields: [
            {
              "id": getNewFieldId(),
              "component": "SelectCollector",
              "visible": false,
              "name": "authors",
              "label": locales.reduce((acc, curr) => {
                acc[curr] = t("form.collector.label", curr);
                return acc;
              }, {}),
              "export": true,
              "condition": "",
              "deleteable": false,
              "required": true,
              "locked": { deleteable: true, visible: true, component: true, name: true, export: true, condition: true, required: true },

            },
            {
              "id": getNewFieldId(),
              "component": "Date",
              "visible": true,
              "name": "date",
              "label": locales.reduce((acc, curr) => {
                acc[curr] = t("form.date.label", curr);
                return acc;
              }, {}),
              "export": true,
              "condition": "",
              "deleteable": false,
              "required": true,
              "locked": { deleteable: true, visible: true, component: true, name: true, export: true, condition: true, required: true },
            }
          ]
        }
      ]
    }
  }, [locales, currentLocale])

  if (!loaded || !currentForm) return (
    <Box style={{
      flex: 1, display: "flex", alignItems: "center", justifyContent: "center"
    }}>
      <CircularProgress />
    </Box>);
  return <>
    <Formik
      enableReinitialize={true}
      validateOnChange={false}
      initialValues={currentForm}
      onSubmit={({ name, ...values }, { setSubmitting}) => {
        setSubmitting(true);
        setFormLoading(true);
      
        createFormForCompany({
          name,
          companyId: company.id,
          content: JSON.stringify(values),
          formId:values.keepId ? values.id:null
        }).then((ok) => {
          console.log(ok);
          //we created a new form, update form and set new form as current
          setCurrentFormId(ok.data.id);
          updateForms().finally(() => {
            setSubmitting(false);
            setFormLoading(false);
          });
        }).catch(ok => {
          console.log("error", ok);
          setSubmitting(false);
          setFormLoading(false);
        })
      }}
      validationSchema={Yup.object({
        name:Yup.string().required(t("form.name.required")).min(3,t("form.name.lengthError"))
      })}
    >
      {(formikBag) => {
        //current selected locale in the form
        let formLocale = formikBag.values.currentLocale;
        return <>

          <Form style={{
            flex: 1, display: "flex",
            overflow: "hidden"
          }}>
            <Grid container alignItems={"stretch"} sx={{
              flex: 1,
              marginTop: 1,
              overflow: "hidden"
            }}>
              {/*--------------------- SECTION LIST -------------------------*/}
              {/*------------------------------------------------------------*/}
              <Grid item sx={{
                opacity: formLoading ? 0.4 : 1,
                width: 230,
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                overflow: "hidden"
              }}>
                <Box sx={{
                  flex: 1,
                  position: "relative"
                }}>
                  <Box sx={{
                    position: "absolute",
                    top: 0, left: 0, right: 0, bottom: 0, overflow: "auto", padding: 1
                  }}>
                    <DragDropContext
                      onDragEnd={(result) => {
                        if (!result.destination || result.destination.index === result.source.index) {
                          return;
                        }

                        //REORDER
                        const sections = Array.from(formikBag.values.form);
                        const [removed] = sections.splice(result.source.index, 1);
                        sections.splice(result.destination.index, 0, removed);

                        //console.log(sections, formikBag.values.form);

                        formikBag.setFieldValue("form", sections);
                        formikBag.setFieldValue("currentSection", result.destination.index);
                      }}>
                      <Droppable droppableId="list">
                        {provided => {
                          //console.log("coin",formikBag.values.form[formikBag.values.currentSection]);
                          return (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              {/*--------------------- THE LIST -------------------------*/}
                              {formikBag.values.form.map((section, i) => {
                                return <Draggable key={section.id} draggableId={section.id} index={i}>
                                  {provided => <>
                                    <ListItem
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      sx={{
                                        backgroundColor: i == formikBag.values.currentSection ? "primary.main" : (section.checkpoint ? "grey.600" : undefined),
                                        color: i == formikBag.values.currentSection || section.checkpoint ? "primary.contrastText" : undefined,
                                      }}

                                      secondaryAction={
                                        <IconButton edge="end" aria-label="comments" onClick={(e) => {
                                          setOpenedSectionMenu({ index: i, element: e.target });
                                        }}>
                                          <MoreVertIcon sx={{
                                            color: i == formikBag.values.currentSection || section.checkpoint ? "primary.contrastText" : undefined,
                                          }} />
                                        </IconButton>
                                      }
                                      disablePadding
                                      onClick={() => {
                                        formikBag.setFieldValue("currentSection", i);
                                      }}
                                    >
                                      <ListItemButton role={undefined} onClick={() => { }} dense>
                                        <ListItemText primary={<div {...provided.dragHandleProps}>{section.label[formLocale] || section.label[currentLocale]}</div>} />
                                      </ListItemButton>
                                    </ListItem>
                                    <Popover
                                      open={openedSectionMenu.index == i}
                                      elevation={1}
                                      anchorEl={openedSectionMenu.element}
                                      onClose={() => { setOpenedSectionMenu({ index: -1, element: null }) }}
                                      anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                      }}
                                    >
                                      <>
                                        {(section.locked && section.locked["copy"]) || <MenuItem onClick={() => {
                                          copySection(section);
                                          setOpenedSectionMenu({ index: -1, component: null });
                                        }}>{t("form.section.copy.cta")}</MenuItem>}
                                        {(section.locked && section.locked["deleteable"]) || <MenuItem onClick={() => {
                                          var newForm = produce(formikBag.values.form, (draft) => {
                                            draft.splice(i, 1);
                                          });

                                          //delete if this is the current section
                                          if (formikBag.values.currentSection == i)
                                            formikBag.setFieldValue("currentSection", formikBag.values.currentSection - 1);

                                          formikBag.setFieldValue("form", newForm);
                                          setOpenedSectionMenu({ index: -1, component: null });
                                        }}>{t("form.section.delete.cta")}</MenuItem>}
                                      </>
                                    </Popover>
                                  </>}
                                </Draggable>
                              })}
                              {provided.placeholder}
                            </div>
                          )
                        }}
                      </Droppable>
                      {false && Array(1000).fill("side content").join(" ")}
                    </DragDropContext>
                  </Box>
                </Box>
                {/*--------------------- NEW SECTION -------------------------*/}
                <Box sx={{
                  padding: 1,
                }}>
                  <SplitButton
                    fullWidth={true}
                    options={[
                      {
                        label: t("form.addSection"),
                        action: () => {
                          var newForm = produce(formikBag.values.form, (draft) => {
                            //console.log(JSON.stringify(draft.form));
                            draft.push({
                              ...emptySection,
                              id: getNewFieldId(),
                              label: locales.reduce((acc, curr) => {
                                acc[curr] = t("form.section", curr) + " " + (draft.length + 1);
                                return acc;
                              }, {})
                            });
                          })

                          formikBag.setFieldValue("form", newForm);

                        },
                        active: true,
                      },
                      {
                        label: t("form.addCheckPoint"),
                        action: () => {
                          var newForm = produce(formikBag.values.form, (draft) => {
                            //console.log(JSON.stringify(draft.form));
                            draft.push({
                              ...emptySection,
                              id: getNewFieldId(),
                              checkpoint: true,
                              label: locales.reduce((acc, curr) => {
                                acc[curr] = t("form.checkPoint", curr) + " " + (draft.length + 1);
                                return acc;
                              }, {})
                            });
                          })

                          formikBag.setFieldValue("form", newForm);
                          console.log("new checkpoint click", newForm);

                        },
                        active: true,
                      },
                      {
                        label: t("form.pasteSection"),
                        action: () => {
                          console.log("click paste section");
                          //formikBag.values.currentSection
                          var sectionToPaste = produce(clipboard.section, (draft) => {
                            draft.locked = {};
                            draft.id = getNewFieldId();
                            draft.fields = draft.fields.map(fieldi => ({ ...fieldi, locked: {} }))
                          });
                          var newForm = produce(formikBag.values.form, (draft) => {
                            draft.push(sectionToPaste);
                          });
                          formikBag.setFieldValue("form", newForm);
                          formikBag.setFieldValue("currentSection", formikBag.values.currentSection + 1);
                        },
                        active: clipboard.section ? true : false,
                      }
                    ]} />
                </Box>
              </Grid>
              {/*--------------------- CURRENT SECTION -------------------------*/}
              {/*------------------------------------------------------------*/}
              <SectionContent sx={{
                opacity: formLoading ? 0.4 : 1
              }} />
              {/*--------------------- CHOOSE LOCALE TO EDIT  -------------------------*/}
              {/*----------------------------------------------------------------------*/}
              <Grid item sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                opacity: formLoading ? 0.4 : 1
              }}>
                <Grid container spacing={1} sx={{ padding: 1 }} alignItems={"center"}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id={"select-version-label"}>{t("form.chooseVersion")}</InputLabel>
                      <Select
                      key={currentFormId}
                        labelId={"select-version-label"}
                        label={t("form.chooseVersion")}
                        value={currentFormId}
                        onChange={(e) => {
                          setCurrentFormId(e.target.value);
                        }}
                      >
                        {companyForms.map((version, i) => (
                          <MenuItem key={i + " " + version.id} value={version.id}>
                            <Grid container spacing={1} alignItems={"center"}>
                              <Grid item>
                                <Chip label={t("form.status." + version.status)} color={version.status == "ACTIVE" ? "primary" : "default"} />
                              </Grid>
                              <Grid item>
                                {version.name}
                              </Grid>
                            </Grid>


                          </MenuItem>))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Box sx={{ padding: 1, paddingTop: 0 }} ><Fields.Text touched={true} name={"name"} label={t("form.name")}required={true} /></Box>
                <SubmitForm updateForms={updateForms} formLoading={formLoading} setFormLoading={setFormLoading} />
              </Grid>
            </Grid>
          </Form>
        </>
      }}
    </Formik>
  </>
});
