import {FormControlLabel,Switch, TextField } from '@mui/material';
import React from 'react'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Field, getIn, useFormikContext } from 'formik';
import ErrorMessage from '../ErrorMessage';
export default connect(
	 (state, ownProps) =>{return {
	   //STATE TO PROPS
	   ///////////////////////////////////////////////////////////////////
   
	 }},
	 (dispatch, props) => bindActionCreators({
	   //ACTIONS
	   ///////////////////////////////////////////////////////////////////
	 
	 }, dispatch)
   )(function(props){
	 //COMPONENT
	 ///////////////////////////////////////////////////////////////////
	  const {sx={}}=props;
	 const formikBag = useFormikContext();
	 var value = getIn(formikBag.values,props.name);
	 return <>
	 	<FormControlLabel sx={sx} control={<Switch checked={value} onChange={(e)=>{
			 formikBag.setFieldValue(props.name,e.target.checked);
		}}/>} label={props.label} {...props}/>
	 	 {props.name && <ErrorMessage name={props.name} />}
	</>
   });