import React from 'react';
import {Link } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import actions from "../../../actions/server";
import { Button, Container, Grid, TextField,LinearProgress, Typography } from '@mui/material';
import { Formik, Form, Field} from 'formik';
import ErrorMessage from '../../globals/ErrorMessage';
import SuccessMessage from '../../globals/SuccessMessage';
import * as Yup from 'yup';
/**
 * Non utilise c est juste un template
 */
 export default connect(
  (state, ownProps) =>{return {
    //STATE TO PROPS
    ///////////////////////////////////////////////////////////////////
  }},
  (dispatch, props) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions
  }, dispatch)
)(function(props){
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const {passwordForgotten,t} = props;
  const [error,setError]= React.useState(null);
  const [success,setSuccess] = React.useState(null);
  return <Container style={{height:"100vh",display:"flex",alignItems:"center"}} maxWidth={"xs"}>
	<Formik
       initialValues={{ email: ''}}
	   onSubmit={(values, { setSubmitting }) => {
		   setSubmitting(true);
		   passwordForgotten(values.email)
			.then((ok=>{
				setSuccess(t("passwordForgotten.success"));
				setError(null);
			}))
			.catch(e=>{
				console.log(e);
				setError(e.response ? e.response.data.label:"global.unknown");
				setSuccess(null);
			})
			.finally(e=>{
				setSubmitting(false);
			});
		   console.log(values);
	  }}
	  validationSchema={Yup.object().shape({
           email:Yup.string().email(t("form.field.email.invalid"))
      })}
    >
		{(formikBag) => (
         <Form>
			<Grid container alignItems={"center"}>
				<Grid item container spacing={2}>
					{error && <Grid item xs={12}>
						<ErrorMessage>
							{t(error)}
						</ErrorMessage>
					</Grid>}
					{success && <Grid item xs={12}>
						<SuccessMessage>
							{t(success)}
						</SuccessMessage>
					</Grid>}
					<Grid item xs={12}>
						<Field required fullWidth name={"email"} as={TextField}  label={t("form.email.label")} variant="outlined" type="email"/>
						<ErrorMessage name="email" />
					</Grid>
					
					{formikBag.isSubmitting && <Grid item xs={12}><LinearProgress /></Grid>}
					<Grid item xs={12}>
						<Button type={"submit"} fullWidth variant={"contained"}>{t("auth.passwordForgotten.go")}</Button>
					</Grid>
					
					<Grid item xs={12}>
						<Typography component={Link} to={"/login"}>{t("auth.login.cta")}</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Form>
	   )}
	</Formik>
  </Container>
});
