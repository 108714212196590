import React from 'react';
import { useNavigate, useResolvedPath, useMatch, useLocation, useParams } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from "../../../actions/server";
import UIActions from "../../../actions/ui";
import { AppBar, Box, Tabs, Tab, Popover, Button, Toolbar, MenuItem, Typography, Divider } from '@mui/material';
import LinkTabs from '../../globals/LinkTabs';
import selector from "../../../selectors/";
import { LocalSee } from '@mui/icons-material';
import Fields from '../../globals/Fields';
import * as Yup from "yup";
import { DateTime } from 'luxon';
import { Duration } from 'luxon';
/**
 * Non utilise c est juste un template
 */
export default connect(
	(state, ownProps) => {
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////
			me: state.server.me,
			companies: state.server.companies,
			collects: state.server.collects,
			users: selector.users(state),
			locales: selector.localesOptions(state),
			currentLocale: state.server.labels.currentLocale
		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		...actions,
		...UIActions
	}, dispatch)
)(function (props) {
	//COMPONENT
	///////////////////////////////////////////////////////////////////
	const { me, downloadLogs,possession,logout, t, dialogForm, resetPassword, getUsers, currentLocale, setCurrentLocale, getCollects, getCompanies, collects, companies, users, locales } = props;

	//popup on the right 
	const expandButton = React.useRef();
	const [userExpandOpened, setUserExpandOpened] = React.useState(false);
	const navigate = useNavigate();

	React.useEffect(() => {
		getUsers();
		getCompanies()
		getCollects();
	}, [getUsers, getCompanies, getCollects])
	
	if (!me) return null;
	return (
		<AppBar position="sticky" color={"transparent"} elevation={0} sx={{
			bgcolor: "grey.A100"
		}}>
			<Toolbar disableGutters variant="dense">
				<LinkTabs tabs={[
					[
						{
							route: "", end: true, getLabel: (active, params) => <Box sx={{
								display: "flex", alignItems: "center"
							}}>
								<img src="/logo.png" style={{ width: "90px", marginRight: 10 }} />
								{t("collects.cta")}
							</Box>
						},
						{ route: "collects", end: true, getLabel: (active, params) => "" },
						{
							route: "collects/:id", end: false, getLabel: (active, params) => {
								if (!active || !params) return "";
								var collect = collects.find(itemi => itemi.id == parseInt(params.id));

								return collect ? (" > " + collect.name) : "";
							}
						}
					],
					[
						{ route: "companies", end: true, getLabel: (active, params) => t("companies.cta") },
						{
							route: "companies/:id", end: false, getLabel: (active, params) => {
								if (!active || !params) return "";
								var company = companies.find(itemi => itemi.id == parseInt(params.id));

								return company ? (" > " + company.name) : "";
							}
						}
					],
					[
						{ route: "users", end: true, getLabel: (active, params) => t("users.cta") },
						{
							route: "users/:id", end: false, getLabel: (active, params) => {
								if (!active || !params) return "";
								var user = users.find(itemi => itemi.id == parseInt(params.id));

								return user ? (" > " + user.name) : "";
							}
						}
					],
					...me.role == "ADMIN" ? [
						[
							{ route: "translations", end: true, getLabel: (active, params) => t("translations.cta") }
						]
					] : []
				]}
					tabSx={(active, i) => {
						return {
							bgcolor: active ? "primary.main" : "transparent",
							color: active ? "white !important" : "inherit",
							opacity: 1,
							transition: "all ease 0.5s 0.2s"
						}
					}}
					variant="scrollable"
					scrollButtons="auto"
					aria-label="scrollable auto tabs example"
					sx={{ flexGrow: 1 }}
				/>
				<>
					<Button variant={userExpandOpened ? "contained" : "initial"} onClick={() => setUserExpandOpened(!userExpandOpened)} ref={expandButton} color={userExpandOpened ? "primary" : "inherit"}>{me.name}</Button>
					<Popover
						open={userExpandOpened}
						elevation={1}
						anchorEl={expandButton.current}
						onClose={() => { setUserExpandOpened(false) }}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
					>
						<MenuItem onClick={() => logout()}>{t("auth.logout.cta")}</MenuItem>
						<Divider />
						{true && <MenuItem onClick={() => dialogForm({
                  label:t("collects.action.changeRole.cta"),
                  text:t("collects.action.changeRole.text"),
                  initialValues:{companyId:null,collectorId:null},
                  fields: [
										{
                      component:Fields.Select,
                      props:{
												options:companies.map(c=>{
													return {value:c.id,label:c.name}}),
                        name:"companyId",
                        label:t("collect.companyId.label"),
                        fullWidth:true,
                        required:true
                      }
                    },
                    {
                      component:Fields.Select,
                      props:{
												options:(formikBag)=>{
													return users.filter(user=>user.role=="COLLECTOR"&&user.companyId==formikBag.values.companyId).map(user=>{return {value:user.id,label:user.name}})
												},
                        name:"collectorId",
                        label:t("collect.collectorId.label"),
                        fullWidth:true,
                        required:true
                      }
                    },
										
									]
                }).then((values)=>{
									return possession({id:values.collectorId}).then(ok => {
										console.log("coin ?");
										navigate("/")
										setUserExpandOpened(false) 
									}).catch((fail)=>{
										console.log(fail)
									})
								} )}>{t("auth.changeRole.cta")}</MenuItem>
						}
						<MenuItem onClick={() => {
							dialogForm({
								label: t("users.actions.changePassword.cta"),
								action: resetPassword,
								initialValues: {
									id: me.id,
									"newPassword": "",
									"passwordCheck": ""
								},
								validationSchema: {
									passwordCheck: Yup.string()
										.required(t("form.required"))
										.oneOf([Yup.ref('newPassword')], t("form.passwordCheck.error")),
								},
								fields: [
									{
										component: Fields.Text,
										props: {
											name: "newPassword",
											label: t("users.password"),
											type: "password",
											fullWidth: true,
											required: true
										}
									},
									{
										component: Fields.Text,
										props: {
											name: "passwordCheck",
											label: t("users.passwordCheck"),
											type: "password",
											fullWidth: true,
											required: true
										}
									}
								]
							})
						}}>{t("users.actions.changePassword.cta")}</MenuItem>
						<Divider />
						{locales.map(locale => locale.value == "default" ? null : <MenuItem sx={{
							"&, &:hover": {
								backgroundColor: locale.value == currentLocale ? "primary.main" : undefined,
								color: locale.value == currentLocale ? "primary.contrastText" : undefined,
							},
						}} key={locale.value} onClick={() => {
							setCurrentLocale(locale.value);
							setUserExpandOpened(false);
						}}>{t(locale.label)}</MenuItem>)}
						<Divider />
						<MenuItem onClick={() => {
							dialogForm({
								label: t("users.actions.downloadLogs.cta"),
								action: (values)=>{
									var toReturn = {
										startTime:(values.date[0].toFormat ? values.date[0]:DateTime.fromISO(values.date[0])).toFormat("yyyy-MM-dd'T'hh:mm:ss"),
										endTime:(values.date[1].toFormat ? values.date[1]:DateTime.fromISO(values.date[1])).toFormat("yyyy-MM-dd'T'hh:mm:ss")
									};
									//console.log(toReturn);
									return downloadLogs(toReturn).then(({data})=>{
										const blob = new Blob([data], {
											type: 'text/csv'
											});
											const url = window.URL.createObjectURL(blob);
											window.open(url);
										//console.log(data,"logs");
									});
								},
								initialValues: {
									date: [
										DateTime.now().minus(Duration.fromObject({ days: 7 })),
										DateTime.now()
									]
								},
								validationSchema: {

								},
								fields: [
									{
										component: Fields.Date,
										props: {
											name: "date",
											label: [t("date.from"), t("date.to")],
											fullWidth: true,
											required: true
										}
									}
								]
							})
						}}>{t("users.actions.downloadLogs.cta")}</MenuItem>
					</Popover>
				</>
			</Toolbar>
		</AppBar>
	)
});
