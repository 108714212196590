import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../../actions/server';
import UIActions from "../../../../../../actions/ui";
import { Dialog, Box, Grid, ButtonBase, ListItem, List, ListItemButton, ListItemText, Divider, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import selector from '../../../../../../selectors';
import SplitButton from '../../../../../globals/SplitButton';
import { requiredFields } from './index';
import CollectForm from '../../../../../globals/Collect/Form';
import {useParams } from "react-router-dom";

const SubmitForm = connect(
	(state, ownProps) => {
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////
			users: selector.users(state),
			companies: state.server.companies,
			roles: state.server.roles,
			locales: state.server.locales,
			currentLocale: state.server.labels.currentLocale,
			clipboard: state.ui.clipboard,
			defaultOptions: selector.defaultOptions(state)
		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		...actions,
		...UIActions
	}, dispatch)
)(function (props) {
	//COMPONENT
	///////////////////////////////////////////////////////////////////
	const {
		sx = {}, locales, updateForms, activateForm, formLoading, setFormLoading,
		t
	} = props;

	const [checkingStatus, setCheckingStatus] = React.useState(false);
	const [expandedItem, setExpandedItem] = React.useState(-1);
	const formikBag = useFormikContext();
	const [requiredFieldsStatus, setRequiredFieldStatus] = React.useState([]);

	const [testForm, setTestForm] = React.useState(null);
	const [resultData, setResultData] = React.useState(null);
	const params = useParams()
	//Merge a list of condition with one operator at the end
	const mergeConditions = (list, operator) => {
		var result = [];
		try {

			for (var i = 0; i < list.length; i++) {
				var elem = list[i] ? JSON.parse(list[i]) : list[i];
				if (elem)
					result.push(elem);
			}

			//nothing to merge
			if (result.length == 0)
				result = "";
			else if (result.length == 1 && Array.isArray(result[0]))
				result = JSON.stringify(result[0], null, 2);
			else
				result = JSON.stringify([...result, operator], null, 2);
			return result;
		} catch (e) {

		}
		return t("condition.error");
	}
	//return the validation info
	const validateForm = React.useCallback((values) => {
		//Flat list of fields, this will be used when needing previous fields in a condition value
		var currentCheckpointConditions = "";
		var fields = values.form.map((section, sectionIndex) => {
			if (section.checkpoint) {
				currentCheckpointConditions = mergeConditions([currentCheckpointConditions, section.condition], "&")
				return [];
			}
			return section.fields.map(field => (field.isGroup ?
				(field.fields || []).map((fieldi) => ({
					name: fieldi.name,
					condition: mergeConditions([currentCheckpointConditions, section.condition, field.condition], "&"),
					label: fieldi.label,
				}))
				:
				{
					name: field.name,
					condition: mergeConditions([currentCheckpointConditions, section.condition, field.condition], "&"),
					label: field.label,
				}))
		}).flat().flat().reduce((acc, cur) => {
			if (!acc[cur.name])
				acc[cur.name] = cur;
			else {
				acc[cur.name].condition = mergeConditions([acc[cur.name].condition, cur.condition], "|");
			}
			console.log(cur, acc);
			return acc;
		}, {});

		var newRequiredFieldsStatus = [];
		Object.keys(requiredFields).forEach((requiredFieldName) => {
			var field = fields[requiredFieldName];
			if (fields[requiredFieldName]) {
				//present in the form
				console.log(field.name, field.condition);
				if (!field.condition) {
					newRequiredFieldsStatus.push({
						...field,
						status: "complete"
					})
				} else {
					newRequiredFieldsStatus.push({
						...field,
						status: "with_condition",
					})
				}
			} else {
				newRequiredFieldsStatus.push({
					name: requiredFieldName,
					status: "absent",
				})
			}
		})
		setRequiredFieldStatus(newRequiredFieldsStatus);
	}, [locales, formikBag.values.form]);

	React.useEffect(() => {
		if (checkingStatus) {
			validateForm(formikBag.values);
		}
	}, [JSON.stringify(formikBag.values.form)])
	React.useEffect(() => {
		if (checkingStatus)
			formikBag.handleChange = (values) => {
				console.log(values);
				validateForm(values);
			}
		else
			formikBag.handleChange = null;
	}, [checkingStatus]);

	return <>
		<Box sx={{
			flex: 1,
			display: "flex",
			justifyContent: "center",
			flexDirection: "column",
			...sx
		}}>
			
			{
				["default",...locales].map(locale => <ButtonBase sx={{
					padding: 1,
					margin: 1,
					textDecoration: formikBag.values.currentLocale == locale ? "underline" : "none",
					fontWeight: formikBag.values.currentLocale == locale ? "bold" : "normal",
					backgroundColor: formikBag.values.currentLocale == locale ? "background.default" : "background.paper",
				}} onClick={() => {
					formikBag.setFieldValue("currentLocale", locale);
				}} key={locale}>{t("locales." + locale + ".label")}</ButtonBase>)
			}
		</Box>
		<Grid container sx={{
			marginTop: "auto", padding: 1
		}}>

			{checkingStatus && <Grid item xs={12}>
				{requiredFieldsStatus.length > 0 && <Divider light />}
				<List dense={true}>
					{requiredFieldsStatus.map((field, i) => (
						<ListItem key={field.name} sx={{
							backgroundColor: i == expandedItem && field.condition ? "background.default" : undefined,
						}}>
							<ListItemButton onClick={() => {
								if (expandedItem == i) setExpandedItem(-1);
								else setExpandedItem(i);
							}}>
								<ListItemText
									primary={field.name}
									secondary={i == expandedItem && field.condition ? <>
										<Typography variant={"body2"} sx={{ fontWeight: "bold" }}>{t("form.presentIf")}</Typography>
										<pre>{field.condition}</pre>
									</> : null}
									sx={{
										color: field.status == "absent" ? "error.main" : (
											field.status == "with_condition" ? "warning.main" : "success.main"
										)
									}} />
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</Grid>}
			<Grid item xs={12} style={{display:"flex",justifyContent:"flex-end"}}>
				<SplitButton
					fullWidth={true}
					variant={"contained"}
					color={"primary"}
					sx={{
						maxWidth: 200
					}}
					options={[
						{
							label: t("form.submit.cta"),
							action: () => {
								var doSubmit = ()=>{
									setCheckingStatus(false);
									console.log(formikBag.touched);
									formikBag.setFieldTouched("name",true);
									formikBag.setFieldValue("keepId",true).then(ok=>formikBag.submitForm())
								}
								if(formikBag.values.activatedOnce) {
									props.dialogForm({
										label: t("users.actions.activatedOnce.title"),
										text: t("users.actions.activatedOnce.text"),
									}).then(doSubmit)
								} else
									doSubmit();
								
							},
							active: formikBag.isSubmitting || formLoading ? false : true,
						},
						{
							label: t("form.submitAsNew.cta"),
							action: () => {
								setCheckingStatus(false);
								formikBag.setFieldTouched("name",true);
								formikBag.setFieldValue("keepId",false).then(ok=>formikBag.submitForm())
							},
							active: formikBag.isSubmitting || formLoading ? false : true,
						},
						{
							label: t("form.test.cta"),
							action: () => {
								setCheckingStatus(false);
								setTestForm(formikBag.values.form);
							},
							active: true,
						},
						{

							label: t("form.stateOfRequiredFields"),
							active: !formLoading || formikBag.isSubmitting,
							action: () => {
								setCheckingStatus(!checkingStatus);
								validateForm(formikBag.values);
							}
						},
						{

							label: Object.keys(formikBag.touched).length > 0 ? t("form.saveBeforeActivate.cta") : t("form.activate.cta"),
							active: formikBag.isSubmitting || !formikBag.values.id || formikBag.values.status == "ACTIVE" || formLoading || Object.keys(formikBag.touched).length > 0 ? false : true,
							action: () => {
								setFormLoading(true);
								activateForm(formikBag.values.id).then((ok) => {
									updateForms().finally(ok => setFormLoading(false));
								});
								setCheckingStatus(false);
							}
						},
						{
							label: t("form.cancel.cta"),
							action: () => {
								var savedForm = localStorage.getItem(params.id);
								setCheckingStatus(false);
								if(savedForm) {
									savedForm = JSON.parse(savedForm);
									console.log("restore to ",savedForm);
									formikBag.setFieldValue("form",savedForm)
										.then(ok=>formikBag.setFieldValue("keepId",true)
											.then(ok=>formikBag.submitForm())
										);
								}
									
							},
							active: localStorage.getItem(params.id) ? true : false,
						},
					]}
				/>
			</Grid>
		</Grid>
		<Dialog fullWidth maxWidth={"lg"} open={testForm ? true : false} onClose={() => setTestForm(null)}>
			<Grid container>
				<Grid item xs>
					<CollectForm
						
						currentSection={0}
						answers={null}
						form={testForm}
						onSubmit={(values, { setSubmitting }) => {
							console.log(values);
							setTestForm(null);
							setResultData(values.answers);
						}}
						onChange={(values) => {
							setResultData(values.answers)
						}}
						sx={{
							minWidth: 500,
							height: "80vh"
						}}
					/>
				</Grid>
				<Grid item xs>
					<Typography sx={{
						padding: 2,
						paddingBottom: 1
					}} variant={"h6"}>{t("form.state")}</Typography>
					<Box sx={{
						minWidth: 500,
						maxHeight: "80vh",
						overflow: "auto",
						padding: 2
					}}>
						<Box sx={{
							backgroundColor: "background.default",
							padding: 2,
							borderColor: "grey.200",
							borderWidth: 1,
							borderStyle: "solid"
						}}>
							<pre>{resultData && JSON.stringify(resultData, null, 2)}</pre>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Dialog>
	</>;
});

export default SubmitForm;