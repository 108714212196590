import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../actions/server';
import UIActions from "../../../../actions/ui";
import { Autocomplete, Chip, Popover, MenuItem, Box, Button, Container, Grid, TextField, LinearProgress, Typography, useMediaQuery } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import DataTable from '../../../globals/DataTable';
import { DateTime } from 'luxon';
import Fields from '../../../globals/Fields';
import selector from '../../../../selectors';
import NotAdminHeader from '../../../globals/notAdminHeader';

/**
 * Non utilise c est juste un template
 */
export default connect(
  (state, ownProps) => {
    return {
      //STATE TO PROPS
      ///////////////////////////////////////////////////////////////////
      collects: state.server.collects,
      loaded:state.server.loaded.collects,
      locales: selector.localesOptions(state),
      collectsStatusOptions:selector.collectsStatusOptions(state),
      currentLocale: state.server.labels.currentLocale,
      me: state.server.me
    }
  },
  (dispatch, props) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions,
    ...UIActions
  }, dispatch)
)(function (props) {
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const { createCollect,updateCollectForm,updateCollectAnswers,getCollect,loaded=true, dialogForm,updateCollectName,collectsStatusOptions, collects = [], getMyCollects, locales, currentLocale, t, logout, setCurrentLocale, me } = props;
  const bigScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));
  

  const navigate = useNavigate();
  React.useEffect(() => {
    getMyCollects()
  }, [])

  return <>
    <NotAdminHeader />
    <Grid flexDirection={bigScreen ? "row":"column-reverse"} container sx={{ p: bigScreen ? 2 :0}} >
      <Grid item xs={12} md={8} >
        <DataTable
          rows={collects}
          updateRows={getMyCollects}
          loaded={loaded}
          route={(id) => "/collects/" + id}
          filters={{
            initialValues: {
              "date": [null, null],
              "status": "",
              "today": false,
              "search": ""
            },
            action: (rows, values) => {
              var filtered = rows.filter((rowi) => {
                var accept = true;
                var rowDate = rowi.timeLastUpdate ? DateTime.fromISO(rowi.timeLastUpdate) : null;
                if (!bigScreen && values.today) {
                  var today = DateTime.local().hasSame(rowDate, "day");
                  if(!today) return false;
                }

                //date min
                if (values.date[0] && rowDate && rowDate < DateTime.fromISO(values.date[0]))
                  accept = false;
                //date max
                if (values.date[1] && rowDate && rowDate > DateTime.fromISO(values.date[1]))
                  accept = false;

                //status
                if(me && me.role=="COLLECTOR") {
                  if (bigScreen && values.status && ((values.status !=="VALIDATED" && rowi.status !== values.status)||(values.status ==="VALIDATED" && rowi.status !== "VALIDATED" && rowi.status !== "DUMPED") ))
                      return false;
                }else {
                  if (bigScreen && values.status && rowi.status !== values.status)
                    return false;
                }

                //search
                if (values.search && rowi.name.indexOf(values.search) < 0 && (!rowi.timeLastUpdate || rowi.timeLastUpdate.indexOf(values.search) < 0))
                  accept = false;
                return accept;
              });
              return filtered;
            },
            fields: bigScreen ?
              [
                {
                  component: Fields.Select,
                  wrapperProps:{
                    width:200
                  },
                  props: {
                    name: "status",
                    label: t("status"),
                    fullWidth: true,
                    options:collectsStatusOptions
                  }
                },
                {
                  component: Fields.Date,
                  props: {
                    name: "date",
                    label: [t("date.from"), t("date.to")],
                    fullWidth: true,
                  }
                },
                {
                  component: Fields.Text,
                  props: {
                    name: "search",
                    fullWidth: true,
                    label: t("form.search.label"),
                    variant: "outlined"
                  }
                }
              ] :
              [
                {
                  component:Fields.Switch,
                  props:{
                    name:"today",
                    label:t("today"),
                    labelPlacement:"top"
                  },
                  wrapperProps:{}
                },
                {
                  component: Fields.Text,
                  props: {
                    name: "search",
                    fullWidth: true,
                    label: t("form.search.label"),
                    variant: "outlined"
                  }
                }
              ]
          }}
          actions={true ? [
            {
              active: (rows) => rows.filter(row=>row.status=="STARTED").length ==1 ? true : false,
              action: (rows) => () => { 
                var row = rows.filter(row=>row.status=="STARTED")[0];
                dialogForm({
                  label:t("collects.action.rename.cta"),
                  text:t("collects.action.rename.text"),
                  initialValues:{name:row.name,id:row.id},
                  fields: [
                    {
                      component:Fields.Text,
                      props:{
                        name:"name",
                        label:t("collect.name.label"),
                        fullWidth:true,
                        required:true
                      }
                    }],
                  action:(values)=>{
                    return updateCollectName(values).then(ok => {getMyCollects();})
                  }
                })
                
              },
              label: (rows) => t("collects.actions.rename.cta")
            },
            {
              active: (rows) => rows.length==1,
              action: (rows) => (setLoading) => { 
                
                var row = rows[0];
                try {
                  var date = DateTime.now().setLocale(currentLocale).toISODate()
                } catch (e) {
                  var date = DateTime.now().toISODate()
                }
                var name = date + " - " + t("collects.emptyCollectName");
                console.log(row);
                dialogForm({
                  label:t("collects.action.duplicate.cta"),
                  text:t("collects.action.duplicate.text"),
                  initialValues:{name},
                  fields: [
                    {
                      component:Fields.Text,
                      props:{
                        name:"name",
                        label:t("collect.name.label"),
                        fullWidth:true,
                        required:true
                      }
                    }],
                  action:({name})=>{
                    setLoading(true);
                    return getCollect(row.id).then(collect=>{
                      if(collect.data && collect.data.answers)
                        console.log("collect",collect.data);
                        createCollect({ name}).then(ok => {
                          console.log("ok",ok);
                          updateCollectForm({
                            id:ok.data.id,
                            formId:collect.data.form.id
                          }).catch((e)=>{
                            console.log("cant change collect form");
                          });
                          updateCollectAnswers({
                            id:ok.data.id,
                            fields:collect.data.answers.fields,
                            content:collect.data.answers.content
                          }).then(success=>{
                            setLoading(false);
                            navigate("/collects/" + ok.data.id);
                          }).catch(e=>{
                            setLoading(false);
                            navigate("/collects/" + ok.data.id);
                          })
                        }).catch(()=>{
                          setLoading(false);
                        });
                    }).catch(()=>{
                      setLoading(false);
                    });
                  }
                })
                
              },
              label: (rows) => t("collects.actions.duplicate.cta")
            }
          ]:[]}
          columns={[
            {
              id: "date",
              label: t("collects.date"),
              disablePadding:!bigScreen,
              cellDataGetter: (row) => DateTime.fromISO(row.timeLastUpdate).setLocale("fr").toISODate()
            }, {
              id: "name",
              disablePadding:!bigScreen,
              label: t("collects.name"),
              cellDataGetter: (row) => row.name
            },
            {
              id: "uploadCount",
              disablePadding:!bigScreen,
              label: t("collects.uploadCount"),
              cellDataGetter: (row) => row.uploadCount
            },
            {
              id: "status",
              disablePadding:!bigScreen,
              label: t("collects.status"),
              cellDataValue: row => row.status,
              cellDataGetter: (row) => {
                return <Grid container spacing={1} alignItems={"center"}>
                  {row.status == "STARTED" && <Grid item><Chip label={t("collects.status.STARTED")} color={"default"} variant={"outlined"} size={bigScreen ? "medium":"small"}/></Grid>}

                  {row.status == "STARTED" && <Grid item><Chip label={row.formValid ? t("collects.formValid") : t("collects.formNotValid")} color={"default"} variant={row.formValid ? "contained" : "outlined"} size={bigScreen ? "medium":"small"}/></Grid>}

                  {row.status == "SENT" && <Grid item><Chip label={t("collects.status.SENT")} color={"default"} variant={"contained"} /></Grid>}
                  {(row.status == "VALIDATED" || row.status == "DUMPED") && <Grid item><Chip label={t("collects.status.VALIDATED")} color={"success"} variant={"contained"} /></Grid>}
                  {row.status == "REFUSED" && <Grid item><Chip label={t("collects.status.REFUSED")} color={"error"} variant={"outlined"} size={bigScreen ? "medium":"small"}/></Grid>}
                </Grid>
              
              }
            }
          ]}
        />
      </Grid>
      <Grid item xs={12} md={4} sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <Button
          variant={"outlined"}
          onClick={() => {
            try {
              var date = DateTime.now().setLocale(currentLocale).toISODate()
            } catch (e) {
              var date = DateTime.now().toISODate()
            }
            var name = date + " - " + t("collects.emptyCollectName");

            dialogForm({
              label:t("collects.action.new.cta"),
              text:t("collects.action.new.text"),
              initialValues:{name},
              fields: [
                {
                  component:Fields.Text,
                  props:{
                    name:"name",
                    label:t("collect.name.label"),
                    fullWidth:true,
                    required:true
                  }
                }],
              action:({name})=>{
                return createCollect({ name}).then(ok => {
                  console.log(ok);
                  navigate("/collects/" + ok.data.id);
                })
              }
            })
          }}
          sx={{
            padding: 3,
            marginTop:3,
            marginBottom:3
          }}
        >
          {t("collects.action.new.cta")}
        </Button>
      </Grid>
    </Grid>
  </>
});
