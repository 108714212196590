import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../actions/server';
import CollectList from '../collectList';
/**
 * Non utilise c est juste un template
 */
export default connect(
  (state) => {
    return {
      //STATE TO PROPS
      ///////////////////////////////////////////////////////////////////
      collects: state.server.collects,
      loaded:state.server.loaded.collects
    }
  },
  (dispatch) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions
  }, dispatch)
)(function (props) {
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const { getCollects, loaded,collects = [] } = props;

  return <CollectList loaded={loaded} collects={collects} updateCollects={getCollects}/>
  });
