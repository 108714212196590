import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Select, FormControl, Grid, InputLabel, NativeSelect, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import useWindowSize from '../useWindowSize';
import { Field, useFormikContext } from 'formik';
import ErrorMessage from '../ErrorMessage';
import RichText from './RichText';
import Radio from "./Radio";
export default connect(
	/* ownProps : {
		visible:boolean,
		name,
		required,
		options:[
			{
				value,
				label
			}
		],
		defaultValue:value,
		value,
		name
	}
	 }*/
	(state, ownProps) => {
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////
			currentLocale: state.server.labels.currentLocale
		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////

	}, dispatch)
)(function (props) {
	//COMPONENT
	///////////////////////////////////////////////////////////////////
	const { currentLocale, explanation, label, fields = [], variant = "outlined", sx = {}, options = [], ...inputProps } = props;
	const formikBag = useFormikContext();
	//console.log("radio disabled",props.disabled||!formikBag.values.editing);
	return <>
		<Typography variant={"h4"}>{label}</Typography>

		<RichText value={explanation} currentLocale={currentLocale} />
		<Grid container spacing={2}>
			{fields.map(({ name, options, label, ...otherFieldProps }) => {
				//Process options depending of currentLocale
				var optionProcessed = {};
				if (options) {
					optionProcessed.options = options.map(option => ({
						...option,
						label: typeof option.label === 'object' ? (option.label[currentLocale]||option.label["default"] || option.value) : (option.label || option.value)
					}));
				}
				//console.log(name,optionProcessed,options);
				//Process label depending of currentLocale
				var labelProcessed = {}
				if (label) {
					labelProcessed.label = typeof label === 'object' ? (label[currentLocale] || label["default"] || name) : (label || name)
				}
				return <Grid key={name} item xs={12} md={12}>
					<Radio name={"answers."+name} {...optionProcessed} {...labelProcessed} {...otherFieldProps} disabled={props.disabled||!formikBag.values.editing || !formikBag.values.canEdit}/>
				</Grid>
			})}
		</Grid>

	</>
});