import EVENT from "../events";
import produce from "immer";
import { getFileKey } from "../actions/server";

const server = produce((draft, action) => {
	//console.log(action);
	if (action.payload) {
		var { id, file, files, status, uploading, uploadingThumb, uploadingController, uploadingStatus,uploadingToken } = action.payload;

		if(!id && action.request) id = action.request.id;
		if(!file && action.request) file = action.request.file;

		if (file)
			var fileKey = getFileKey(file);
		if (files) {
			var fileKeys = files.map(getFileKey);
		}
	}
	//console.log("nouvelle action,  main token != token ?",draft.GLOBALS.token!=draft.GLOBALS.main_token,action);
	switch (action.type) {
		//GLOBALES
		case EVENT.SERVER.SET_GLOBALS.SUCCESS:
			//console.log("setGlobals",action.payload);
			draft.GLOBALS = {...draft.GLOBALS,...action.payload};
			break;
		case EVENT.SERVER.EXORCISE.SUCCESS:
			draft.GLOBALS.token = draft.GLOBALS.main_token;
			//exorcise : token back to main token
			localStorage.setItem('token', draft.GLOBALS.main_token);
			draft.collects=[];
			draft.collectDetail={};
			draft.loaded.collects=false;
			break;
		case EVENT.SERVER.POSSESSION.SUCCESS:
			//possession : token = new token
			localStorage.setItem('token', action.payload.token);
			draft.GLOBALS.token = action.payload.token;
			draft.collects=[];
			draft.collectDetail={};
			draft.loaded.collects=false;
			break;
		case EVENT.SERVER.GET_LOCALES.SUCCESS:
		case EVENT.SERVER.EDIT_LOCALES.SUCCESS:
			draft.locales = action.payload;
			draft.loaded.locales = true;
			break;
		case EVENT.SERVER.SET_CURRENT_LOCALE.SUCCESS:
			draft.labels.currentLocale = action.payload;
			draft.labels.v++;
			draft.loaded.labels = true;
			break;
		//USERS
		case EVENT.SERVER.GET_USERS.SUCCESS:
			draft.users.data = action.payload.users;
			draft.users.v++;
			draft.loaded.users = true;
			break;
		case EVENT.SERVER.GET_USERS_OF_COMPANY.SUCCESS:
			console.log(action.payload);
			if (draft.me.role == "SUPERVISOR") {
				draft.users.data = action.payload.users;
				draft.users.v++;
				draft.loaded.users = true;
			}
			break;

		//AUTH
		case EVENT.SERVER.LOGIN.SUCCESS:
			draft.GLOBALS.token = action.payload.token;
			localStorage.setItem('token', action.payload.token);
			localStorage.setItem('main_token', action.payload.token);
			console.log("setTokenOk");
			break;

		case EVENT.SERVER.LOGOUT.SUCCESS:
			draft.companies = [];
			draft.collects = [];
			draft.users.data = [];
			draft.users.v++;
			//draft.labels.currentLocale=null;
			delete draft.GLOBALS.token;
			draft.me = null;
			localStorage.removeItem("token");
			localStorage.removeItem("main_token");
			draft.loaded = {
				...draft.loaded,
				users:false,
				collects:false,
				companies:false
			};
			break;

		case EVENT.SERVER.ME.SUCCESS:
			draft.me = action.payload;
			//if (draft.labels.currentLocale === null)
			draft.labels.currentLocale = action.payload.locale;
			
			//si collector c est le seul user visible
			if (action.payload.role == "COLLECTOR") {
				draft.users.data = [action.payload];
				draft.users.v++;
				draft.loaded.users = true;
			}
			break;

		//COMPANIES
		case EVENT.SERVER.CREATE_COMPANY.SUCCESS:
			break;
		case EVENT.SERVER.GET_COMPANIES.SUCCESS:
			draft.companies = action.payload;
			draft.loaded.companies = true;
			break;

		//COLLECTS
		case EVENT.SERVER.GET_ALL_COLLECTS.SUCCESS:
			draft.collects = action.payload;
			draft.loaded.collects = true;
			break;
		case EVENT.SERVER.GET_MY_COLLECTS.SUCCESS:
			draft.collects = action.payload;
			draft.loaded.collects = true;
			break;

		case EVENT.SERVER.GET_COLLECTS_OF_COMPANY.SUCCESS:
			if (draft.me.role == "SUPERVISOR") {
				draft.collects = action.payload;
				draft.loaded.collects = true;
				//draft.collects.v++;
			}
	

		case EVENT.SERVER.SET_UPLOADING.SUCCESS:
			//what are we updating
			if (!draft.uploadingByCollect[id]) {
				draft.uploadingByCollect[id] = {
					majorUpdateVersion:0,
					uploading: uploading || [],
					uploadingThumb: uploadingThumb || {},
					uploadingController: uploadingController || {},
					uploadingStatus: uploadingStatus || {},
					uploadingToken:uploadingToken || {}
				}
			} else {
				if (uploading) draft.uploadingByCollect[id].uploading = uploading;
				if (uploadingThumb) draft.uploadingByCollect[id].uploadingThumb = uploadingThumb;
				if (uploadingController) draft.uploadingByCollect[id].uploadingController = uploadingController;
				if (uploadingToken) draft.uploadingByCollect[id].uploadingToken = uploadingToken;
				if (uploadingStatus) draft.uploadingByCollect[id].uploadingStatus = uploadingStatus;
			}
			if (action.resolve) action.resolve();
			//console.log(JSON.parse(JSON.stringify(draft.uploadingByCollect[id])), action.payload);
			break;

		case EVENT.SERVER.CANCEL_UPLOAD.SUCCESS:
			
			if (draft.uploadingByCollect[id]) {
				var keys = [];
				if (fileKey)
					keys.push(fileKey);
				if (fileKeys)
					keys = [...keys, ...fileKeys];
				
				for (var i = 0; i < keys.length; i++) {
					var fileKeyi = keys[i];
					console.log(fileKeyi,draft.uploadingByCollect[id].uploadingController[fileKeyi])
					//abort upload
					if (draft.uploadingByCollect[id].uploadingController[fileKeyi])
						draft.uploadingByCollect[id].uploadingController[fileKeyi].abort();

					//delete uploading file if here
					delete draft.uploadingByCollect[id].uploadingController[fileKeyi];
					delete draft.uploadingByCollect[id].uploadingToken[fileKeyi];
					if (!action.payload.keepThumbs) {
						delete draft.uploadingByCollect[id].uploadingThumb[fileKeyi];
					} else
						console.log("keep thumb", fileKeyi);

					delete draft.uploadingByCollect[id].uploadingStatus[fileKeyi];
					var fileIndex = draft.uploadingByCollect[id].uploading.findIndex(upload => getFileKey(upload) == fileKeyi);
					if (fileIndex > -1) {
						draft.uploadingByCollect[id].uploading.splice(fileIndex, 1);
					}
				}
			}
			if (action.resolve) action.resolve();
			break;
		
		case EVENT.SERVER.GET_UPLOAD_STATUS.SUCCESS:
		case EVENT.SERVER.UPDATE_UPLOADING_STATUS.SUCCESS:
			if(status=="SUCCESS"){
				delete draft.uploadingByCollect[id].uploadingStatus[fileKey];
				delete draft.uploadingByCollect[id].uploadingThumb[fileKey];
				delete draft.uploadingByCollect[id].uploadingController[fileKey];
				delete draft.uploadingByCollect[id].uploadingToken[fileKey];
				var fileIndex = draft.uploadingByCollect[id].uploading.findIndex(upload => getFileKey(upload) == fileKey);
				if (fileIndex > -1) {
					delete draft.uploadingByCollect[id].uploading.splice(fileIndex, 1);
				}
			} else if (draft.uploadingByCollect[id]) {
				draft.uploadingByCollect[id].uploadingStatus[fileKey] = action.payload;
				delete draft.uploadingByCollect[id].uploadingController[fileKey];
				//dont want to delete this token yet
				//delete draft.uploadingByCollect[id].uploadingToken[fileKey];
			}
			if (action.resolve) action.resolve();
			break;
		case EVENT.SERVER.UPDATE_UPLOAD_VERSION.SUCCESS:
			console.log("UPDATE VERSION !!!!!");
			if(draft.uploadingByCollect[id])
				draft.uploadingByCollect[id].majorUpdateVersion++;
			break;
		case EVENT.SERVER.SEND_COLLECT.SUCCESS:
		case EVENT.SERVER.GET_COLLECT.SUCCESS:
		case EVENT.SERVER.VALIDATE_COLLECT.SUCCESS:
		case EVENT.SERVER.DUMP_COLLECT.SUCCESS:
		case EVENT.SERVER.REFUSE_COLLECT.SUCCESS:
			draft.collectDetail[action.payload.id] =  action.payload;
			break;

		//Too much data stored, let each page loads its stuff
		/*
		case EVENT.SERVER.GET_COMMENTS_OF_COLLECT.SUCCESS:
			if (!draft.collectDetail[action.request.id])
				draft.collectDetail[action.request.id] = {
					data: {},
					comments: []
				}
			else
				draft.collectDetail[action.request.id].data = action.payload;
			break;
		*/
		case EVENT.SERVER.EDIT_TRANSLATIONS.SUCCESS:
		case EVENT.SERVER.GET_TRANSLATIONS.SUCCESS:
			draft.labels.v++
			draft.labels.data = JSON.parse(action.payload.contentJson) || {}
			draft.loaded.labels = true;
			//console.log(draft.labels.currentLocale,draft.labels.data);

			break;

		default:
			break;
	}
	if (draft.loaded.locales && (!draft.GLOBALS.token || (draft.GLOBALS.token && draft.me && draft.me.role)) && draft.loaded.labels)
		draft.loaded.app = true;
	else
		draft.loaded.app = false;
},
	//INITIAL STATE
	{
		GLOBALS: {
			token: localStorage.getItem('token'),
			main_token : localStorage.getItem('main_token')
		},
		//list of string
		locales: [],
		//infos about me
		me: null,
		//list of companies if appropriate
		companies: [],
		//list of collects accessible by me
		collects: [],

		collectDetail:{},

		//keep uploading by collects : 
		/*
		{
			idCollect:{
				majorUpdateVersion:0,
				uploading:[files],
				uploadingThumb:{id:thumbData},
				uploadingController:{id:abortController},
				uploadingToken:{id:token}
				uploadingStatus:{id,status} appartient a ["STARTED","SUCCESS","FAIL"]
			}
		}
		*/
		uploadingByCollect: {},
		//list of users
		users: {
			data: [],
			v: 0
		},
		//list of labels + current locale
		labels: {
			v: 0,
			data: {},
			currentLocale: navigator.language.toLowerCase().substring(0,2)
		},
		//possible roles
		roles: ["COLLECTOR", "SUPERVISOR", "VALIDATOR", "ADMIN"],
		collectStatus:{
			STARTED:"STARTED", 
			SENT:"SENT", 
			VALIDATED:"VALIDATED", 
			REFUSED:"REFUSED", 
			DUMPED:"DUMPED"
		},
		// we are ready to display the app
		loaded: {
			collects:false,
			users:false,
			labels:false,
			companies:false,
			locales:false,
			app:false
		}
	}
);
export default server