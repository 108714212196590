import React from 'react';
import { Link, useNavigate, useParams, useResolvedPath } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../../actions/server';
import { Button, Container, Grid, TextField, LinearProgress, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import Fields from '../../../../globals/Fields';
import ErrorMessage from '../../../../globals/ErrorMessage';
import selector from "../../../../../selectors";
import SuccessMessage from '../../../../globals/SuccessMessage';
export default connect(
  (state, ownProps) => {
    return {
      //STATE TO PROPS
      ///////////////////////////////////////////////////////////////////
      users: selector.users(state),
      companies:state.server.companies,
      locales:state.server.locales
    }
  },
  (dispatch, props) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions
  }, dispatch)
)(function (props) {
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const {companies,editCompany, locales,t } = props;
  const [company, setCompany] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [success,setSuccess] = React.useState(null);
  let params = useParams();

  React.useEffect(() => {
    const newCompany = companies.find((company) => company.id == parseInt(params.id));
    setCompany(newCompany)
  }, [companies, params.id])

  if (!company) return null;
  return <Container maxWidth="xs" sx={{paddingTop:5}}>
    <Formik
      initialValues={{
        ...company
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        console.log(values);
        editCompany(values)
          .then((ok) => {
            setSuccess(t("form.information.submitSuccess"));
            setError(null);
          })
          .catch(e => {
            console.log("error", e);
            setSuccess(null);
            setError(e.response ? e.response.data.label : "global.unknown");
          })
          .finally(e => {
            setSubmitting(false);
          });
      }}
    //validationSchema={{}}
    >
      {(formikBag) => {
        return (
        <Form>
          <Grid container alignItems={"center"}>
            <Grid item container spacing={2}>
              {success && <Grid item xs={12}>
                <SuccessMessage timeout={3000}  reset={()=>setSuccess(null)}>
                  {success}
                </SuccessMessage>
              </Grid>}
              {error && <Grid item xs={12}>
                <ErrorMessage>
                  {t(error)}
                </ErrorMessage>
              </Grid>}
              <Grid item xs={12}>
                <Fields.Text required fullWidth name={"name"} label={t("name")} />
              </Grid>
              
              <Grid item xs={12}>
                <Fields.SelectLocale required fullWidth name={"localeDefault"}  label={t("localeDefault")} />
              </Grid>
              {formikBag.isSubmitting && <Grid item xs={12}><LinearProgress /></Grid>}
              <Grid item xs={12}>
                <Button type={"submit"} fullWidth variant={"contained"}>{t("general.go")}</Button>
              </Grid>

            </Grid>
          </Grid>
        </Form>
      )}}
    </Formik>
  </Container>
});
