//https://github.com/toomuchdesign/re-reselect/blob/master/examples/4-programmatic-keyselector-composition.md#keyselectorcombinerjs
export default function keySelectorCombiner({inputSelectors = []} = {}) {
    const keySelectors = inputSelectors
      .map(entry => entry && entry.keySelector ||null)
      .filter(keySelector => typeof keySelector === 'function');
  
    // The actual keySelector
    return (...args) => {
      return keySelectors
        .map(keySelector => keySelector(...args))
        .filter(value => {
          const valueType = typeof value;
          return valueType === 'string' || valueType === 'number';
        })
        .join(':');
    };
  };