import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import actions from '../../../../actions/server';
import UsersList from '../../admin/usersList';
import selector from '../../../../selectors';
/**
 * Non utilise c est juste un template
 */
export default connect(
  (state) => {
    return {
      //STATE TO PROPS
      ///////////////////////////////////////////////////////////////////
      users:selector.users(state),
      loaded:state.server.loaded.users,
      me:state.server.me
    }
  },
  (dispatch) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions
  }, dispatch)
)(function (props) {
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const {users, getUsersOfCompany, me,loaded} = props;

  return <UsersList users={users} loaded={loaded} updateUsers={()=>{
      if (me.companyId) {
        getUsersOfCompany(me.companyId);
      }
  }}/>
});
