import React from 'react';
import {Link,Outlet,useNavigate,useParams ,useResolvedPath,useMatch} from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import actions from '../../../../../actions/server';
import {Box, Button, Container, Grid, TextField,LinearProgress, Typography, Tabs,Tab } from '@mui/material';
import { Formik, Form, Field} from 'formik';
import LinkTabs from '../../../../globals/LinkTabs';


export default connect(
  (state, ownProps) =>{return {
    //STATE TO PROPS
    ///////////////////////////////////////////////////////////////////
  }},
  (dispatch, props) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions
  }, dispatch)
)(function(props){
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const {t} = props;
  let params = useParams();
  return <Box sx={{
    backgroundColor:"background.paper",flex:1,
      display:"flex",
      flexDirection:"column",
      alignItems:"stretch"
  }}>
    <LinkTabs tabs={[
      [
        {route:"companies/"+params.id,end:true,getLabel:(active,params)=>t("company.statistics")},
        {route:"companies/"+params.id+"/statistics",end:true,getLabel:(active,params)=>""}
      ],
      [
        {route:"companies/"+params.id+"/form",end:true,getLabel:(active,params)=>t("company.form")}
      ],
      [
        {route:"companies/"+params.id+"/information",end:true,getLabel:(active,params)=>t("company.information")}
      ],
      [
        {route:"companies/"+params.id+"/collects",end:true,getLabel:(active,params)=>t("company.collects")}
      ],
      [
        {route:"companies/"+params.id+"/users",end:true,getLabel:(active,params)=>t("company.users")}
      ]
    ]}/>
    <Outlet/>
  </Box>
});
