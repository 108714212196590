import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import actions from '../../../../../actions/server';
import UsersList from '../../usersList';
/**
 * Non utilise c est juste un template
 */
export default connect(
  (state) => {
    return {
      //STATE TO PROPS
      ///////////////////////////////////////////////////////////////////
      
    }
  },
  (dispatch) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions
  }, dispatch)
)(function (props) {
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const { getUsersOfCompany, } = props;
  const [users,setUsers] = React.useState([]);
  const [loaded,setLoaded] = React.useState(false);

  const params = useParams();
  React.useEffect(()=>{
	  getUsersOfCompany(params.id).then(({data})=>{
      setUsers(data.users);
      setLoaded(true);
    });
  },[getUsersOfCompany,params.id,])

  return <UsersList loaded={loaded} users={users} updateUsers={()=>{
    getUsersOfCompany(params.id).then(({data})=>setUsers(data.users));
  }}/>
  });
