import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../../../actions/server';
import UIactions from '../../../../actions/ui';
import { Box, Button, Container, Grid, TextField, LinearProgress, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import DataTable from '../../../globals/DataTable';
import { DateTime } from 'luxon';
import Fields from '../../../globals/Fields';
/**
 * Non utilise c est juste un template
 */
export default connect(
  (state, ownProps) => {
    return {
      //STATE TO PROPS
      ///////////////////////////////////////////////////////////////////
      companies: state.server.companies,
      loaded:state.server.loaded.companies,
      locales: state.server.locales,
      currentLocale: state.server.labels.currentLocale,
      clipboard: state.ui.clipboard
    }
  },
  (dispatch, props) => bindActionCreators({
    //ACTIONS
    ///////////////////////////////////////////////////////////////////
    ...actions,
    ...UIactions
  }, dispatch)
)(function (props) {
  //COMPONENT
  ///////////////////////////////////////////////////////////////////
  const { createCompany, loaded,companies = [], locales, currentLocale, t, getActiveFormOfCompany, copyForm, clipboard,createFormForCompany } = props;
  const [copyingForm,setCopyingForm] = React.useState(false);
  const [pastingForm,setPastingForm] = React.useState(false);
  return <Box sx={{ p: 2 }}>

    <DataTable
      rows={companies}
      loaded={loaded}
      route={(id) => "/companies/" + id}
      filters={{
        initialValues: {
          "locale": "",
          "search": ""
        },
        action: (rows, values) => {
          //console.log(rows,values);
          return rows.filter((rowi) => {
            var accept = true;
            //locale
            if (values.locale && rowi.localeDefault && rowi.localeDefault !== values.locale)
              accept = false;
            //search
            if(values.search && JSON.stringify(rowi).toLowerCase().indexOf(values.search.toLowerCase())<0) 
              accept=false;
            return accept;
          })

        },
        fields: [{
          component: Fields.Autocomplete,
          props: {
            name: "locale",
            label: t("companies.localeDefault"),
            options: ["", ...locales],
            getOptionLabel: (option) => {
              if (option) {
                return t("locales." + option + ".label")
              } else
                return t("locales.all.label")
            },
            fullWidth: true
          }
        },
        {
          component: Fields.Text,
          props: {
            name: "search",
            fullWidth: true,
            label: t("form.search.label"),
            variant: "outlined"
          }
        }
        ]
      }}
      actions={[
        {
          active: (rows) => !copyingForm && rows.length == 1 ? true : false,
          action: (rows) => () => {
            setCopyingForm(true)
            getActiveFormOfCompany(rows[0].id).then((ok) => {
              console.log("copying",rows[0],ok,JSON.parse(ok.data.content));
              copyForm(JSON.parse(ok.data.content));
            }).finally(()=>setCopyingForm(false))
          },
          label: (rows) => t("companies.actions.copyForm.cta")
        },
        {
          active: (rows) => !pastingForm&& clipboard.form && rows.length == 1 ? true : false,
          action: (rows) => () => {
            setPastingForm(true);

            //Get company from ID
            var company = companies.find((c) => c.id == parseInt(rows[0].id)) || {};
            
            try {
              var name = DateTime.now().setLocale(company.localeDefault).toLocaleString();
            } catch (e) {
              var name = DateTime.now().toLocaleString();
            }
            console.log("pasting in",clipboard.form,{
              name,
              companyId: company.id,
              content: JSON.stringify(clipboard.form )
            })
            createFormForCompany({
              name,
              companyId: company.id,
              content: JSON.stringify(clipboard.form )
            }).finally((ok) => {
              setPastingForm(false);
            })
          },
          label: (rows) => t("companies.actions.replaceForm.cta")
        },
      ]}
      columns={[
       {
          id: "name",
          label: t("companies.name"),
          cellDataGetter: (row) => row.name
        }
      ]}
      newItem={{
        label: t("companies.new.cta"),
        action: createCompany,
        active: true,
        initialValues: {
          "name": "",
          "localeDefault": currentLocale,
          "date": DateTime.now()
        },
        fields: [{
          component: Fields.Text,
          props: {
            name: "name",
            label: t("companies.name"),
            autoFocus: true,
            fullWidth: true,
            required: true
          }
        },
        {
          component: Fields.SelectLocale,
          props: {
            name: "localeDefault",
            label: t("companies.localeDefault"),
            fullWidth: true,
            required: true
          }
        }/*,
      {
        component:Fields.Date,
        props:{
          name:"date",
          label:t("companies.date"),
          fullWidth:true,
          minDate:DateTime.now(),
          required:true
        }
      }*/
        ]
      }}
    />
  </Box>
});
