import {
	withRouter,
	Link,
	useNavigate
} from 'react-router-dom'
import {
	connect
} from 'react-redux'
import {
	bindActionCreators
} from 'redux';

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, TextField, Grid, Table, TableCell, Typography, TableSortLabel, Paper, TableContainer, TablePagination, TableBody, Checkbox, TableHead, TableRow, Box, Container, useMediaQuery, CircularProgress } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import SplitButton from './SplitButton';
import Fields from "./Fields";
import { Formik, Form, Field, useFormikContext } from 'formik';
import ErrorMessage from "../globals/ErrorMessage";
import * as Yup from 'yup';
import actions from '../../actions/server';
//Small helpers
function descendingComparator(a, b, dataGetter) {
	var valA = dataGetter(a);
	var valB = dataGetter(b);
	if (!Number(valA) || !Number(valB)) {
		valA = ("" + valA).toLowerCase()
		valB = ("" + valB).toLowerCase();
	}

	if (valA < valB) {
		return -1;
	}
	if (valA > valB) {
		return 1;
	}
	return 0;
}

const DataTableContent = connect(
	(state, ownProps) => {
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////
		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////

	}, dispatch)
)(function (props) {
	const { newItem, forceSelect = false, selected, setSelected, setProcessedActions, dense = false, filters, actions = [], route, getRowId = (row) => row.id, onClick = () => { }, columns, rows, editAction, onRowClick, setEditingAction, setEditingItem,updateRows=()=>{}, ...tableProps } = props;

	const formikBag = useFormikContext();
	//current inner states 
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState(columns.length ? columns[0].id : null);
	const [orderDataGetter, setOrderDataGetter] = React.useState(() => {
		if (typeof columns[0].cellDataValue == "function")
			return columns[0].cellDataValue;
		else if (typeof columns[0].cellDataGetter == "function")
			return columns[0].cellDataGetter;
		else
			return (rowi) => rowi[orderBy];
	});
	const [page, setPage] = React.useState(0);

	const [rowsPerPage, setRowsPerPage] = React.useState(25);
	// Avoid a layout jump when reaching the last page with empty rows.
	const [filteredRows, setFilteredRows] = React.useState(rows);
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0;

	const navigate = useNavigate();

	React.useEffect(() => {
		var newFilteredRows = filters.action(rows, formikBag.values);
		setFilteredRows(newFilteredRows);

		var newSelected = selected.map(selectedi=>rows.find(rowi=>getRowId(rowi)==getRowId(selectedi))).filter(rowi=>rowi);

		//update selected : reduce only from old selected
		newSelected = filters.action(newSelected, formikBag.values);

		if (forceSelect && JSON.stringify(newSelected) !== JSON.stringify(selected))
			setSelected(newSelected);
		else if (!forceSelect) {
			setSelected(newSelected);
		}
	}, [formikBag.values, rows])

	//update datatable if responsive change 
	const bigScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));
	React.useEffect(()=>{
		formikBag.setFieldValue("bigScreen",bigScreen);
	},[bigScreen])
	//console.log("render table",props);
	/*
	//to uncomment si pagination mais enquetter double appel
	React.useEffect(()=>{
		console.log("refresh rows");
		updateRows({page,rowsPerPage,orderBy,order});
	},[page,rowsPerPage,orderBy,order])
	*/
	//setFilterValues(formikBag.values);
	return <Form >
		<Paper sx={{ p: 2 }} elevation={0}>
			<Grid container spacing={2}>
				{filters.fields.map((fieldi, i) => {
					//console.log(fieldi.props);
					const { sx, ...otherProps } = fieldi.props;
					if (fieldi.wrapperProps)
						var wrapperProps = fieldi.wrapperProps;
					else
						var wrapperProps = {
							xs:true
						};
					return <Grid item {...wrapperProps} key={i}>
						<fieldi.component {...otherProps} sx={typeof sx == "function" ? sx(formikBag.values) : sx} />
					</Grid>
				})}
			</Grid>

			<TableContainer>
				<Table
					sx={{
						width: "100%",
						tableLayout: "fixed",
						overflowWrap: "break-word"
					}}
					aria-labelledby="tableTitle"
					size={dense ? 'small' : 'medium'}
				>
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column.id}
									align={column.numeric ? 'right' : 'left'}
									padding={column.disablePadding ? 'none' : 'normal'}
									sortDirection={orderBy === column.id ? order : false}
								>
									<TableSortLabel
										active={orderBy === column.id}
										direction={orderBy === column.id ? order : 'asc'}
										onClick={(event) => {
											const isAsc = orderBy === column.id && order === 'asc';
											setOrder(isAsc ? 'desc' : 'asc');
											setOrderBy(column.id);
											setOrderDataGetter(() => {
												return column.cellDataValue ?
													column.cellDataValue
													:
													(
														column.cellDataGetter ?
															column.cellDataGetter
															:
															(rowi) => rowi[orderBy]
													);
											})
										}}
									>
										{column.label}
										{orderBy === column.id ? (
											<Box component="span" sx={visuallyHidden}>
												{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
											</Box>
										) : null}
									</TableSortLabel>
								</TableCell>
							))}
							{(actions.length > 0 || forceSelect) && <TableCell padding="checkbox">
								<Checkbox
									color="primary"
									indeterminate={selected.length > 0 && selected.length < filteredRows.length}
									checked={filteredRows.length > 0 && selected.length === filteredRows.length}
									onChange={(event) => {
										if (event.target.checked) {
											setSelected(filteredRows);
											return;
										}
										setSelected([]);
									}}
									inputProps={{
										'aria-label': 'select all desserts',
									}}
								/>
							</TableCell>}
						</TableRow>
					</TableHead>

					<TableBody>
						{[...filteredRows].sort((a, b) => {
							var toReturn = order === 'desc'
								? descendingComparator(a, b, orderDataGetter)
								: -descendingComparator(a, b, orderDataGetter);

							return toReturn;
						})
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row, index) => {
								const isItemSelected = selected.find(rowi => getRowId(rowi) == getRowId(row)) ? true : false;
								const labelId = `enhanced-table-checkbox-${index}`;
								const selectRow = (event) => {
									event.stopPropagation();
									event.preventDefault();
									const selectedIndex = selected.findIndex(selectedi=>getRowId(row)==getRowId(selectedi));
									let newSelected = [];

									if (selectedIndex === -1) {
										newSelected = newSelected.concat(selected, row);
									} else if (selectedIndex === 0) {
										newSelected = newSelected.concat(selected.slice(1));
									} else if (selectedIndex === selected.length - 1) {
										newSelected = newSelected.concat(selected.slice(0, -1));
									} else if (selectedIndex > 0) {
										newSelected = newSelected.concat(
											selected.slice(0, selectedIndex),
											selected.slice(selectedIndex + 1),
										);
									}
									setSelected(newSelected);
								}
								return (
									<TableRow
										hover
										component={route ? "a":undefined}
										href={route ? route(getRowId(row)):undefined}
										style={{
											textDecoration:"none"
										}}
										onClick={(event) => {
											if (route){
												navigate(route(getRowId(row)))
											} else if (onRowClick) {
												onRowClick(row)
											} else if (editAction) {
												setEditingAction(() => editAction);
												setEditingItem(row);
											} else
												selectRow(event)
										}}
										role="checkbox"
										aria-checked={isItemSelected}
										tabIndex={-1}
										key={"row" + getRowId(row)}
										selected={isItemSelected}
										sx={{
											cursor: "pointer"
										}}
									>
										{columns.map(({ cellDataGetter, id }, index) => {
											return (
												<TableCell
													key={getRowId(row) + "-" + id + " " + page}
													component="th"
													scope="row"
												>
													{cellDataGetter(row)}
												</TableCell>
											);
										})}
										{(actions.length > 0 || forceSelect) && <TableCell padding="checkbox">
											<Checkbox
												color="primary"
												onClick={selectRow}
												checked={isItemSelected}
												inputProps={{
													'aria-labelledby': labelId,
												}}
											/>
										</TableCell>}
									</TableRow>
								);
							})}
						{emptyRows > 0 && (
							<TableRow
								style={{
									height: (dense ? 33 : 53) * emptyRows,
								}}
							>
								<TableCell colSpan={columns.length} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={filteredRows.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={(event, newPage) => {
					setPage(newPage);
				}}
				onRowsPerPageChange={(event) => {
					setRowsPerPage(parseInt(event.target.value, 10));
					setPage(0);
				}}
			/>
		</Paper>
	</Form>
})



const DataTable = connect(
	(state, ownProps) => {
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////

		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////
		t: actions.t
	}, dispatch)
)(function (props) {
	const {
		selected, setSelected,loaded=true,
		newItem, filters, actions = [], onRowClick = undefined, route, getRowId = (row) => row.id, onClick = () => { }, columns, rows, t, editAction, ...tableProps } = props;

	//array of selected rows
	const [localSelected, setLocalSelected] = React.useState([]);
	
	//New item
	const [editingItem, setEditingItem] = React.useState(null);
	const [editingAction, setEditingAction] = React.useState(() => newItem ? newItem.action : undefined);
	const [editingItemErrors, setEditingItemErrors] = React.useState(null);

	//actions
	const [processedActions, setProcessedActions] = React.useState([]);
	React.useEffect(() => {
		//console.log("selected changed",selected,localSelected);
		var newProcessedActions = actions.map(({ active, action, label }) => {
			return {
				active: active(selected ? selected : localSelected),
				action: action(selected ? selected : localSelected),
				label: label(selected ? selected : localSelected)
			};
		})
		setProcessedActions(newProcessedActions);
	}, [selected, localSelected])
	
	if(!loaded) return <Grid container sx={{flex:1,minHeight:300,width:"100%"}} alignItems={"center"} justifyContent={"center"}>
		<Grid item>
			<CircularProgress/>
		</Grid>
	</Grid>;
	return (<>
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Formik
					initialValues={filters.initialValues}
					validationSchema={Yup.object(filters.validationSchema || {})}
				>
					{(formikBag) => <DataTableContent
						forceSelect={setSelected ? true : false}
						selected={selected ? selected : localSelected}
						setSelected={setSelected ? setSelected : setLocalSelected}
						setProcessedActions={setProcessedActions}
						setEditingItem={setEditingItem}
						setEditingAction={setEditingAction}
						{...props} />}
				</Formik>
			</Grid>
			{newItem && <Grid item>
				<Button variant={"outlined"} onClick={() => {
					setEditingItem(newItem.initialValues)
					setEditingAction(() => newItem.action)
				}}>{newItem.label}</Button>
				<Dialog open={editingItem ? true : false} onClose={() => setEditingItem(null)}>
					{editingItem && <Formik
						enableReinitialize={true}
						initialValues={editingItem}
						onSubmit={(values, { setSubmitting }) => {
							console.log(values);
							setSubmitting(true);
							if (editingAction)
								return editingAction(values)
									.then((ok) => {
										setEditingItemErrors(null);
										setEditingItem(null);
										setEditingAction(undefined);
									})
									.catch(e => {
										console.log("error", e);
										setEditingItemErrors(e.response ? e.response.data.label : "global.unknown");
									})
									.finally(e => {
										setSubmitting(false);
									});
						}}
						validationSchema={Yup.object(newItem.validationSchema || {})}
					>
						{(formikBag) => (
							<Form>
								<DialogTitle>{newItem.label}</DialogTitle>
								<DialogContent>
									<Grid container spacing={2}>
										<Grid item xs={12}><ErrorMessage>{editingItemErrors}</ErrorMessage></Grid>
										{newItem.fields.map((fieldi, i) => {
											return <Grid item xs={12} key={i} {
												...(fieldi.wrapperProps ? {
													...fieldi.wrapperProps,
													sx: typeof fieldi.wrapperProps.sx == "function" ? fieldi.wrapperProps.sx(formikBag.values) : fieldi.wrapperProps.sx
												} : {})
											}>
												<fieldi.component {...{
													...fieldi.props,
													sx: typeof fieldi.props.sx == "function" ? fieldi.props.sx(formikBag.values) : fieldi.props.sx
												}} />
											</Grid>
										})}
									</Grid>
								</DialogContent>
								<DialogActions>
									<Button variant={"outlined"} onClick={() => setEditingItem(null)}>{t("general.cancel")}</Button>
									<Button type={"submit"} variant={"contained"} color={"primary"}>{t("general.go")}</Button>
								</DialogActions>
							</Form>
						)}
					</Formik>}
				</Dialog>
			</Grid>}
			<Grid item xs></Grid>
			<Grid item>
				<SplitButton options={processedActions} />
			</Grid>
		</Grid>
	</>);
})

DataTable.propTypes = {
	selected: PropTypes.array,
	setSelected: PropTypes.func,
	newItem: PropTypes.shape({
		label: PropTypes.string.isRequired,
		action: PropTypes.func.isRequired,
		active: PropTypes.bool,
		initialValues: PropTypes.object,
		fields: PropTypes.arrayOf(PropTypes.shape({
			component: PropTypes.any,
			props: PropTypes.object
		}))
	}),
	filters: PropTypes.shape({
		action: PropTypes.func.isRequired,
		initialValues: PropTypes.object,
		fields: PropTypes.arrayOf(PropTypes.shape({
			component: PropTypes.any,
			props: PropTypes.object
		}))
	}),
	actions: PropTypes.arrayOf(PropTypes.shape({
		active: PropTypes.func.isRequired,
		action: PropTypes.func.isRequired,
		label: PropTypes.any.isRequired,
	})),
	onRowClick: PropTypes.func,
	route: PropTypes.func,
	getRowId: PropTypes.func,
	onClick: PropTypes.func,
	columns: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string,
		cellDataGetter: PropTypes.func,
		cellDataValue:PropTypes.func,
		label: PropTypes.any.isRequired,
	})),
	rows: PropTypes.array,
	editAction: PropTypes.func,
}
export default DataTable;