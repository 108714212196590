import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Select, FormControl, InputLabel, NativeSelect, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import useWindowSize from '../useWindowSize';
import { Field, getIn, useFormikContext } from 'formik';
import ErrorMessage from '../ErrorMessage';
export default connect(
	/* ownProps : {
		visible:boolean,
		name,
		required,
		options:[
			{
				value,
				label
			}
		],
		defaultValue:value,
		value,
		name
	}
	 }*/
	(state, ownProps) => {
		return {
			//STATE TO PROPS
			///////////////////////////////////////////////////////////////////

		}
	},
	(dispatch, props) => bindActionCreators({
		//ACTIONS
		///////////////////////////////////////////////////////////////////

	}, dispatch)
)(function (props) {
	//COMPONENT
	///////////////////////////////////////////////////////////////////
	const {required, disabled,visible = true, label, variant = "outlined",  sx={}, options = [], ...inputProps } = props;
	const formikBag = useFormikContext();
	//const value = getIn(formikBag.values,props.name);
	//console.log(props); 
	const bigScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));
	return <>
		<FormControl sx={sx}>
			<FormLabel required={required}>{props.label}</FormLabel>
			<Field as={RadioGroup}
				aria-labelledby="demo-radio-buttons-group-label"
				name={props.name}
				row={true}
				sx={{
					display:bigScreen ? "flex":undefined
				}}
			>
				{options.map((optioni, i) => <FormControlLabel 
					sx={{
						flex:bigScreen? 1:undefined
					}}
					key={i + " " + optioni.value} 
					value={optioni.value} control={<Radio disabled={disabled}	/>} 
					label={optioni.label}
					
				/>)}
			</Field>
		</FormControl>
		{props.name && <ErrorMessage sx={{marginTop:1}} name={props.name} />}
	</>
});